import { useEffect, useRef, useState } from 'react';
import constate from 'constate';
import { useParams } from 'react-router';
import useReportDetails from '../remote/useReportDetails';
import useReports from '../remote/useReports';

const useReportData = () => {
  const patrolRef = useRef();
  const [alarmRefs, setAlarmRefs] = useState<HTMLDivElement[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [filters, setFilters] = useState({
    listPage: 1,
    limit: 125,
    MissionName: [],
    endDate: '',
    startDate: '',
    Robot: [],
    SuccessFailure: [],
  });

  const { data: reportsResponse } = useReports(filters);
  const { uuid: currentUuid } = useParams();
  const [uuid, setUuid] = useState<string>('');

  const { data: reportDetailsResponse } = useReportDetails(
    { uuid: currentUuid || '' },
    {
      enabled: !!currentUuid,
      suspense: true,
    }
  );

  // Update uuid when reportsResponse changes
  useEffect(() => {
    reportsResponse && setUuid(reportsResponse?.reports[0]?.uuid || '');
  }, [reportsResponse]);

  return {
    report: reportDetailsResponse,
    reports: reportsResponse,
    setFilters,
    filters,
    patrolRef,
    alarmRefs,
    setAlarmRefs,
    uuid,
    setUuid,
    isLoaded,
    setIsLoaded,
  };
};

export const [ReportProvider, useReportContext] = constate(useReportData);
