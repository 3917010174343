import { MainContainer } from '@/components/myStyle/MainContainer';
import { CircularProgress, Paper, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface LoadingPageProps {
  loadingText?: string;
}
export const LoadingPage = ({ loadingText }: LoadingPageProps) => {
  const theme = useTheme();

  return (
    <MainContainer theme={theme}>
      <Paper>
        <Stack
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <CircularProgress />
          <Typography variant="h6" gutterBottom>
            {loadingText}
          </Typography>
        </Stack>
      </Paper>
    </MainContainer>
  );
};
