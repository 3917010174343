import { useState } from 'react';
import ScheduleIcon from '@mui/icons-material/Schedule';
import Sort from '@mui/icons-material/Sort';
import ListItemLink from './ListItemLink';
import { Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useHeaderContext } from '@/hooks/constate/HeaderContext';
import { useTranslation } from 'react-i18next';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

const items = [
  {
    href: '/',
    icon: <ScheduleIcon fontSize="small" />,
    title: 'schedule',
  },
  {
    href: '/report/:uuid',
    icon: <DescriptionOutlinedIcon fontSize="small" />,
    title: 'report',
  },
  {
    href: '/list',
    icon: <Sort fontSize="small" />,
    title: 'reportList',
  },
];

export const MainListItems = () => {
  const { drawOpen } = useHeaderContext();
  const [open, setOpen] = useState(true);
  const { t } = useTranslation();

  return (
    <Box>
      <ListItemButton
        onClick={() => setOpen(!open)}
        sx={{
          pb: 1,
          '&:hover, &:focus': { '& svg': { opacity: 1 } },
          borderRadius: '12px',
        }}
      >
        <ListItemIcon>
          <MoreHorizIcon />
        </ListItemIcon>
        {drawOpen && (
          <>
            <ListItemText primary={t('menu')} />
            <KeyboardArrowDown
              sx={{
                mr: -1,
                opacity: 0,
                transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                transition: '0.2s',
              }}
            />
          </>
        )}
      </ListItemButton>
      {open &&
        items.map((item, index) => {
          const itemKey = `main-${index}`;
          return (
            <ListItemLink key={item.title} item={item} itemKey={itemKey} />
          );
        })}
    </Box>
  );
};
