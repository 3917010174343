import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { red, green } from '@mui/material/colors';
import { StyledTableCell, StyledTableRow } from './DataTable';
import { getAlarmResultText } from '@/utils/getAlarmResultText';
import { getAlarmInspectionStandards } from '@/utils/getAlarmInspectionStandards';
import { displayThresholdRange } from '@/utils/displayThresholdRange';
interface FilteredActionsProps {
  filteredActions: FilteredActions[];
}

interface FilteredActions {
  action_name: string;
  data_captures: DataCapture[];
}
interface DataCapture {
  time: string;
  dataUrl: string;
  key_result: KeyResult[];
  channelName: string;
  full_dataUrl: string;
}
interface KeyResult {
  name: string;
  value: string;
}

const CheckOrCloseIcon = ({
  isDataAvailable,
}: {
  isDataAvailable: boolean;
}) => (
  <StyledTableCell align="center">
    {isDataAvailable ? (
      <CheckIcon color="success" sx={{ color: green[500] }} fontSize="small" />
    ) : (
      <CloseIcon color="error" sx={{ color: red[500] }} fontSize="small" />
    )}
  </StyledTableCell>
);

export const ActionDataRow = ({ filteredActions }: FilteredActionsProps) => {
  return (
    <>
      {filteredActions.map((data, index) => {
        const [actionName, factoryName, lineName] = data.action_name.split('-');
        const checkResult = getAlarmResultText(
          actionName.trimEnd(),
          data?.data_captures[0]?.key_result
        );
        const { maxThreshold, minThreshold } = getAlarmInspectionStandards(
          actionName.trimEnd(),
          data?.data_captures[0]?.key_result
        );

        const isDataAvailable = Boolean(data);

        const rowStyles = {
          bgcolor: isDataAvailable ? `${data}.light` : `${data}.main`,
        };

        return (
          <StyledTableRow key={index} sx={rowStyles}>
            <StyledTableCell>{factoryName}</StyledTableCell>
            <StyledTableCell>{lineName}</StyledTableCell>
            <StyledTableCell colSpan={2}>{actionName}</StyledTableCell>
            <StyledTableCell align="center">
              {displayThresholdRange(minThreshold, maxThreshold)}
            </StyledTableCell>
            <StyledTableCell align="center">{checkResult}</StyledTableCell>
            <CheckOrCloseIcon isDataAvailable={isDataAvailable} />
          </StyledTableRow>
        );
      })}
    </>
  );
};
