import React, {
  BaseSyntheticEvent,
  forwardRef,
  SetStateAction,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useTranslation } from 'react-i18next';
import { convertDate } from '@/utils/converDate';
import { debounce } from '@/hooks/common/debounce';

interface ScheduleFormValue {
  robotNickname: string;
  selectedMission: string;
  settingDateTime: string;
  isSMSChecked: boolean;
  isEmailChecked: boolean;
}

interface TransitionsScheduleDialogProps {
  modalOpen: boolean;
  setModalOpen: (value: SetStateAction<boolean>) => void;
  onSubmit: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    e?: BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  formValues: ScheduleFormValue;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const TransitionsScheduleDialog = ({
  modalOpen,
  setModalOpen,
  onSubmit,
  formValues,
}: TransitionsScheduleDialogProps) => {
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClick = async () => {
    setIsSubmitting(true);
    try {
      await onSubmit();
      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box>
      <Dialog
        open={modalOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => setModalOpen(false)}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          {t('mission')} : {formValues?.selectedMission?.split('*')[0]}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {t('The mission start time is', {
              time: convertDate(Date.parse(formValues?.settingDateTime)),
            })}{' '}
            {t('Please confirm')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalOpen(false)} disabled={isSubmitting}>
            {t('cancel')}
          </Button>
          <Button type="submit" onClick={handleClick} disabled={isSubmitting}>
            {isSubmitting ? '보내는 중...' : t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
