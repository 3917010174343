import { useCallback, useState, useMemo } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Paper,
  Typography,
  FormControl,
  Button,
  Box,
  Grid,
} from '@mui/material';
import { TransitionsScheduleDialog } from '@/components/Schedule/TransitionsScheduleDialog';
import useRegisterSchedule from '@/hooks/remote/useRegisterSchedule';
import { queryKey } from '@/hooks/remote/querykey';
import { showToast } from '@/hooks/toast/toastUtils';
import { useScheduleContext } from '@/hooks/constate/ScheduleContext';
import { useTranslation } from 'react-i18next';
import { useForm, SubmitHandler } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { isWithinInterval, addDays } from 'date-fns';
import { FormSelect } from '../Common/FormSelect';
import { FormDate } from '../Common/FormDate';
import { FormCheckBox } from '../Common/FormCheckBox';

interface ScheduleFormValue {
  robotNickname: string;
  selectedMission: string;
  settingDateTime: string;
  isSMSChecked: boolean;
  isEmailChecked: boolean;
}

export const Scheduler = () => {
  const { handleSubmit, getValues, control, watch } =
    useForm<ScheduleFormValue>({
      mode: 'onBlur',
    });
  const formValues = getValues();
  const watchedFormValues = watch();

  const [modalOpen, setModalOpen] = useState(false);

  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const { robots, mission } = useScheduleContext();

  const { mutateAsync: registerSchedule } = useRegisterSchedule({
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.fetchSchedule]);
    },
  });

  const ROBOTS = useMemo(() => {
    return robots
      .filter((value) => value.nickname)
      .map((robot) => ({
        value: robot.nickname,
        label: robot.nickname,
      }));
  }, [robots]);

  const MISSION = useMemo(() => {
    if (!mission) return [];
    return mission.map((missionElement) => ({
      value: `${missionElement.name}*${missionElement.uuid}`,
      label: missionElement.name,
    }));
  }, [mission]);

  const createScheduleData = useCallback(
    (formValues: ScheduleFormValue) => {
      if (!formValues) return null;
      return {
        robotNickname: formValues.robotNickname,
        date: Date.parse(formValues.settingDateTime),
        repeatMs: 0,
        missionId: formValues?.selectedMission?.split('*')[1],
        eventName: formValues?.selectedMission?.split('*')[0],
        isSMSChecked: formValues.isSMSChecked,
        isEmailChecked: formValues.isEmailChecked,
      };
    },
    [formValues]
  );

  const onSubmit: SubmitHandler<ScheduleFormValue> = useCallback(
    async (data) => {
      const scheduleData = createScheduleData(data);
      if (!scheduleData) return;

      if (scheduleData.date > Date.now()) {
        try {
          await registerSchedule(scheduleData);
          showToast(t, 'success', 'successfully booked the schedule');
        } catch (e) {
          showToast(t, 'error', 'Schedule booking failed, please try again');
        }
      }

      setModalOpen(false);
    },
    [createScheduleData]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Paper>
          <TransitionsScheduleDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            onSubmit={handleSubmit(onSubmit)}
            formValues={formValues}
          />
          <Typography variant="h4">
            {t('mission')} {t('reservation')}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)} style={{ margin: 10 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormSelect
                  name="robotNickname"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: '로봇을 선택해주세요',
                    },
                  }}
                  selectGroup={ROBOTS}
                  SelectFieldProps={{ label: '로봇' }}
                  FormControlProps={{ size: 'small', fullWidth: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormSelect
                  name="selectedMission"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: '미션을 선택해주세요',
                    },
                  }}
                  selectGroup={MISSION}
                  SelectFieldProps={{ label: '미션' }}
                  FormControlProps={{ size: 'small', fullWidth: true }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormDate
                  name="settingDateTime"
                  control={control}
                  defaultValue=""
                  rules={{
                    required: {
                      value: true,
                      message: '시간을 입력해주세요',
                    },
                    validate: (value) => {
                      const currentDate = new Date();
                      const maxDate = addDays(currentDate, 7);
                      if (
                        !isWithinInterval(new Date(value), {
                          start: currentDate,
                          end: maxDate,
                        })
                      ) {
                        return '현재 시각이후 부터 +7일까지만 가능합니다.';
                      }
                      return true;
                    },
                  }}
                  textFieldProps={{
                    type: 'datetime-local',
                    fullWidth: true,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  size="small"
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <FormCheckBox
                    name="isEmailChecked"
                    control={control}
                    defaultValue={true}
                    label="이메일"
                  />
                  <FormCheckBox
                    name="isSMSChecked"
                    control={control}
                    defaultValue={true}
                    label="문자메세지"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                endIcon={<AddIcon />}
                size="large"
                sx={{ height: 56 }}
                onClick={() => setModalOpen((prev) => !prev)}
                disabled={
                  !watchedFormValues.robotNickname ||
                  !watchedFormValues.selectedMission ||
                  !watchedFormValues.settingDateTime
                }
              >
                {t('missionRegistration')}
              </Button>
            </Box>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
};
