import { CssBaseline } from '@mui/material';
import {
  ThemeProvider as MUIThemeProvider,
  createTheme,
  StyledEngineProvider,
} from '@mui/material/styles';
//
import { themePalette } from './palette';
import { themeTypography } from './typography';
import { GlobalStyles } from './globalStyles';
import colors from '@assets/scss/_themes-vars.module.scss';

import ComponentsOverrides from './componentStyleOverride';
import { Colors, themeOptions, ThemeOption, ThemeProviderProps } from './types';
import { useLangContext } from '@/hooks/constate/LangContext';
import * as locales from '@mui/material/locale';

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const { locale } = useLangContext();

  const color: Colors = colors;
  const themeOption: ThemeOption = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.secondaryDark,
    menuSelectedBack: color.secondaryLight,
    divider: color.grey200,
  };

  const themeOptions: themeOptions = {
    direction: 'ltr',
    palette: themePalette(themeOption),
    typography: themeTypography(themeOption),
    borderRadius: 12,
    mixins: {
      toolbar: {
        minHeight: '48px',
        padding: '16px',
        '@media (min-width: 600px)': {
          minHeight: '48px',
        },
      },
    },
  };
  const themes = createTheme(themeOptions, locales[locale]);
  themes.components = ComponentsOverrides(themeOption);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={themes}>
        <CssBaseline />
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
};
