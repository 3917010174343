import {
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Checkbox,
  ListItemText,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface MultipleSelectCheckboxProps {
  checkboxName: string;
  names: Names[];
  filters: filter;
  setFilters: React.Dispatch<React.SetStateAction<filter>>;
}
interface filter {
  listPage: number;
  limit: number;
  MissionName: never[];
  endDate: string;
  startDate: string;
  Robot: never[];
  SuccessFailure: never[];
}
interface Names {
  uuid?: string;
  mission_id?: number;
  name: string;
  saved_at?: string;
  nickname?: string;
}

const ITEM_HEIGHT = 64;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 5.5 + ITEM_PADDING_TOP,
      width: 350,
    },
  },
};

export const MultipleSelectCheckbox = ({
  checkboxName,
  names,
  filters,
  setFilters,
}: MultipleSelectCheckboxProps) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const { t } = useTranslation();

  const handleSelectChange = (
    event: SelectChangeEvent<typeof selectedItems>
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedItems(typeof value === 'string' ? value.split(',') : value);
    setFilters({ ...filters, [checkboxName]: value });
  };

  return (
    <>
      <FormControl sx={{ m: 1, width: '100%' }}>
        <InputLabel id="multiple-checkbox-label">{t(checkboxName)}</InputLabel>
        <Select
          multiple
          value={selectedItems}
          onChange={handleSelectChange}
          input={<OutlinedInput label={t(checkboxName)} />}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          {names.map((element: Names, index: number) => (
            <MenuItem key={index} value={element.name}>
              <Checkbox checked={selectedItems.includes(element.name)} />
              <ListItemText primary={t(element.name)} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};
