import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { queryKey } from './querykey';
import { fetchAddress } from '@/api/address';
type AddressResponse = ContactInformation[];

interface ContactInformation {
  id: number;
  rank: string;
  name: string;
  phone: string;
  email: string;
  in_charge: string;
  alarm_level: AlarmLevel[];
  factories: Factories[];
  alarm_types: AlarmTypes[];
}
interface AlarmLevel {
  name: string;
  is_checked: boolean;
}
interface AlarmTypes {
  name: string;
  is_checked: boolean;
}

interface Factories {
  name: string;
  is_checked: boolean;
}

const useAddress = (
  variable = undefined,
  options?: UseQueryOptions<AddressResponse, AxiosError>
) => {
  const fetcher = async () => {
    const result = await fetchAddress();
    return result.data;
  };

  return useQuery<AddressResponse, AxiosError>(
    [queryKey.fetchAddress, variable],
    fetcher,
    options
  );
};

export default useAddress;
