import { Box, CircularProgress, ButtonBase, IconButton } from '@mui/material';
import { useHeaderContext } from '@/hooks/constate/HeaderContext';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { CalendarSection } from './CalendarSection';
import { ProfileSection } from './ProfileSection';
import MenuIcon from '@mui/icons-material/Menu';
import { LanguageToggle } from './LanguageToggle';
import useConfigLogo from '@/hooks/remote/useConfigLogo';
import mc from '@/utils/mc';
import { PRE_SIGNED_URL_EXPIRATION_TIME } from '../PatrolReport/AlarmReport';
import { showToast } from '@/hooks/toast/toastUtils';
import { useTranslation } from 'react-i18next';

export const Header = () => {
  return (
    <Suspense fallback={<CircularProgress />}>
      <HeaderComponent />
    </Suspense>
  );
};

const HeaderComponent = () => {
  const theme = useTheme();
  const [image, setImage] = useState('');
  const { drawOpen, setDrawOpen } = useHeaderContext();
  const { t } = useTranslation();
  const { data: fetchLogo } = useConfigLogo(undefined, {
    keepPreviousData: true,
  });

  const getPreSignedUrl = useCallback(async (img: string) => {
    try {
      const presignedUrl = await mc.presignedUrl(
        'GET',
        'spot-bucket',
        img,
        PRE_SIGNED_URL_EXPIRATION_TIME
      );
      setImage(presignedUrl);
    } catch (err) {
      showToast(t, 'error', `presigned err ${err}`);
    }
  }, []);

  useEffect(() => {
    if (fetchLogo) {
      getPreSignedUrl(fetchLogo?.url);
    }
  }, [fetchLogo]);

  return (
    <>
      <Box
        component="span"
        sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}
      >
        <ButtonBase
          sx={{ width: '160px', height: '50px', paddingTop: '5px' }}
          disableRipple
          component={Link}
          to={'/'}
        >
          {image ? (
            <img
              src={image}
              width="150"
              height="80"
              referrerPolicy="no-referrer"
              alt="이미지가 없습니다."
            />
          ) : (
            <CircularProgress />
          )}
        </ButtonBase>
        <IconButton
          sx={{
            width: '50px',
            height: '50px',
            borderRadius: '12px',
            overflow: 'hidden',
            ml: 8,
            background: theme.palette.secondary.light,
            color: theme.palette.secondary.dark,
            '&:hover': {
              background: theme.palette.secondary.dark,
              color: theme.palette.secondary.light,
            },
          }}
          onClick={() => setDrawOpen(!drawOpen)}
          size="large"
        >
          <MenuIcon />
        </IconButton>
      </Box>
      <LanguageToggle />
      <CalendarSection />
      <ProfileSection />
    </>
  );
};
