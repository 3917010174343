import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { updateSMS } from '@/api/coreConfig';

interface updateSMSParams {
  id: number;
  agency: string;
  api_key: string;
  senderPhoneNumber: string;
  default: boolean;
  is_need_config: boolean;
  settingTime: string;
}

const useUpdateSMS = (
  options?: UseMutationOptions<updateSMSParams, AxiosError, updateSMSParams>
) => {
  const fetcher = async (variable: updateSMSParams) => {
    const result = await updateSMS(variable);
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useUpdateSMS;
