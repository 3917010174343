import { useState, useEffect, useMemo } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { EditToggle } from './EditToggle';
import { useForm, SubmitHandler } from 'react-hook-form';
import { showToast } from '@/hooks/toast/toastUtils';
import { useTranslation } from 'react-i18next';
import { queryKey } from '@/hooks/remote/querykey';
import { useQueryClient } from '@tanstack/react-query';
import EditForm from './EditForm';
import AddForm from './AddForm';
import useFactory from '@/hooks/remote/useFactory';
import useUpdateFactory from '@/hooks/remote/useUpdateFactory';
import useDeleteFactory from '@/hooks/remote/useDeleteFactory';

interface FactoryFormValue {
  addFactoryName: string;
  newFactoryName: string;
  selectedFactory: {
    name: string;
    id: number;
    newName: string;
  };
}

export const FactoryForm = () => {
  const [mode, setMode] = useState('edit');
  const [action, setAction] = useState('');
  const { t } = useTranslation();

  const { control, handleSubmit, watch, setValue } =
    useForm<FactoryFormValue>();
  const queryClient = useQueryClient();

  const { data: factory } = useFactory(undefined);

  const { mutateAsync: updateFactory } = useUpdateFactory({
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.fetchFactory]);
      queryClient.invalidateQueries([queryKey.fetchMissions]);
    },
  });
  const { mutateAsync: deleteFactory } = useDeleteFactory({
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.fetchFactory]);
    },
  });

  const handleChangeMode = (newMode: string) => {
    setMode(newMode);
  };

  const FACTORY = useMemo(() => {
    if (!factory) return [];
    return factory.map((el) => ({
      value: el.name,
      label: el.name,
    }));
  }, [factory]);

  const onSubmit: SubmitHandler<FactoryFormValue> = (data) => {
    action === 'delete' ? factoryDelete(data) : factoryEdit(data);
  };

  const factoryEdit: SubmitHandler<FactoryFormValue> = async (data) => {
    if (data.selectedFactory.newName.includes('삭제')) {
      factoryDelete(data);
      return;
    }
    const selectedFactoryName = data.selectedFactory.name;
    const factoryDataToEdit = factory?.find(
      (el) => el.name === selectedFactoryName
    );

    if (!factoryDataToEdit) {
      showToast(t, 'error', '편집할 공장이 없습니다.');
      return;
    }

    try {
      const updatedFactoryData = {
        ...factoryDataToEdit,
        name: data.selectedFactory.newName,
      };
      await updateFactory(updatedFactoryData);
      showToast(t, 'success', '수정 성공');
    } catch (e) {
      showToast(t, 'error', '전송 오류');
    }
  };

  const factoryDelete: SubmitHandler<FactoryFormValue> = async (data) => {
    const selectedFactoryName = data.selectedFactory.name;
    const factoryDataToDelete = factory?.find(
      (el) => el.name === selectedFactoryName
    );

    if (!factoryDataToDelete) {
      showToast(t, 'error', '삭제할 공장이 없습니다.');
      return;
    }

    try {
      await deleteFactory(factoryDataToDelete);
      showToast(t, 'success', '삭제 성공');
    } catch (error: any) {
      const { message, response } = error;
      if (response.status === 403) {
        showToast(t, 'error', message);
      }
      showToast(t, 'error', '전송 실패');
    }
  };

  const selectedFactory = watch('selectedFactory.name');

  useEffect(() => {
    setValue('selectedFactory.newName', selectedFactory);
  }, [selectedFactory, setValue]);

  return (
    <Grid
      item
      xs={12}
      md={6}
      p={2}
      sx={{ border: 1, borderRadius: 1, borderColor: '#CDD5DF' }}
    >
      <Stack direction="column">
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="h4">공장 설정</Typography>
          {/* <EditToggle
            onChange={handleChangeMode}
            mode={mode}
            setMode={setMode}
          /> */}
        </Stack>
      </Stack>
      {mode === 'edit' ? (
        <EditForm
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          control={control}
          FACTORY={FACTORY}
          setAction={setAction}
        />
      ) : (
        <AddForm handleSubmit={handleSubmit} control={control} />
      )}
    </Grid>
  );
};
