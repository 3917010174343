import { fetchActionList } from '@/api/actionSetting';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { queryKey } from './querykey';
interface ActionListVariable {
  uuid: string;
}

interface ActionListResponse {
  mission_uuid: string;
  action_uuid: string;
  action_name: string;
  source_action_create_at: string;
  source_action_modified_at: string;
  alias: string | null;
  create_at: string;
  modified_at: string;
  factory: string | null;
  line: string | null;
  description: string | null;
  unit: string | null;
  action_enable: boolean;
  inspection_type: string | null;
  inspection_criteria: string | null;
}

const useActionList = (
  variable: ActionListVariable,
  options?: UseQueryOptions<ActionListResponse[], AxiosError>
) => {
  const fetcher = async () => {
    const result = await fetchActionList(variable);
    return result.data;
  };

  return useQuery<ActionListResponse[], AxiosError>(
    [queryKey.fetchActionList, variable],
    fetcher,
    options
  );
};

export default useActionList;
