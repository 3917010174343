export const queryKey = {
  fetchReportDetail: '/report/detail/:uuid',
  fetchReports: '/report/list',
  fetchSchedule: '/schedule/',
  fetchAddress: '/address',
  fetchScheduleLogs: 'schedule/log',
  fetchFactory: '/core/config/factory',
  fetchLogo: '/core/config/logo',
  fetchActionList: '/missions/:uuid/actions',
  fetchActions: '/missions/:missionUuid/actions/:actionUuid',
  fetchMissions: '/missions/:uuid',
  fetchMissionList: '/missions',
  fetchRouteImage: '/missions/route-map-image/:uuid',
  fetchSMS: 'core/config/sms',
};

export const mutationKey = {
  createPdf: '/report/post_pdf',
  deleteAddress: '/address/detail',
  addAddress: '/address/',
  updateAddress: 'address/detail',
  registerSchedule: 'schedule/work',
  deleteSchedule: 'schedule/work',
  deleteFactory: 'core/config/factory',
  registerSMS: 'core/config/sms',
  updateSMS: 'core/config/sms',
  updateFactory: 'core/config/factory',
  registerSMTP: 'core/config/smtp',
  registerScoutInfo: 'core/config/scoutinfo',
  registerLogo: 'core/config/logo',
  missionSync: '/missions/sync',
  editActions: '/missions/:missionUuid/actions/:actionUuid',
  editMissions: '/missions/:uuid',
  editRouteImage: '/missions/route-map-image/:uuid',
  deleteRouteImage: '/missions/route-map-image/:uuid',
  deleteReport: '/report/detail',
};
