import { fetchMissions } from '@/api/missionSetting';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { queryKey } from './querykey';

interface MissionsVariable {
  uuid: string;
}

interface MissionsResponse {
  mission_uuid: string;
  mission_name: string;
  source_mission_create_at: string;
  source_mission_modified_at: string;
  alias: string;
  create_at: string;
  modified_at: string;
  file: string;
  route_description: string;
  factory: string;
  detail_route: string;
}

const useMissions = (
  variable: MissionsVariable,
  options?: UseQueryOptions<MissionsResponse, AxiosError>
) => {
  const fetcher = async () => {
    const result = await fetchMissions(variable);
    return result.data;
  };

  return useQuery<MissionsResponse, AxiosError>(
    [queryKey.fetchMissions, variable],
    fetcher,
    options
  );
};

export default useMissions;
