import { rest } from 'msw';
import { mockScheduleData } from './data/mockScheduleData';

const baseUrl = process.env.REACT_APP_BACKEND_ENDPOINT_URL;

export const scheduleHandlers = [
  rest.get(`${baseUrl}/schedule/`, (req, res, ctx) => {
    return res(ctx.json(mockScheduleData));
  }),
  rest.post(`${baseUrl}/schedule/work`, (req, res, ctx) => {
    // You can process or store the data as needed
    return res(
      ctx.status(200),
      ctx.json({ message: 'Schedule registered successfully' })
    );
  }),
  rest.delete(`${baseUrl}/schedule/work`, (req, res, ctx) => {
    // You can process or delete the data as needed
    return res(
      ctx.status(200),
      ctx.json({ message: 'Schedule deleted successfully' })
    );
  }),
];
