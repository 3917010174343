import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { queryKey } from './querykey';
import { fetchFactory } from '@/api/coreConfig';

interface FactoryResponse {
  name: string;
  manager: string;
  id: number;
}

const useFactory = (
  variable = undefined,
  options?: UseQueryOptions<FactoryResponse[], AxiosError>
) => {
  const fetcher = async () => {
    const result = await fetchFactory();
    return result.data;
  };

  return useQuery<FactoryResponse[], AxiosError>(
    [queryKey.fetchFactory, variable],
    fetcher,
    options
  );
};

export default useFactory;
