import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { updateFactory } from '@/api/coreConfig';

interface updateFactoryParams {
  id: number;
  name: string;
  manager: string;
}

const useUpdateFactory = (
  options?: UseMutationOptions<
    updateFactoryParams,
    AxiosError,
    updateFactoryParams
  >
) => {
  const fetcher = async (variable: updateFactoryParams) => {
    const result = await updateFactory(variable);
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useUpdateFactory;
