export interface RouteMapImage {
  mission_uuid: string;
  file: string;
  size: number;
  type: string;
}

export const mockRouteMapImageResponse: RouteMapImage[] = [
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    file: 'http://localhost:9000/route-map-bucket/2023-7-10/%E1%84%82%E1%85%B3%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC_%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9_DC2oWiK.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20230710%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230710T074045Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=e9d55a73e964498e197bb2022af5be5a8f8d9093a144417e74f14fbfd4b36db0',
    size: 91482,
    type: 'png',
  },
];
