import { rest } from 'msw';
import { mockReportData } from './data/mockReportData';

const baseUrl = process.env.REACT_APP_BACKEND_ENDPOINT_URL;

export const reportDetailHandlers = [
  rest.get(`${baseUrl}/report/detail/:uuid`, (req, res, ctx) => {
    const uuid = 'fa12440f-4aa9-471a-b312-4076d0e03ba7';
    const reportData = mockReportData.find((report) => report.uuid === uuid);

    // Check if the report data was found
    if (reportData) {
      // Return the report data as the response
      const response = {
        uuid: reportData.uuid,
        mission: reportData.mission,
        robot: reportData.robot,
        action: reportData.action,
      };
      return res(ctx.status(200), ctx.json(response));
    } else {
      // Return a 404 error if the report data wasn't found
      return res(ctx.status(404));
    }
  }),
];
