import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { registerScoutInfo } from '@/api/coreConfig';

interface registerScoutInfoParams {
  token: string;
}

const useRegisterScoutInfo = (
  options?: UseMutationOptions<
    registerScoutInfoParams,
    AxiosError,
    registerScoutInfoParams
  >
) => {
  const fetcher = async (variable: registerScoutInfoParams) => {
    const result = await registerScoutInfo(variable);
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useRegisterScoutInfo;
