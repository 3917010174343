import { useReportContext } from '@/hooks/constate/ReportContext';
import { Box, Typography, Grid } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const PathSummary = () => {
  const { report } = useReportContext();
  const { t } = useTranslation();
  const missionRoute = useMemo(() => {
    if (!report?.mission[0]?.detail_route)
      return '지정된 경로가 없습니다. 미션설정에서 입력해주세요';
    return report?.mission[0]?.detail_route;
  }, [report]);
  return (
    <Grid item xs={12}>
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 4,
          padding: 2,
          border: 1,
          borderColor: 'grey.400',
        }}
      >
        <Typography variant="h5" gutterBottom>
          {t('missionRoute')}
        </Typography>
        <Typography variant="body1" gutterBottom>
          {t`${missionRoute}`}
        </Typography>
      </Box>
    </Grid>
  );
};
