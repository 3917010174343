import constate from 'constate';
import useSchedules from '@/hooks/remote/useSchedules';
import { useMemo } from 'react';

const useSchedule = () => {
  const { data: schedules } = useSchedules(undefined, {
    retry: true,
    refetchInterval: 500000,
    refetchIntervalInBackground: true,
  });

  const daySortedSchedules = useMemo(() => {
    return schedules?.calendar?.activeEvents?.sort((a, b) => {
      return a.schedule.timeMs.localeCompare(b.schedule.timeMs);
    });
  }, [schedules]);

  const { robots, mission } = useMemo(
    () => schedules || { robots: [], mission: [] },
    [schedules]
  );

  return {
    schedules,
    daySortedSchedules,
    robots,
    mission,
  };
};

export const [ScheduleProvider, useScheduleContext] = constate(useSchedule);
