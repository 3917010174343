import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { registerFactory } from '@/api/coreConfig';

interface registerFactoryParams {
  addFactoryName: string;
}
const useRegisterFactory = (
  options?: UseMutationOptions<
    registerFactoryParams,
    AxiosError,
    registerFactoryParams
  >
) => {
  const fetcher = async (variable: registerFactoryParams) => {
    const result = await registerFactory(variable.addFactoryName);
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useRegisterFactory;
