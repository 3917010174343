import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { registerSMTP } from '@/api/coreConfig';

interface registerSMTPParams {
  address: string;
  name: string;
  id: string;
  password: string;
  port: number;
}

const useRegisterSMTP = (
  options?: UseMutationOptions<
    registerSMTPParams,
    AxiosError,
    registerSMTPParams
  >
) => {
  const fetcher = async (variable: registerSMTPParams) => {
    const result = await registerSMTP(variable);
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useRegisterSMTP;
