import { editActions } from '@/api/actionSetting';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

interface EditActionsParams {
  mission_uuid: string;
  action_uuid: string;
  action_name: string;
  source_action_create_at: string;
  source_action_modified_at: string;
  alias: string | null;
  create_at: string;
  modified_at: string;
  factory: string | null;
  line: string | null;
  description: string | null;
  unit: string | null;
  action_enable: boolean;
  inspection_type: string | null;
  inspection_criteria: string | null;
}

interface EditActionsResponse {
  test: string;
}

const useEditActions = (
  options?: UseMutationOptions<
    EditActionsResponse,
    AxiosError,
    EditActionsParams[]
  >
) => {
  const fetcher = async (variable: EditActionsParams[]) => {
    const result = await editActions(variable);
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useEditActions;
