import useSMSinfo from '@/hooks/remote/useSMSinfo';
import useUpdateSMS from '@/hooks/remote/useUpdateSMS';
import { showToast } from '@/hooks/toast/toastUtils';
import { Grid, Stack, Typography, Button } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormInputText } from '../Common/FormInputText';

interface SMSList {
  id: number;
  agency: string;
  api_key: string;
  senderPhoneNumber: string;
  settingTime: string;
  default: boolean;
  is_need_config: boolean;
}

interface SMSSettingFormValue {
  sms: SMSList;
}

export const SmsForm = () => {
  const { handleSubmit, control } = useForm<SMSSettingFormValue>();

  const { data: SMSInfo } = useSMSinfo();
  const { mutateAsync: updateSMS } = useUpdateSMS();
  const { t } = useTranslation();

  const onSubmit: SubmitHandler<SMSSettingFormValue> = (data) => {
    if (
      SMSInfo?.setting_time !== data.sms.settingTime ||
      SMSInfo?.sender_phone_number !== data.sms.senderPhoneNumber
    ) {
      smsUpdate(data);
    } else {
      showToast(t, 'error', '수정된 값을 넣어야합니다.');
    }
  };

  const smsUpdate: SubmitHandler<SMSSettingFormValue> = async (data) => {
    try {
      if (!SMSInfo) return;
      const { settingTime, senderPhoneNumber } = data.sms;
      const updatedSMSInfo = {
        ...SMSInfo,
        settingTime,
        senderPhoneNumber,
      };
      await updateSMS(updatedSMSInfo);
      showToast(t, 'success', '수정완료');
    } catch (e) {
      showToast(t, 'error', '전송오류');
    }
  };

  return (
    <Grid
      item
      xs={12}
      md={6}
      p={2}
      sx={{ border: 1, borderRadius: 1, borderColor: '#CDD5DF' }}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack direction="column">
          <Typography variant="h4" mb={4}>
            {t('SMSSetting')}
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <FormInputText
                name="sms.senderPhoneNumber"
                control={control}
                defaultValue={SMSInfo?.sender_phone_number || ''}
                rules={{ required: '발신자 번호를 입력해주세요' }}
                textFieldProps={{
                  label: `${t('senderPhoneNumber')}`,
                  fullWidth: true,
                  type: 'tel',
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormInputText
                name="sms.settingTime"
                control={control}
                defaultValue={SMSInfo?.setting_time || ''}
                rules={{
                  required: '요약문자를 받을 시간을 입력해주세요',
                  pattern: {
                    value: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
                    message: '시간은 00:00 형식으로 입력해주셔야합니다.',
                  },
                }}
                textFieldProps={{
                  label: `${t('SMS summary time setting (24time ex 0830)')}`,
                  fullWidth: true,
                  type: 'time',
                }}
              />
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <Button variant="contained" type="submit">
                {t('submit')}
              </Button>
            </Grid>
          </Grid>
        </Stack>
      </form>
    </Grid>
  );
};
