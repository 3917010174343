type AddressResponse = ContactInformation[];

interface ContactInformation {
  id: number;
  rank: string;
  name: string;
  phone: string;
  email: string;
  in_charge: string;
  alarm_level: AlarmLevel[];
  factories: Factories[];
  alarm_types: AlarmTypes[];
}
interface AlarmLevel {
  name: string;
  is_checked: boolean;
}
interface AlarmTypes {
  name: string;
  is_checked: boolean;
}

interface Factories {
  name: string;
  is_checked: boolean;
}

export const mockAddressData: AddressResponse = [
  {
    id: 155,
    rank: 'Manager',
    name: 'woody',
    phone: '01020259380',
    email: 'woody@clobot.co.kr',
    in_charge: 'test',
    alarm_level: [
      { name: 'INFO', is_checked: true },
      { name: 'WARNING', is_checked: false },
      { name: 'CRITICAL', is_checked: true },
    ],
    alarm_types: [
      { name: 'EMAIL', is_checked: true },
      { name: 'SMS_LIVE', is_checked: false },
      { name: 'SMS_BOOKING', is_checked: true },
    ],
    factories: [
      { name: 'AG_RED', is_checked: true },
      { name: 'AG_BLUE', is_checked: true },
    ],
  },
  {
    id: 1,
    rank: 'Manager',
    name: 'test1',
    phone: '01023421234',
    email: 'test@clobot.co.kr',
    in_charge: 'test',
    alarm_level: [
      { name: 'INFO', is_checked: true },
      { name: 'WARNING', is_checked: true },
      { name: 'CRITICAL', is_checked: false },
    ],
    alarm_types: [
      { name: 'EMAIL', is_checked: true },
      { name: 'SMS_LIVE', is_checked: false },
      { name: 'SMS_BOOKING', is_checked: true },
    ],

    factories: [
      { name: 'AG_RED', is_checked: true },
      { name: 'AG_BLUE', is_checked: true },
    ],
  },
  {
    id: 2,
    rank: 'Supervisor',
    name: 'test2',
    phone: '01012345679',
    email: 'test2@clobot.co.kr',
    in_charge: 'test',
    alarm_level: [
      { name: 'INFO', is_checked: false },
      { name: 'WARNING', is_checked: true },
      { name: 'CRITICAL', is_checked: true },
    ],
    alarm_types: [
      { name: 'EMAIL', is_checked: true },
      { name: 'SMS_LIVE', is_checked: false },
      { name: 'SMS_BOOKING', is_checked: true },
    ],

    factories: [
      { name: 'AG_RED', is_checked: false },
      { name: 'AG_BLUE', is_checked: true },
    ],
  },
  {
    id: 3,
    rank: 'admin',
    name: 'test3',
    phone: '01012345679',
    email: 'test3@clobot.co.kr',
    in_charge: 'test',
    alarm_level: [
      { name: 'INFO', is_checked: true },
      { name: 'WARNING', is_checked: true },
      { name: 'CRITICAL', is_checked: false },
    ],
    alarm_types: [
      { name: 'EMAIL', is_checked: true },
      { name: 'SMS_LIVE', is_checked: false },
      { name: 'SMS_BOOKING', is_checked: true },
    ],

    factories: [
      { name: 'AG_RED', is_checked: false },
      { name: 'US', is_checked: true },
    ],
  },
];
