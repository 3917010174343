import { client } from '@api/client';

interface AddAddressParams {
  rank: string;
  name: string;
  phone: string;
  email: string;
  in_charge: string;
  alarm_level: AlarmLevel[];
  alarm_types: AlarmTypes[];
  factories: Factories[];
}
interface AlarmTypes {
  name: string;
  is_checked: boolean;
}
interface AlarmLevel {
  name: string;
  is_checked: boolean;
}
interface Factories {
  name: string;
  is_checked: boolean;
}

interface UpdateAddressParams {
  rank: string;
  name: string;
  phone: string;
  email: string;
  to_email: string;
  in_charge: string;
  alarm_level: AlarmLevel[];
}
type AddressResponse = ContactInformation[];

interface ContactInformation {
  id: number;
  rank: string;
  name: string;
  phone: string;
  email: string;
  in_charge: string;
  alarm_level: AlarmLevel[];
  factories: Factories[];
  alarm_types: AlarmTypes[];
}

export const fetchAddress = () => {
  return client.get<AddressResponse>(`/address/`);
};

export const addAddress = (params: AddAddressParams) => {
  return client.post(`/address/`, {
    data: params,
  });
};

export const updateAddress = (params: UpdateAddressParams) => {
  return client.put(`/address/detail`, {
    data: params,
  });
};

export const deleteAddress = (email: string[]) => {
  return client.delete(`/address/detail`, {
    data: { email },
  });
};
