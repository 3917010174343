import { GridRowModel } from '@mui/x-data-grid';
interface CheckList {
  name: string;
  is_checked: boolean;
}

export const setToastMessage = (
  newRow: GridRowModel,
  oldRow: GridRowModel,
  t: (key: string) => string
) => {
  if (newRow === undefined || oldRow === undefined) return null;
  if (newRow.name !== oldRow.name) {
    return `이름이 [${oldRow.name} ->  ${newRow.name}] `;
  }
  if (newRow.email !== oldRow.email) {
    return `이메일이 '${oldRow.email}' 에서 '${newRow.email}로'`;
  }
  if (newRow.rank !== oldRow.rank) {
    return `직책이 '${oldRow.rank}' 에서 '${newRow.rank}로'`;
  }

  if (newRow.phone !== oldRow.phone) {
    return `핸드폰번호가 '${oldRow.phone}' 에서 '${newRow.phone}로'`;
  }

  const changedAlarmTypes = newRow?.alarm_types?.filter(
    (newType: CheckList) => {
      const oldType = oldRow?.alarm_types?.find(
        (types: CheckList) => types.name === newType.name
      );
      return oldType && newType.is_checked !== oldType.is_checked;
    }
  );
  const changedFactories = newRow?.factories?.filter(
    (newFactory: CheckList) => {
      const oldFactory = oldRow?.factories?.find(
        (factory: CheckList) => factory.name === newFactory.name
      );
      return oldFactory && newFactory.is_checked !== oldFactory.is_checked;
    }
  );

  if (changedAlarmTypes?.length > 0) {
    const changedTypesString = changedAlarmTypes
      .map(
        (type: CheckList) =>
          `'${t(type.name)}' 를  ${type.is_checked ? '부여' : '해제'}로`
      )
      .join(', ');

    return `기능 ${changedTypesString}`;
  }

  if (changedFactories?.length > 0) {
    const changedFactoryString = changedFactories
      .map(
        (factory: CheckList) =>
          `'${t(factory.name)}' 를  ${factory.is_checked ? '부여' : '해제'}로`
      )
      .join(', ');

    return `공장 ${changedFactoryString}`;
  }

  return null;
};
