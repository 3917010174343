import { GridValueFormatterParams } from '@mui/x-data-grid';
interface CheckList {
  name: string;
  is_checked: boolean;
}

export const phoneFormatter = (phone: string) => {
  return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
};

export const formatterArray = (
  values: string[],
  t: (key: string) => string
): string => {
  return values.map((value) => t(value)).join(', ');
};

export const GirdTableformatter = (
  params: GridValueFormatterParams,
  t: (key: string) => string
): string => {
  const values = params?.value;

  if (Array.isArray(values)) {
    const checkedValue = values.filter((value: CheckList) => value.is_checked);
    const valueNames = checkedValue.map((value: CheckList) => value.name);
    return formatterArray(valueNames, t);
  }
  return '';
};
