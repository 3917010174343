import { useLangContext } from '@/hooks/constate/LangContext';
import { queryKey } from '@/hooks/remote/querykey';
import useActionList from '@/hooks/remote/useActionList';
import useEditActions from '@/hooks/remote/useEditActions';
import useMissions from '@/hooks/remote/useMissions';
import { showToast } from '@/hooks/toast/toastUtils';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import {
  DataGrid,
  koKR,
  enUS,
  GridCellParams,
  useGridApiRef,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { useQueryClient } from '@tanstack/react-query';
import { useTheme } from '@mui/material/styles';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { CustomToolbar } from '../Address/DataGridCustomToolbar';
import CustomNoRowsOverlay from '../Common/CustomNoRowsOverlay';
import { MainContainer } from '../myStyle/MainContainer';
interface EditActionsProps {
  mission_uuid: string;
  action_uuid: string;
  action_name: string;
  source_action_create_at: string;
  source_action_modified_at: string;
  alias: string | null;
  create_at: string;
  modified_at: string;
  factory: string | null;
  line: string | null;
  description: string | null;
  unit: string | null;
  action_enable: boolean;
  inspection_type: string | null;
  inspection_criteria: string | null;
}

export const ActionEdit = () => {
  const { locale } = useLangContext();
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  const { uuid: missionUuid } = useParams();
  const queryClient = useQueryClient();
  const [isDisable, setIsDisable] = useState(true);
  const theme = useTheme();

  const { data: actionList } = useActionList(
    { uuid: missionUuid || '' },
    {
      enabled: !!missionUuid,
    }
  );
  const { data: mission } = useMissions(
    { uuid: missionUuid || '' },
    {
      enabled: !!missionUuid,
    }
  );

  const { mutateAsync: editActions } = useEditActions({
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.fetchActionList]);
    },
  });

  // const onExecution = (params: GridCellParams) => {
  //   const actionEx = params.row.actionExecution;
  //   return (params.row.actionExecution = !actionEx);
  // };

  const columns = [
    {
      field: 'action_name',
      headerName: t('액션명') || '액션명',
      flex: 2,
      editable: false,
    },
    {
      field: 'ailas',
      headerName: t('별칭') || '별칭',
      flex: 1,
      editable: true,
    },

    {
      field: 'factory',
      headerName: t('공장') || '공장명',
      flex: 1,
      editable: true,
    },
    {
      field: 'line',
      headerName: t('라인') || '라인명',
      flex: 1,
      editable: true,
    },
    {
      field: 'description',
      headerName: '설명',
      flex: 2,
      editable: true,
    },
    {
      field: 'inspection_criteria',
      headerName: '점검 범위',
      flex: 1,
      editable: true,
    },

    {
      field: 'unit',
      headerName: '단위',
      flex: 1,
      editable: true,
    },
    {
      field: 'actionEdit',
      headerName: '',
      flex: 1,
      renderCell: (params: GridCellParams) => {
        return (
          <Button
            variant="contained"
            disabled={isDisable}
            onClick={() => handleSubmit(params)}
          >
            편집
          </Button>
        );
      },
    },

    // {
    //   field: 'actionExecution',
    //   headerName: '수행여부',
    //   flex: 1,
    //   editable: true,

    //   renderCell: (params: GridCellParams) => (
    //     <Switch
    //       checked={params.value as boolean}
    //       onClick={() => onExecution(params)}
    //     />
    //   ),
    // },
  ];

  const handleSubmit = async (params: GridCellParams) => {
    try {
      const rowModels = apiRef.current.getRow(params?.id);
      await editActions([rowModels]);
      setIsDisable(true);
    } catch (e) {
      showToast(t, 'error', `전송 실패`);
    }
  };

  const handleAllSubmit = async () => {
    try {
      const modifiedRows: EditActionsProps[] = [];
      const rowModels = apiRef.current.getRowModels();
      rowModels.forEach((value) => {
        modifiedRows.push(value as EditActionsProps);
      });
      await editActions(modifiedRows);
      showToast(t, 'success', '액션 수정 성공');
      setIsDisable(true);
    } catch (e) {
      showToast(t, 'error', `액션 수정 실패`);
    }
  };

  const ActionToolBar = () => {
    return (
      <Box>
        <CustomToolbar />
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            sx={{ margin: 1 }}
            disabled={isDisable}
            variant="contained"
            type="submit"
            onClick={handleAllSubmit}
          >
            한번에 편집
          </Button>
        </Box>
      </Box>
    );
  };

  const handleRowUpdate = async (
    newRow: GridValidRowModel,
    oldRow: GridValidRowModel
  ) => {
    const hasChange =
      newRow.action_name !== oldRow.action_name ||
      newRow.factory !== oldRow.factory ||
      newRow.line !== oldRow.line ||
      newRow.description !== oldRow.description ||
      newRow.inspection_criteria !== oldRow.inspection_criteria ||
      newRow.unit !== oldRow.unit;
    if (hasChange) {
      setIsDisable(false);
      return newRow;
    }
    return oldRow;
  };

  const FilterdActionList = useMemo(() => {
    return actionList?.filter((action) => action !== null);
  }, [actionList]);

  return (
    <MainContainer theme={theme}>
      <Paper>
        <Stack direction="row" columnGap={5} m={2}>
          <Typography variant="h4">
            scout미션명 : {mission?.mission_name}
          </Typography>
          <Typography variant="h4">별칭 : {mission?.alias}</Typography>
          <Typography variant="h4">수정일 :{mission?.modified_at}</Typography>
        </Stack>
        <DataGrid
          sx={{ minHeight: 400 }}
          rows={FilterdActionList || []}
          columns={columns}
          getRowId={(action) => action.action_uuid}
          apiRef={apiRef}
          editMode="row"
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          processRowUpdate={handleRowUpdate}
          onProcessRowUpdateError={() => showToast(t, 'error', 'change failed')}
          slots={{
            toolbar: ActionToolBar,
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          localeText={
            locale === 'koKR'
              ? koKR.components.MuiDataGrid.defaultProps.localeText
              : enUS.components.MuiDataGrid.defaultProps.localeText
          }
        />
      </Paper>
    </MainContainer>
  );
};
