import { useTranslation } from 'react-i18next';

export const convertMillisecondsToDHM = (milliseconds: number): string => {
  const { t } = useTranslation();

  const elapsedMinutes = Math.floor((milliseconds - Date.now()) / (1000 * 60));

  const days = Math.floor(elapsedMinutes / (24 * 60));
  const hours = Math.floor((elapsedMinutes % (24 * 60)) / 60);
  const minutes = elapsedMinutes % 60;

  const dayStr = days > 0 ? `${days}${t('day')} ` : '';
  const hourStr = hours > 0 ? `${hours}${t('hours')} ` : '';
  const minuteStr = minutes > 0 ? `${minutes}${t('minutes')} ` : '';

  return `${dayStr}${hourStr}${minuteStr}`;
};
