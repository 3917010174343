import { Box, Select, MenuItem, Checkbox, ListItemText } from '@mui/material';
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';

interface CheckList {
  name: string;
  is_checked: boolean;
}

export const DataGridEditCheckBox = ({
  id,
  value,
  field,
}: GridRenderCellParams) => {
  const filterdList = value.filter((el: CheckList) => el.is_checked);
  const apiRef = useGridApiContext();
  const { t } = useTranslation();

  const handleCheckboxChange = (el: CheckList) => {
    const updatedList = value.map((value: CheckList) =>
      value.name === el.name
        ? { ...value, is_checked: !value.is_checked }
        : value
    );

    apiRef.current.setEditCellValue({ id, field, value: updatedList });
  };

  return (
    <Box>
      <Select
        sx={{
          width: '150px',
          '& .MuiInputBase-root': {
            borderRadius: 0,
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: 0,
          },
        }}
        multiple
        value={filterdList.map((el: CheckList) => t(el.name))}
        renderValue={(selected) => selected.join(', ')}
      >
        {value.map((el: CheckList) => (
          <MenuItem key={el.name} value={el.name}>
            <Checkbox
              checked={el.is_checked}
              onChange={() => handleCheckboxChange(el)}
            />
            <ListItemText primary={t(el.name)} />
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export const renderEditCheckbox = (params: GridRenderCellParams) => {
  return <DataGridEditCheckBox {...params} />;
};
