import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { updateAddress } from '@/api/address';
import { AxiosError } from 'axios';

interface UpdateAddressResponse {
  id: number;
  rank: string;
  name: string;
  phone: string;
  email: string;
  in_charge: string;
  alarm_level: AlarmLevel[];
}
interface AlarmLevel {
  name: string;
  is_checked: boolean;
}
export interface UpdateAddressParams {
  rank: string;
  name: string;
  phone: string;
  email: string;
  to_email: string;
  in_charge: string;
  alarm_level: AlarmLevel[];
}

const useUpdateAddress = (
  options?: UseMutationOptions<
    UpdateAddressResponse,
    AxiosError,
    UpdateAddressParams
  >
) => {
  const fetcher = async (variable: UpdateAddressParams) => {
    const result = await updateAddress(variable);
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useUpdateAddress;
