import { client } from '@api/client';

export const fetchLogo = () => {
  return client.get(`/core/config/logo`);
};

export const fetchFactory = () => {
  return client.get(`/core/config/factory`);
};

export const fetchSMS = () => {
  return client.get(`/core/config/sms`);
};

export const registerSMS = (params: registerSMSParams) => {
  return client.post(`/core/config/sms`, {
    data: {
      agency: params.agency,
      time: params.time,
      sender_phone_number: params.senderPhoneNumber,
      api_key: params.apiKey,
      default: true,
    },
  });
};

export const registerSMTP = (params: registerSMTPParams) => {
  return client.post(`/core/config/smtp`, {
    data: {
      smtp_detail: {
        host_address: params.address,
        host_name: params.name,
        host_user: params.id,
        host_password: params.password,
        host_port: params.port,
      },
      enable: true,
    },
  });
};

export const registerScoutInfo = (params: registerScoutInfoParams) => {
  return client.post(`/core/config/scoutinfo`, {
    data: {
      token: params.token,
    },
  });
};

export const registerLogo = (params: registerLogoParams) => {
  return client.post(
    `/core/config/logo`,
    {
      image: params.logoImage,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export const registerFactory = (factoryName: string) => {
  return client.post(`/core/config/factory`, {
    data: {
      name: factoryName,
    },
  });
};

export const updateSMS = (params: updateSMSParams) => {
  return client.put(`/core/config/sms/${params.id}`, {
    data: {
      id: params.id,
      agency: params.agency,
      api_key: '*',
      sender_phone_number: params.senderPhoneNumber,
      default: params.default,
      is_need_config: params.is_need_config,
      setting_time: params.settingTime,
    },
  });
};

export const updateFactory = (params: updateFactoryParams) => {
  return client.put(`/core/config/factory/${params.id}`, {
    data: {
      name: params.name,
    },
  });
};

export const deleteFactory = (id: number) => {
  return client.delete(`/core/config/factory/${id}`);
};

interface registerSMSParams {
  apiKey: string;
  senderPhoneNumber: number;
  agency: string;
  time: string;
}

interface registerSMTPParams {
  address: string;
  name: string;
  id: string;
  password: string;
  port: number;
}

interface registerScoutInfoParams {
  token: string;
}

interface registerLogoParams {
  logoImage: File;
}

interface updateSMSParams {
  id: number;
  agency: string;
  api_key: string;
  senderPhoneNumber: string;
  default: boolean;
  is_need_config: boolean;
  settingTime: string;
}

interface updateFactoryParams {
  id: number;
  name: string;
  manager: string;
}
