import { HeaderProvider } from '@/hooks/constate/HeaderContext';
import { AppBar, Box, CssBaseline, Toolbar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Outlet } from 'react-router';
import { Header, SidebarPage } from '.';

export const MainLayout = () => {
  const theme = useTheme();

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <HeaderProvider>
        <AppBar
          enableColorOnDark
          position="fixed"
          color="inherit"
          elevation={0}
          sx={{
            bgcolor: theme.palette.background.paper,
            padding: 0,
          }}
        >
          <Toolbar>
            <Header />
          </Toolbar>
          <Box sx={{ display: 'flex' }}>
            <SidebarPage />
            <Box
              component="main"
              sx={{
                width: '30%', // for xs, sm screens
                backgroundColor: '#eef2f6',
                minHeight: 'calc(100vh - 88px)',
                height: 'calc(100vh - 88px)',
                flexGrow: 1,
                padding: '20px',
                margin: '20px 20px 10px 10px',
                borderRadius: '12px',
                overflowY: 'auto',
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </AppBar>
      </HeaderProvider>
    </Box>
  );
};
