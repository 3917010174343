import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { registerSchedule } from '@/api/schedule';

interface ScheduleResponse {
  mission: ScheduledMission[];
  robots: ScheduledRobot[];
  calendar?: Calendar;
}

interface ScheduledMission {
  uuid: string;
  mission_id: number;
  name: string;
  saved_at: string;
}

interface ScheduledRobot {
  robotIndex: number;
  hostname: string;
  username: string;
  nickname: string;
}

interface Calendar {
  activeEvents: ActiveEvent[];
}

interface ActiveEvent {
  eventMetadata: EventMetadata;
  agent: Agent;
  task: Task;
  schedule: Schedule;
  booking?: BookingData;
}
interface BookingData {
  is_email_received: boolean;
  is_sms_received: boolean;
}

interface EventMetadata {
  eventId: string;
  name: string;
  modificationTimeMs: string;
  modificationUser: string;
}

interface Agent {
  nickname: string;
}

interface Task {
  missionId: string;
}

interface Schedule {
  timeMs: string;
  repeatMs: string;
}
interface RegisterScheduleParams {
  robotNickname: string;
  date: number;
  repeatMs: number;
  missionId: string;
  eventName: string;
  isSMSChecked: boolean;
  isEmailChecked: boolean;
}

const useRegisterSchedule = (
  options?: UseMutationOptions<
    ScheduleResponse,
    AxiosError,
    RegisterScheduleParams
  >
) => {
  const fetcher = async (variable: RegisterScheduleParams) => {
    const result = await registerSchedule(variable);
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useRegisterSchedule;
