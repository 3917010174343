import { rest } from 'msw';
import { mockSMSData } from './data/coreConfig/mockSMSData';
import { mockAddressFactoriesData } from './data/mockAddressFactoriesData';

const baseUrl = process.env.REACT_APP_BACKEND_ENDPOINT_URL;

export const coreConfigHandlers = [
  rest.get(`${baseUrl}/core/config/sms`, (req, res, ctx) => {
    return res(ctx.status(201), ctx.json(mockSMSData));
  }),
  rest.post(`${baseUrl}/core/config/sms`, (req, res, ctx) => {
    // You can process or store the data as needed
    return res(ctx.status(201));
  }),

  rest.put(`${baseUrl}/core/config/sms/:id`, (req, res, ctx) => {
    // You can process or store the data as needed
    return res(ctx.status(201));
  }),

  rest.get(`${baseUrl}/core/config/logo`, (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({ url: 'logo/Logo_Slogan_Combination_Vertical_nwiXuh9.png' })
    );
  }),

  rest.post(`${baseUrl}/core/config/smtp`, (req, res, ctx) => {
    // You can process or store the data as needed
    return res(ctx.status(201));
  }),

  // rest.post(`${baseUrl}/core/config/scoutinfo`, (req, res, ctx) => {
  //   // You can process or store the data as needed
  //   return res(ctx.status(201));
  // }),
  rest.post(`${baseUrl}/core/config/logo`, (req, res, ctx) => {
    // You can process or store the data as needed
    return res(ctx.status(201));
  }),

  rest.post(`${baseUrl}/core/config/factory`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),

  rest.put(`${baseUrl}/core/config/factory/:id`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  rest.delete(`${baseUrl}/core/config/factory/:id`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
];
