import { useEffect, useMemo, useState, useRef } from 'react';
import { useReportContext } from '@/hooks/constate/ReportContext';
import { getReportListColumns } from './ReportListDataGridColumns';
import { textLengthOverCut } from '@/utils/textLengthOverCut';
import { GridCellParams, DataGrid, koKR, enUS } from '@mui/x-data-grid';
import { ReportListCustomToolbar } from './ReportListCustomToolbar';
import { useLangContext } from '@/hooks/constate/LangContext';
import CustomNoRowsOverlay from '../Common/CustomNoRowsOverlay';
import { ReportDataGridFooter } from './ReportDataGridFooter';

const ReportListTable = () => {
  const { setFilters, reports } = useReportContext();
  const columns = getReportListColumns();
  const { locale } = useLangContext();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 25,
    page: 0,
  });

  const usePrevious = (value: number): number => {
    const ref = useRef<number>(0);
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };
  const prevPage = usePrevious(paginationModel.page);
  const prevSize = usePrevious(paginationModel.pageSize);

  useEffect(() => {
    const { pageSize, page } = paginationModel;

    if (prevPage < page || prevSize !== pageSize) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        listPage:
          page % 10 === 0 ? Math.floor(page / 10) + 1 : prevFilters.listPage,
        limit: pageSize * 5,
      }));
    }
  }, [paginationModel]);

  const rows = useMemo(
    () =>
      reports?.reports.map((report) => {
        return {
          id: report.uuid,
          missionName: textLengthOverCut(report.mission[0].name, 24),
          startTime: report.mission[0].start_time.slice(0, -3),
          executingRobot: report.robot[0].info.name,
          status: report.mission[0].status,
          openReport: report.uuid,
          openInNewWindow: report.uuid,
        };
      }),
    [reports]
  );

  return (
    <>
      <DataGrid
        sx={{
          minHeight: '400px',
          '& .success': { color: '#357a38' },
          '& .failure': { color: '#b2102f' },
        }}
        rows={rows || []}
        columns={columns}
        getCellClassName={(params: GridCellParams) => {
          if (params.field === 'status') {
            if (params.value === 'SUCCESS') return 'success';
            else return 'failure';
          } else return '';
        }}
        checkboxSelection
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        disableRowSelectionOnClick
        slots={{
          toolbar: ReportListCustomToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
          footer: ReportDataGridFooter,
        }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 300 },
          },
        }}
        localeText={
          locale === 'koKR'
            ? koKR.components.MuiDataGrid.defaultProps.localeText
            : enUS.components.MuiDataGrid.defaultProps.localeText
        }
      />
    </>
  );
};

export default ReportListTable;
