import { Avatar, Box } from '@mui/material';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';

export const ProfileSection = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box
      sx={{
        mr: 1,
        [theme.breakpoints.down('md')]: {
          mr: 2,
        },
      }}
    >
      <Avatar
        variant="rounded"
        aria-haspopup="true"
        onClick={() => navigate('/address')}
        color="inherit"
        sx={{ width: '50px', height: '50px' }}
      >
        <PermContactCalendarIcon />
      </Avatar>
    </Box>
  );
};
