import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { registerLogo } from '@/api/coreConfig';

interface registerLogoParams {
  logoImage: File;
}
const useRegisterLogo = (
  options?: UseMutationOptions<
    registerLogoParams,
    AxiosError,
    registerLogoParams
  >
) => {
  const fetcher = async (variable: registerLogoParams) => {
    const result = await registerLogo(variable);
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useRegisterLogo;
