import { useLangContext } from '@/hooks/constate/LangContext';
import { Box } from '@mui/material';
import { DataGrid, koKR, enUS } from '@mui/x-data-grid';
import { CustomToolbar } from '../Address/DataGridCustomToolbar';
import useMissionList from '@/hooks/remote/useMissionList';
import CustomNoRowsOverlay from '../Common/CustomNoRowsOverlay';
import { getMissionColumns } from './GetMissionColumns';
import { MissionSyncButton } from './MissionSyncButton';

export const MissionTable = () => {
  const { locale } = useLangContext();
  const columns = getMissionColumns();

  const { data: missionList } = useMissionList(undefined);

  return (
    <Box sx={{ minHeight: 400, width: '100%' }}>
      <MissionSyncButton />
      <DataGrid
        sx={{ minHeight: 400 }}
        rows={missionList?.filter((mission) => mission !== null) || []}
        columns={columns}
        getRowId={(mission) => mission.mission_uuid}
        checkboxSelection
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 25,
            },
          },
        }}
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: CustomNoRowsOverlay,
        }}
        localeText={
          locale === 'koKR'
            ? koKR.components.MuiDataGrid.defaultProps.localeText
            : enUS.components.MuiDataGrid.defaultProps.localeText
        }
      />
    </Box>
  );
};
