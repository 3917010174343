import { useTranslation } from 'react-i18next';

export const convertDate = (milliSecond: number): string => {
  const { t } = useTranslation();

  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const data = new Date(Number(milliSecond));
  const year = data.getFullYear();
  const month = data.getMonth() + 1;
  const date = data.getDate();
  const day = days[data.getDay()];
  const hours = data.getHours();
  const minutes = data.getMinutes();
  return `${year}.${month}.${date} ${t(`${day}`)} ${hours}${t(
    'hour'
  )} ${minutes}${t('minutes')}`;
};
