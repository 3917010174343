import { useTranslation } from 'react-i18next';
import { GirdTableformatter, phoneFormatter } from '@/utils/formatting';
import { GridColDef } from '@mui/x-data-grid';
import { renderEditCheckbox } from './DataGridEditCheckBox';

export const getColumns = (): GridColDef[] => {
  const { t } = useTranslation();
  return [
    {
      field: 'rank',
      headerName: t('rank') || 'rank',
      width: 150,
      editable: true,
    },
    {
      field: 'name',
      headerName: t('name') || 'name',
      width: 150,
      editable: true,
    },
    {
      field: 'phone',
      headerName: t('phone') || 'phone',
      width: 150,
      editable: true,
      valueGetter: (params) => {
        return phoneFormatter(params.value);
      },
    },
    {
      field: 'email',
      headerName: t('email') || 'email',
      width: 150,
      editable: true,
    },
    {
      field: 'factories',
      headerName: t('factories') || 'factories',
      width: 150,
      editable: true,
      renderEditCell: renderEditCheckbox,
      valueFormatter: (params) => GirdTableformatter(params, t),
    },
    {
      field: 'alarm_types',
      headerName: t('selectedFeature') || 'selectedFeature',
      width: 150,
      editable: true,
      renderEditCell: renderEditCheckbox,
      valueFormatter: (params) => GirdTableformatter(params, t),
    },
  ];
};
