import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import tranEn from './en/en.json';
import tranKo from './ko/ko.json';
export const languages = ['en', 'ko'] as const;
export type Languages = (typeof languages)[number]; // 'en' | 'ko'

const resources = {
  en: { translation: tranEn },
  ko: { translation: tranKo },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ko', // 초기 설정 언어
    fallbackLng: {
      'en-US': ['en-US'],
      default: ['ko-KR'],
    },
    debug: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: true,
    },
  });

export default i18n;
