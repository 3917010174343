import { missionSync } from '@/api/missionSetting';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

interface MissionSyncResponse {
  test: string;
}

const useMissionSync = (
  options?: UseMutationOptions<MissionSyncResponse, AxiosError>
) => {
  const fetcher = async () => {
    const result = await missionSync();
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useMissionSync;
