import { Box } from '@mui/material';
import ScheduleCard from './ScheduleCard';
interface BookingData {
  is_email_received: boolean;
  is_sms_received: boolean;
}

interface EventMetadata {
  eventId: string;
  name: string;
  modificationTimeMs: string;
  modificationUser: string;
}

interface Agent {
  nickname: string;
}

interface Task {
  missionId: string;
}

interface Schedule {
  timeMs: string;
  repeatMs: string;
}

interface ActiveEvent {
  eventMetadata: EventMetadata;
  agent: Agent;
  task: Task;
  schedule: Schedule;
  booking?: BookingData;
}

interface TodayScheduleListProps {
  schedules: ActiveEvent[];
}

export const TodayList = ({ schedules }: TodayScheduleListProps) => {
  const filteredSchedules = schedules?.filter(
    (schedule) => !schedule.eventMetadata.name.includes('Driver')
  );

  return (
    <Box>
      {filteredSchedules?.map((schedule, index) => (
        <ScheduleCard key={index} index={index} schedule={schedule} />
      ))}
    </Box>
  );
};
