import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { queryKey } from './querykey';
import { fetchSchedule } from '@/api/schedule';
interface ScheduleResponse {
  mission: ScheduledMission[];
  robots: ScheduledRobot[];
  calendar?: Calendar;
}

interface ScheduledMission {
  uuid: string;
  mission_id: number;
  name: string;
  saved_at: string;
}

interface ScheduledRobot {
  robotIndex: number;
  hostname: string;
  username: string;
  nickname: string;
}

interface Calendar {
  activeEvents: ActiveEvent[];
}
export interface ActiveEvent {
  eventMetadata: EventMetadata;
  agent: Agent;
  task: Task;
  schedule: Schedule;
  booking?: BookingData;
}
export interface BookingData {
  is_email_received: boolean;
  is_sms_received: boolean;
}

export interface EventMetadata {
  eventId: string;
  name: string;
  modificationTimeMs: string;
  modificationUser: string;
}

export interface Agent {
  nickname: string;
}

export interface Task {
  missionId: string;
}

export interface Schedule {
  timeMs: string;
  repeatMs: string;
}
const useSchedules = (
  variable = undefined,
  options?: UseQueryOptions<ScheduleResponse, AxiosError>
) => {
  const fetcher = async () => {
    const result = await fetchSchedule();
    return result.data;
  };

  return useQuery<ScheduleResponse, AxiosError>(
    [queryKey.fetchSchedule, variable],
    fetcher,
    options
  );
};

export default useSchedules;
