export const displayThresholdRange = (
  minThreshold: string,
  maxThreshold: string
): string => {
  return getDisplayRange(minThreshold, maxThreshold);
};

const getDisplayRange = (min: string, max: string): string => {
  if (min === '-' || max === '-') {
    return min === '-' ? max : min;
  }
  return `${min} ~ ${max}`;
};
