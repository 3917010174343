import { Paper } from '@mui/material';
import { LoadingPage } from './LoadingPage';
import { MainContainer } from '@/components/myStyle/MainContainer';
import { useTheme } from '@mui/material/styles';
import { Suspense } from 'react';
import { SystemForm } from '@/components/SystemSetting/SystemForm';

export const SystemSettingPage = () => {
  return (
    <Suspense fallback={<LoadingPage loadingText={'시스템 세팅 로딩 중..'} />}>
      <SystemSetting />
    </Suspense>
  );
};

const SystemSetting = () => {
  const theme = useTheme();

  return (
    <MainContainer theme={theme}>
      <Paper>
        <SystemForm />
      </Paper>
    </MainContainer>
  );
};
