import { useReportContext } from '@/hooks/constate/ReportContext';
import { getDetectedNumber } from '@/utils/getDetectedNumber';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
  Box,
} from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const RealtimeDetection = () => {
  const { report } = useReportContext();
  const { t } = useTranslation();

  const fallenHuman = useMemo(() => {
    if (!report) return 0;
    return getDetectedNumber(report.action, 'Fallen');
  }, [report]);

  const highTemperature = useMemo(() => {
    if (!report) return 0;
    return getDetectedNumber(report.action, 'Thermal');
  }, [report]);

  return (
    <Grid item xs={12}>
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 4,
          padding: 2,
          border: 1,
          borderColor: 'grey.400',
        }}
      >
        <Typography variant="h5" gutterBottom>
          {t('constantSurveillance')}
        </Typography>
        <TableContainer>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>{t('unconsciousPatient')}</TableCell>
                <TableCell align="right">
                  {fallenHuman} {t('times')}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>{t('abnormalTemperature')}</TableCell>
                <TableCell align="right">
                  {highTemperature} {t('times')}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Grid>
  );
};
