import { Box, Grid, Paper, Typography } from '@mui/material';
import AcccessibleTable from '@components/Dashboard/RobotStatus';
import HorizontalStepperWithError from '@components/Dashboard/Step';

// const StyledImg = styled.img`
//   width: 100%;
//   height: 90%;
//   position: relative;
// `;

const Dashboard = () => {
  return (
    <Box>
      <Grid container spacing={4}>
        {/* p는 패딩을 뜻함 */}
        <Grid item xs={12} md={8} lg={7}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 400,
            }}
          >
            <AcccessibleTable />
            <HorizontalStepperWithError />
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={4} lg={5}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              height: 400,
            }}
          >
            <Typography>Map</Typography>
            {/* <StyledImg src={Testimg} alt={Testimg} /> */}
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
