import {
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormControlProps,
  FormHelperText,
  Radio,
  RadioGroup,
  RadioProps,
  Typography,
} from '@mui/material';
import { ChangeEvent } from 'react';
import {
  useController,
  FieldValues,
  FieldPath,
  UseControllerProps,
} from 'react-hook-form';

type TRadioGroup = Omit<FormControlLabelProps, 'control'>;

interface MuiRadioGroupProps {
  group: TRadioGroup[];
  FormControlProps?: FormControlProps;
  FormRadioProps?: RadioProps;
  label: string;
}

export const FormRadio = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  group,
  FormControlProps,
  FormRadioProps,
  ...props
}: MuiRadioGroupProps & UseControllerProps<TFieldValues, TName>) => {
  const {
    field: { name, onChange, value },
    fieldState: { error: fieldError },
  } = useController(props);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  return (
    <FormControl
      sx={{
        border: 1,
        borderColor: '#CDD5DF',
        width: '100%',
        padding: 1,
        marginBottom: 1,
      }}
      {...FormControlProps}
      error={!!fieldError}
    >
      <RadioGroup row name={name} value={value} onChange={handleChange}>
        <Typography variant="subtitle2" mr={3}>
          {props.label}
        </Typography>
        {group.map(({ value: radioValue, disabled, label }, index) => (
          <FormControlLabel
            key={index}
            value={radioValue}
            label={label}
            control={
              <Radio
                {...FormRadioProps}
                value={radioValue}
                disabled={disabled}
              />
            }
          />
        ))}
      </RadioGroup>
      <FormHelperText>{fieldError?.message}</FormHelperText>
    </FormControl>
  );
};
