import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import DataTable from './DataTable';
import { useLayoutEffect, useMemo, useState } from 'react';
import { MissionMap } from './MissionMap';
import { MissionSummary } from './MissionSummary';
import { RealtimeDetection } from './RealtimeDetection';
import { PathSummary } from './PathSummary';
import { useReportContext } from '@/hooks/constate/ReportContext';
import { useTranslation } from 'react-i18next';
import mc from '@/utils/mc';
import useConfigLogo from '@/hooks/remote/useConfigLogo';
import { PRE_SIGNED_URL_EXPIRATION_TIME } from './AlarmReport';
import { showToast } from '@/hooks/toast/toastUtils';

interface FilteredActionsProps {
  filteredActions: FilteredActions[];
}

interface FilteredActions {
  action_name: string;
  data_captures: DataCapture[];
}
interface DataCapture {
  time: string;
  dataUrl: string;
  key_result: KeyResult[];
  channelName: string;
  full_dataUrl: string;
}

interface KeyResult {
  name: string;
  value: string;
}

export const PatrolDetail = ({ filteredActions }: FilteredActionsProps) => {
  const { report } = useReportContext();
  const { t } = useTranslation();
  const { data: fetchLogo } = useConfigLogo();
  const [image, setImage] = useState<string>();

  const getPreSignedUrl = async (img: string) => {
    try {
      const presignedUrl = await mc.presignedUrl(
        'GET',
        'spot-bucket',
        img,
        PRE_SIGNED_URL_EXPIRATION_TIME
      );
      setImage(presignedUrl);
    } catch (err) {
      showToast(t, 'error', `presigned err ${err}`);
    }
  };

  useLayoutEffect(() => {
    if (fetchLogo) getPreSignedUrl(fetchLogo?.url);
  }, [fetchLogo]);

  const missionTitle = useMemo(() => {
    if (!report) return '';
    return report.mission[0].mission_alias
      ? report.mission[0].mission_alias
      : report.mission[0].name;
  }, [report]);

  const performanceRobotName = useMemo(() => {
    if (!report) return '';
    return report?.robot[0]?.info?.name;
  }, [report]);

  const factoryName = useMemo(() => {
    if (!report?.mission[0].factory_alias)
      return '미션 설정에서 공장 별칭을 입력해주세요';
    return report?.mission[0].factory_alias;
  }, [report]);

  return (
    <Paper>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h4" sx={{ margin: 1 }}>
          SPOT {t('dailyInspectionReport')}
        </Typography>
        <Box mb={1}>
          {image ? (
            <img
              src={image}
              width="150"
              height="80"
              referrerPolicy="no-referrer"
              alt="이미지가 없습니다."
            />
          ) : (
            <CircularProgress /> // Show the loading spinner while image is being fetched
          )}
        </Box>
      </Box>
      <Divider sx={{ borderBottom: '1px solid ', marginBottom: 2 }} />
      <Stack mb={4}>
        <Typography variant="h6">
          {t('factory')}: {factoryName}
        </Typography>
        <Typography variant="h6">
          {t('executingRobot')}: {performanceRobotName}
        </Typography>
        <Typography variant="h6">
          {t('mission')}: {missionTitle}
        </Typography>
      </Stack>
      <MissionSummary />
      <Grid container spacing={4} sx={{ mb: 3 }}>
        <MissionMap />
        <Grid item xs={12} md={6}>
          <Grid container spacing={4}>
            <RealtimeDetection />
            <PathSummary />
          </Grid>
        </Grid>
      </Grid>
      <DataTable filteredActions={filteredActions} />
    </Paper>
  );
};
