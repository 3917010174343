/* eslint-disable @typescript-eslint/no-non-null-assertion */
import * as Minio from 'minio';

let endPoint = process.env.REACT_APP_MINIO_ENDPOINT_URL;
if (process.env.REACT_APP_ENV !== 'dev') {
  endPoint = `${window.location.hostname}`;
}

const mc = new Minio.Client({
  endPoint: endPoint!,
  useSSL: process.env.REACT_APP_MINIO_SSL === 'true',
  port: parseInt(process.env.REACT_APP_MINIO_PORT!),
  accessKey: process.env.REACT_APP_MINIO_ACCESSKEY!,
  secretKey: process.env.REACT_APP_MINIO_SECRETKEY!,
});

export default mc;
