import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchReports } from '@api/report';
import { queryKey } from './querykey';

interface ReportsParams {
  listPage: number;
  limit: number;
  MissionName?: string[];
  endDate?: string;
  startDate?: string;
  Robot?: string[];
  SuccessFailure?: string[];
}
interface ReportsResponse {
  reports: Report[];
  pageCnt: number;
  curPage: number;
}

interface Report {
  uuid: string;
  url: string;
  mission: Mission[];
  robot: Robot[];
  created_at: string;
}

interface Mission {
  name: string;
  status: string;
  start_time: string;
  end_time: string;
  time_spent: TimeSpent;
  mission_route: string;
  map_url: string;
}
interface Robot {
  info: RobotInfo;
  name: string;
  subscribers: unknown[];
}
interface RobotInfo {
  name: string;
  ip: string;
}
interface TimeSpent {
  hours: number;
  minutes: number;
  seconds: number;
}

const useReports = (
  variable: ReportsParams,
  options?: UseQueryOptions<ReportsResponse, AxiosError>
) => {
  const fetcher = async () => {
    const result = await fetchReports(variable);
    return result.data;
  };

  return useQuery<ReportsResponse, AxiosError>(
    [queryKey.fetchReports, variable],
    fetcher,
    options
  );
};

export default useReports;
