interface ActionResponse {
  mission_uuid: string;
  action_uuid: string;
  action_name: string;
  source_action_create_at: string;
  source_action_modified_at: string;
  alias: string | null;
  create_at: string;
  modified_at: string;
  factory: string | null;
  line: string | null;
  description: string | null;
  unit: string | null;
  action_enable: boolean;
  inspection_type: string | null;
  inspection_criteria: string | null;
}

export const mockActionListResponse: ActionResponse[] = [
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'b298c01b-2b8d-4612-ab5e-f1f573d0b548',
    action_name: 'Chemical - CH2 - HOLDING_Furnace_EF',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.533539+09:00',
    modified_at: '2023-07-10T14:08:21.533544+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'f1ccc55f-9f9e-4c96-9d7f-0c9cf78c5258',
    action_name: 'Localize 521 -1',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.535758+09:00',
    modified_at: '2023-07-10T14:08:21.535761+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '21c9deeb-050c-4053-a357-5279c5f224b3',
    action_name: 'Thermal Inspection - HOLDING - Furnace_CD',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.537717+09:00',
    modified_at: '2023-07-10T14:08:21.537720+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'dc42f83b-a03b-46aa-bc40-7f18a0e337c0',
    action_name: 'Go Here 2',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.539545+09:00',
    modified_at: '2023-07-10T14:08:21.539547+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '844102f7-329f-4d8c-97e5-0662125bbdad',
    action_name: 'Localize 525',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.542563+09:00',
    modified_at: '2023-07-10T14:08:21.542572+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '31dd6f08-7830-4b30-ae05-d1e53cee33e6',
    action_name: 'Extension Finished: 9',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.549008+09:00',
    modified_at: '2023-07-10T14:08:21.549013+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '3c383200-07d6-4a38-b230-e326bd5d7a18',
    action_name: 'Extension Finished: 6',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.551436+09:00',
    modified_at: '2023-07-10T14:08:21.551439+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '09d04b2a-6a3a-4fbe-a90d-d9d55039cc5c',
    action_name: 'Thermal Inspection - CH2 - Holding_Furnace_A',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.555132+09:00',
    modified_at: '2023-07-10T14:08:21.555135+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '61d02ea2-d91c-404f-9a80-17ea2f5cfb82',
    action_name: 'Thermal Inspection - CH3 - AP_Furnace',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.556993+09:00',
    modified_at: '2023-07-10T14:08:21.556995+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: true,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '1d62a8cd-a8fd-4aff-9687-f077dc09d4f9',
    action_name: 'Extension Finished: 2',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.558772+09:00',
    modified_at: '2023-07-10T14:08:21.558774+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '86ce2543-a1d4-4e54-a6cc-2f553aeb0e74',
    action_name: 'Chemical - CH2 - Holding_Furnace_A',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.560720+09:00',
    modified_at: '2023-07-10T14:08:21.560722+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'b86c6ffe-55df-40bf-9b22-e591b9367f7b',
    action_name: 'Localize 523 - 1',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.562912+09:00',
    modified_at: '2023-07-10T14:08:21.562916+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '55fde10f-887c-46a8-a34c-54922ed1ce86',
    action_name: 'Extension Finished: 10',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.564933+09:00',
    modified_at: '2023-07-10T14:08:21.564935+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '8dea2ae2-6a43-443f-a0da-9381deb8f6c3',
    action_name: 'Groupname Acquisition 1',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.566684+09:00',
    modified_at: '2023-07-10T14:08:21.566686+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: true,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '6a1209b0-dd01-41b1-abbc-0f94699c2957',
    action_name: 'Pincam - CH2 - USF_2',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.568420+09:00',
    modified_at: '2023-07-10T14:08:21.568421+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: true,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '72767ec8-b2d6-4211-bacc-11a3bbe9e0bf',
    action_name: 'Chemical - CH2 - HOLDING_Furnace_CD',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.570234+09:00',
    modified_at: '2023-07-10T14:08:21.570237+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '0fea87a8-d1dd-40c7-bd0a-6a6407f0a920',
    action_name: 'Chemical - CB2 - HOLDING_Furnace_B',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.573501+09:00',
    modified_at: '2023-07-10T14:08:21.573510+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '3d94242f-c810-4c41-bbec-48bb687191ad',
    action_name: 'Chemical - CH3 - AP_Furnace',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.578511+09:00',
    modified_at: '2023-07-10T14:08:21.578520+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: true,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '754ef9b5-f0b0-4b98-962d-016e1ff4b493',
    action_name: 'Thermal Inspection - CB2 - HOLDING_Furnace_B',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.581380+09:00',
    modified_at: '2023-07-10T14:08:21.581386+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'f5ff6b2e-2dbb-409c-bccb-3edd6a77b734',
    action_name: 'Chemical Action 6',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.584231+09:00',
    modified_at: '2023-07-10T14:08:21.584238+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'c8a675b2-2e75-4f72-8602-5ee5666894fc',
    action_name: 'Chemical - CH2 - NLP_A5',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.589945+09:00',
    modified_at: '2023-07-10T14:08:21.589955+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '05ec7c55-187d-42c0-a115-5e91f3b97af5',
    action_name: 'Localize 524',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.593157+09:00',
    modified_at: '2023-07-10T14:08:21.593162+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'a0e39956-6419-4689-b7ac-60b4a04da3c6',
    action_name: 'Extension Finished: 5',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.595188+09:00',
    modified_at: '2023-07-10T14:08:21.595191+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '569649c0-db0b-466a-8e84-4770f35dc247',
    action_name: 'Thermal Inspection - CB3 - HOLDING_Furnace',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.596964+09:00',
    modified_at: '2023-07-10T14:08:21.596967+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: true,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '4565e2a4-1151-4bcb-9179-657b1ea2ddc7',
    action_name: 'Extension Finished: 7',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.598712+09:00',
    modified_at: '2023-07-10T14:08:21.598714+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'f1fb3d80-c3d2-4f86-8097-d24900840242',
    action_name: 'Thermal Inspection - CB3 - PNL_Cooling',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.600681+09:00',
    modified_at: '2023-07-10T14:08:21.600683+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: true,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '8626d1cf-236f-46bf-8932-4df975236440',
    action_name: 'Localize 527 fin',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.602611+09:00',
    modified_at: '2023-07-10T14:08:21.602614+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '3d925eb5-83f8-49d4-b9de-4c8e10695255',
    action_name: 'Chemical - CB3 - PNL_Cooling',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.609153+09:00',
    modified_at: '2023-07-10T14:08:21.609162+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: true,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'b2909718-0503-4c8e-9179-5d9c0ad53434',
    action_name: 'Pincam - CH2 - USF_4',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.613206+09:00',
    modified_at: '2023-07-10T14:08:21.613214+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '9d56687e-d731-42f8-b82f-d7ce29b2724b',
    action_name: 'Thermal Inspection - CB3 - HOLDING_Mani',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.619639+09:00',
    modified_at: '2023-07-10T14:08:21.619641+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: true,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '4fa24276-0c96-4534-9641-0d21699d3204',
    action_name: 'Localize qq',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.621486+09:00',
    modified_at: '2023-07-10T14:08:21.621489+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'bbb80e6c-df17-44ad-bb20-c576dabc5c66',
    action_name: 'Pincam - CH2 - USF_3',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.623431+09:00',
    modified_at: '2023-07-10T14:08:21.623433+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'b07dd08d-142d-426e-864e-f8398897cfa5',
    action_name: 'Extension Finished: 8',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.625554+09:00',
    modified_at: '2023-07-10T14:08:21.625558+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '7352bf90-b28a-4a3d-b48b-2f2de88198fb',
    action_name: 'Localize 527 - 1',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.627388+09:00',
    modified_at: '2023-07-10T14:08:21.627389+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '7a8cf3ca-17b8-472d-9c1c-3003d6dc7c17',
    action_name: 'Mrc - CH2 - GBF',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.629132+09:00',
    modified_at: '2023-07-10T14:08:21.629135+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'bc204d21-ff79-4d1f-9def-17deca85a3bf',
    action_name: 'Localize 523 - 3',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.630973+09:00',
    modified_at: '2023-07-10T14:08:21.630975+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '0d15af89-79f5-4ddc-9145-7e8cc3d82c69',
    action_name: 'Pincam - CH2 - USF_1',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.632793+09:00',
    modified_at: '2023-07-10T14:08:21.632795+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: true,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'e532a10d-4841-4c86-9815-7c4d1dbd9087',
    action_name: 'Localize 2',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.634557+09:00',
    modified_at: '2023-07-10T14:08:21.634559+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '2e1430e1-90f1-4039-a0fc-4a34caa37a9a',
    action_name: 'Start',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.639672+09:00',
    modified_at: '2023-07-10T14:08:21.639674+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'a0903338-b057-4a13-b0cc-ff9025eb6103',
    action_name: 'Thermal Inspection - CH2 - HOLDING_Furnace_EF',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.641627+09:00',
    modified_at: '2023-07-10T14:08:21.641629+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '5ba63843-aaa9-49fa-97e6-c850ddfa9cdf',
    action_name: 'Extension Finished: 3',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.643433+09:00',
    modified_at: '2023-07-10T14:08:21.643435+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '296f0280-ffda-48e8-94b0-c95564643259',
    action_name: 'Thermal Inspection - CB3 - FUR_Cooling',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.645211+09:00',
    modified_at: '2023-07-10T14:08:21.645214+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: true,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '89a2a6f2-4e34-47fb-8b6d-67b787806995',
    action_name: 'Chemical - CH2 - MOLTING_LNG',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.647002+09:00',
    modified_at: '2023-07-10T14:08:21.647004+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'e15c0830-5a80-4515-a391-9f5b2bae9b96',
    action_name: 'Localize 1',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.648802+09:00',
    modified_at: '2023-07-10T14:08:21.648804+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '04cfcd2e-036d-4e40-af03-ae33540960f4',
    action_name: 'Go Here 3',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.650629+09:00',
    modified_at: '2023-07-10T14:08:21.650632+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'e21ef34d-d897-4398-b666-7fedcdec4400',
    action_name: 'End Recording',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.652566+09:00',
    modified_at: '2023-07-10T14:08:21.652568+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '9aedb128-dc62-4c08-ac62-cc69d90fbe40',
    action_name: 'Chemical - CB3 - HOLDING_Furnace',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.654495+09:00',
    modified_at: '2023-07-10T14:08:21.654497+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: true,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'b7f90c60-83da-41f2-96c2-c617dc49f1a1',
    action_name: 'Extension Finished: 11',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.656315+09:00',
    modified_at: '2023-07-10T14:08:21.656317+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '3e6ea884-1fe3-4e10-b115-a5006c3a20f0',
    action_name: 'Localize 526',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.658096+09:00',
    modified_at: '2023-07-10T14:08:21.658099+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'ef197a4e-6d79-4c5c-89ec-6d5a4652da28',
    action_name: 'Thermal Inspection - CH2 - NLP_A5',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.660048+09:00',
    modified_at: '2023-07-10T14:08:21.660051+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '1a6f3012-6e9c-4f64-961b-04701e5d21e8',
    action_name: 'Go Here 1',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.662013+09:00',
    modified_at: '2023-07-10T14:08:21.662016+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: true,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '4146bc9c-b2ba-417f-8230-5b33bed6e5cc',
    action_name: 'Extension Finished: 4',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.665546+09:00',
    modified_at: '2023-07-10T14:08:21.665557+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '105d10fb-e529-4922-8598-d206f36aa52d',
    action_name: 'Chemical - CH2 - POUR_LNG_1',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.668485+09:00',
    modified_at: '2023-07-10T14:08:21.668492+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: 'afcdd3f9-cfcf-4f22-a530-ec2534a71889',
    action_name: 'Chemical Action 7',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.674789+09:00',
    modified_at: '2023-07-10T14:08:21.674792+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '9ce65d98-dc21-404c-8f3d-94e2e4c6b8ee',
    action_name: 'Chemical - CH2 - POUR_LNG',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.676931+09:00',
    modified_at: '2023-07-10T14:08:21.676934+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '330b0c14-b00b-44a1-96fc-1a4de9dde721',
    action_name: 'Chemical Action 5',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: null,
    create_at: '2023-07-10T14:08:21.678938+09:00',
    modified_at: '2023-07-10T14:08:21.678940+09:00',
    factory: null,
    line: null,
    description: null,
    unit: null,
    action_enable: false,
    inspection_type: null,
    inspection_criteria: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '0e94b206-a8a2-45db-a252-236925e0c232',
    action_name: 'Extension Finished: 1',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: 'test1234',
    create_at: '2023-07-10T14:08:21.553359+09:00',
    modified_at: '2023-07-10T14:09:31.728690+09:00',
    factory: 'clobot-factory',
    line: 'spot-line',
    description: 'clobot spot test',
    unit: '섭씨',
    action_enable: false,
    inspection_type: 'min-max',
    inspection_criteria: '10-50',
  },
];
