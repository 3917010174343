interface Action {
  action_name: string;
  data_captures: DataCapture[];
}

interface DataCapture {
  time: string;
  dataUrl: string;
  key_result: KeyResult[];
  channelName: string;
  full_dataUrl: string;
}

interface KeyResult {
  name: string;
  value: string;
}
export const getDetectedNumber = (
  detectedAction: Action[],
  findActionName: string
) => {
  return (
    detectedAction.filter(
      (data) => data.action_name.split('_')[0] === findActionName
    ).length || 0
  );
};
