import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { addAddress } from '@/api/address';
import { AxiosError } from 'axios';

interface AddAddressParams {
  rank: string;
  name: string;
  phone: string;
  email: string;
  in_charge: string;
  alarm_level: AlarmLevel[];
  alarm_types: AlarmTypes[];
  factories: Factories[];
}
interface AlarmTypes {
  name: string;
  is_checked: boolean;
}
interface AlarmLevel {
  name: string;
  is_checked: boolean;
}
interface Factories {
  name: string;
  is_checked: boolean;
}
type AddAddressResponse = ContactInformation[];

interface ContactInformation {
  id: number;
  rank: string;
  name: string;
  phone: string;
  email: string;
  in_charge: string;
  alarm_level: AlarmLevel[];
  factories: Factories[];
  alarm_types: AlarmTypes[];
}

const useAddAddress = (
  options?: UseMutationOptions<AddAddressResponse, AxiosError, AddAddressParams>
) => {
  const fetcher = async (variable: AddAddressParams) => {
    const result = await addAddress(variable);
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useAddAddress;
