import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { registerSMS } from '@/api/coreConfig';

interface registerSMSParams {
  apiKey: string;
  senderPhoneNumber: number;
  agency: string;
  time: string;
}

const useRegisterSMS = (
  options?: UseMutationOptions<registerSMSParams, AxiosError, registerSMSParams>
) => {
  const fetcher = async (variable: registerSMSParams) => {
    const result = await registerSMS(variable);
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useRegisterSMS;
