export interface MissionResponse {
  mission_uuid: string;
  mission_name: string;
  source_mission_create_at: string;
  source_mission_modified_at: string;
  alias: string | null;
  create_at: string;
  modified_at: string;
  file: string | null;
  route_description: string | null;
  factory: string | null;
  detail_route: string | null;
}

export const mockMissionListResponse: MissionResponse[] = [
  {
    mission_uuid: '12099718-8a65-4224-8885-1807ef04c715',
    mission_name: 'dynaparm (copy1)',
    source_mission_create_at: '2023-06-15T13:14:23.864458Z',
    source_mission_modified_at: '2023-06-15T13:14:23.864458Z',
    alias: null,
    create_at: '2023-06-15T22:14:23.864458+09:00',
    modified_at: '2023-06-15T22:14:23.864458+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '13a2aadd-0024-4a89-960c-407f51492034',
    mission_name: 'tt_mission_restart_test',
    source_mission_create_at: '2023-05-31T05:41:56.956016Z',
    source_mission_modified_at: '2023-05-31T05:41:56.956016Z',
    alias: null,
    create_at: '2023-05-31T14:41:56.956016+09:00',
    modified_at: '2023-05-31T14:41:56.956016+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '16bdf06e-7390-4bd3-bff8-2227af58163e',
    mission_name: 'AG2_Press',
    source_mission_create_at: '2023-04-27T00:59:22.228968Z',
    source_mission_modified_at: '2023-05-18T18:28:06.876957Z',
    alias: null,
    create_at: '2023-04-27T09:59:22.228968+09:00',
    modified_at: '2023-05-19T03:28:06.876957+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '1826e279-8c24-4665-82dc-9f7f0124c603',
    mission_name: 'tt_img_test',
    source_mission_create_at: '2023-06-19T07:39:37.103360Z',
    source_mission_modified_at: '2023-06-19T07:39:37.103360Z',
    alias: null,
    create_at: '2023-06-19T16:39:37.103360+09:00',
    modified_at: '2023-06-19T16:39:37.103360+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '18746b3d-00dc-440b-af3c-03d05ad1331f',
    mission_name: '230531_USF_PoC_chem_5min',
    source_mission_create_at: '2023-06-15T08:04:19.608074Z',
    source_mission_modified_at: '2023-06-15T08:04:19.608074Z',
    alias: null,
    create_at: '2023-06-15T17:04:19.608074+09:00',
    modified_at: '2023-06-15T17:04:19.608074+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '18a1b446-5f36-42a7-a9ac-cb6e0d18ff47',
    mission_name: 'chemicalqwer',
    source_mission_create_at: '2023-05-30T10:32:40.383532Z',
    source_mission_modified_at: '2023-05-30T11:03:40.325509Z',
    alias: null,
    create_at: '2023-05-30T19:32:40.383532+09:00',
    modified_at: '2023-05-30T20:03:40.325509+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '1b6f906f-f08d-4002-afa2-ef8f7eb4b6ac',
    mission_name: 'test',
    source_mission_create_at: '2023-07-03T09:53:55.016808Z',
    source_mission_modified_at: '2023-07-03T09:53:55.016808Z',
    alias: null,
    create_at: '2023-07-03T18:53:55.016808+09:00',
    modified_at: '2023-07-03T18:53:55.016808+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '1d20fbd0-13c9-417e-96e5-5705bbb5927e',
    mission_name: 'tttttt',
    source_mission_create_at: '2023-06-13T06:25:36.955347Z',
    source_mission_modified_at: '2023-06-13T06:32:01.128117Z',
    alias: null,
    create_at: '2023-06-13T15:25:36.955347+09:00',
    modified_at: '2023-06-13T15:32:01.128117+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '3d9d113d-a961-43db-aafb-72ccccf8f126',
    mission_name: '230602_USF_PoC_chem_6act',
    source_mission_create_at: '2023-06-15T08:04:20.136612Z',
    source_mission_modified_at: '2023-06-15T08:04:20.136612Z',
    alias: null,
    create_at: '2023-06-15T17:04:20.136612+09:00',
    modified_at: '2023-06-15T17:04:20.136612+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '40536a30-8362-4473-bf7d-e52bcec98f90',
    mission_name: 'AG1_Paint',
    source_mission_create_at: '2023-05-06T09:22:29.550944Z',
    source_mission_modified_at: '2023-05-18T18:25:34.596215Z',
    alias: null,
    create_at: '2023-05-06T18:22:29.550944+09:00',
    modified_at: '2023-05-19T03:25:34.596215+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '42111d61-47e7-48bd-9883-079e2c699343',
    mission_name: 'AG2_Full',
    source_mission_create_at: '2023-04-27T00:56:39.256653Z',
    source_mission_modified_at: '2023-05-05T14:20:35.024207Z',
    alias: null,
    create_at: '2023-04-27T09:56:39.256653+09:00',
    modified_at: '2023-05-05T23:20:35.024207+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '4958a54c-cac3-44ad-b590-cadec52d5e32',
    mission_name: 'AG2_Assembly',
    source_mission_create_at: '2023-04-27T01:12:06.989440Z',
    source_mission_modified_at: '2023-05-18T18:24:44.944722Z',
    alias: null,
    create_at: '2023-04-27T10:12:06.989440+09:00',
    modified_at: '2023-05-19T03:24:44.944722+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '50a6b912-8ace-4317-8978-934e236b47d2',
    mission_name: 'AG2_Body',
    source_mission_create_at: '2023-04-27T01:02:43.978212Z',
    source_mission_modified_at: '2023-05-18T18:25:58.871906Z',
    alias: null,
    create_at: '2023-04-27T10:02:43.978212+09:00',
    modified_at: '2023-05-19T03:25:58.871906+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '580f1471-9564-4514-8ca2-6a36759d4739',
    mission_name: 'real chemical',
    source_mission_create_at: '2023-05-31T09:40:39.889167Z',
    source_mission_modified_at: '2023-05-31T09:40:39.889167Z',
    alias: null,
    create_at: '2023-05-31T18:40:39.889167+09:00',
    modified_at: '2023-05-31T18:40:39.889167+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '5924af46-1e4d-4a9e-bd77-a580f5ed3c37',
    mission_name: '230602_USF_PoC',
    source_mission_create_at: '2023-06-15T08:04:20.002525Z',
    source_mission_modified_at: '2023-06-15T08:04:20.002525Z',
    alias: null,
    create_at: '2023-06-15T17:04:20.002525+09:00',
    modified_at: '2023-06-15T17:04:20.002525+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '5b57c345-29d9-4561-95c9-cc29863b5713',
    mission_name: 'yt_jim-test',
    source_mission_create_at: '2023-05-25T09:12:51.488431Z',
    source_mission_modified_at: '2023-05-25T09:12:51.488431Z',
    alias: null,
    create_at: '2023-05-25T18:12:51.488431+09:00',
    modified_at: '2023-05-25T18:12:51.488431+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '5b5ec786-2f4e-4e5e-b9a2-1ef2b23f968e',
    mission_name: 'AG1_Full',
    source_mission_create_at: '2023-05-06T09:20:17.319259Z',
    source_mission_modified_at: '2023-05-07T09:57:16.546043Z',
    alias: null,
    create_at: '2023-05-06T18:20:17.319259+09:00',
    modified_at: '2023-05-07T18:57:16.546043+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '5dadda1b-d1b0-4397-a98d-c94b3d135f0f',
    mission_name: 'yt_test1',
    source_mission_create_at: '2023-05-25T09:38:07.482556Z',
    source_mission_modified_at: '2023-05-25T09:38:07.482556Z',
    alias: null,
    create_at: '2023-05-25T18:38:07.482556+09:00',
    modified_at: '2023-05-25T18:38:07.482556+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '72951d13-7db1-446a-a378-bec5bb90ae42',
    mission_name: 'AG1_Assembly',
    source_mission_create_at: '2023-05-06T09:25:35.903107Z',
    source_mission_modified_at: '2023-05-06T09:25:35.903107Z',
    alias: null,
    create_at: '2023-05-06T18:25:35.903107+09:00',
    modified_at: '2023-05-06T18:25:35.903107+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '7e1f5ed3-6c37-47c1-b530-2ba167cdbebe',
    mission_name: 'AG1_Old_Paint',
    source_mission_create_at: '2023-05-06T09:23:48.669430Z',
    source_mission_modified_at: '2023-05-06T09:24:12.103406Z',
    alias: null,
    create_at: '2023-05-06T18:23:48.669430+09:00',
    modified_at: '2023-05-06T18:24:12.103406+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '80d3b76c-be72-4efb-80f5-2cae0c04cf05',
    mission_name: 'uw3.3ver_test',
    source_mission_create_at: '2023-04-26T09:18:44.314782Z',
    source_mission_modified_at: '2023-04-28T00:13:57.388387Z',
    alias: null,
    create_at: '2023-04-26T18:18:44.314782+09:00',
    modified_at: '2023-04-28T09:13:57.388387+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: 'a167e132-93a5-443d-8c97-92a291469e41',
    mission_name: 'tt_cpu_check_test',
    source_mission_create_at: '2023-06-15T04:05:51.846760Z',
    source_mission_modified_at: '2023-06-19T05:30:37.423375Z',
    alias: null,
    create_at: '2023-06-15T13:05:51.846760+09:00',
    modified_at: '2023-06-19T14:30:37.423375+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: 'a701fda4-1b29-4c66-a330-d38766fcda77',
    mission_name: 'AG1_New_Paint',
    source_mission_create_at: '2023-05-06T09:27:07.653136Z',
    source_mission_modified_at: '2023-05-07T09:57:53.496845Z',
    alias: null,
    create_at: '2023-05-06T18:27:07.653136+09:00',
    modified_at: '2023-05-07T18:57:53.496845+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: 'a7a1442c-48b1-4229-af7b-1a58d9fd5ed9',
    mission_name: '230601_USF_POC_CB3_2MIN',
    source_mission_create_at: '2023-06-15T08:04:19.753049Z',
    source_mission_modified_at: '2023-06-15T08:04:19.753049Z',
    alias: null,
    create_at: '2023-06-15T17:04:19.753049+09:00',
    modified_at: '2023-06-15T17:04:19.753049+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: 'aa3f42e8-bf12-4b23-8ceb-3bb035ccbf98',
    mission_name: '230602_USF_PoC_abnormal_thermal',
    source_mission_create_at: '2023-06-15T08:04:20.068811Z',
    source_mission_modified_at: '2023-06-15T08:04:20.068811Z',
    alias: null,
    create_at: '2023-06-15T17:04:20.068811+09:00',
    modified_at: '2023-06-15T17:04:20.068811+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: 'b903d9dc-7603-4f8a-99fc-6d0e9c212140',
    mission_name: '230601_USF_POC_CB3_3MIN',
    source_mission_create_at: '2023-06-15T08:04:19.821988Z',
    source_mission_modified_at: '2023-06-15T08:04:19.821988Z',
    alias: null,
    create_at: '2023-06-15T17:04:19.821988+09:00',
    modified_at: '2023-06-15T17:04:19.821988+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: 'bd29ecc5-6e37-48d8-a0ea-2bbf5972b806',
    mission_name: 'tt chemical test',
    source_mission_create_at: '2023-06-01T04:31:36.897107Z',
    source_mission_modified_at: '2023-06-01T04:31:36.897107Z',
    alias: null,
    create_at: '2023-06-01T13:31:36.897107+09:00',
    modified_at: '2023-06-01T13:31:36.897107+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: 'c82b7be5-4079-4475-9f9b-a2faf037cada',
    mission_name: 'tt chemical yeop',
    source_mission_create_at: '2023-06-01T03:55:04.423349Z',
    source_mission_modified_at: '2023-06-01T04:25:59.889761Z',
    alias: null,
    create_at: '2023-06-01T12:55:04.423349+09:00',
    modified_at: '2023-06-01T13:25:59.889761+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: 'cdebc5bf-ef88-4ce0-b1ad-8a4d13ad47d5',
    mission_name: 'yt_test',
    source_mission_create_at: '2023-05-25T09:31:22.863657Z',
    source_mission_modified_at: '2023-05-25T09:31:22.863657Z',
    alias: null,
    create_at: '2023-05-25T18:31:22.863657+09:00',
    modified_at: '2023-05-25T18:31:22.863657+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: 'd279fd6a-16f4-441b-81a8-156b00e52812',
    mission_name: 'tt_0616',
    source_mission_create_at: '2023-06-30T05:46:38.633008Z',
    source_mission_modified_at: '2023-06-30T06:08:35.842489Z',
    alias: null,
    create_at: '2023-06-30T14:46:38.633008+09:00',
    modified_at: '2023-06-30T15:08:35.842489+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: 'daf6b501-b7aa-43c1-b633-e6638741e6bf',
    mission_name: '230601_USF_POC_CB3_1MIN',
    source_mission_create_at: '2023-06-15T08:04:19.665868Z',
    source_mission_modified_at: '2023-06-15T08:04:19.665868Z',
    alias: null,
    create_at: '2023-06-15T17:04:19.665868+09:00',
    modified_at: '2023-06-15T17:04:19.665868+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: 'e1ab6948-98e5-4c8b-8be1-de67aa44b742',
    mission_name: 'US-POC',
    source_mission_create_at: '2023-05-31T16:48:12.299586Z',
    source_mission_modified_at: '2023-05-31T16:48:12.299586Z',
    alias: null,
    create_at: '2023-06-01T01:48:12.299586+09:00',
    modified_at: '2023-06-01T01:48:12.299586+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: 'eb73b352-14fd-4ff4-9ce7-f1d8b34bfe45',
    mission_name: '230601_USF_POC_CB3_5MIN',
    source_mission_create_at: '2023-06-15T08:04:19.945743Z',
    source_mission_modified_at: '2023-06-15T08:04:19.945743Z',
    alias: null,
    create_at: '2023-06-15T17:04:19.945743+09:00',
    modified_at: '2023-06-15T17:04:19.945743+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: 'fd00ec27-7dad-4c89-a1a4-1ff11eccad41',
    mission_name: 'ttpincamdynam',
    source_mission_create_at: '2023-06-21T09:24:40.972791Z',
    source_mission_modified_at: '2023-06-21T09:24:40.972791Z',
    alias: null,
    create_at: '2023-06-21T18:24:40.972791+09:00',
    modified_at: '2023-06-21T18:24:40.972791+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: 'ff644c6f-6b7d-47bc-870b-9a7e599dc097',
    mission_name: 'AG2_Paint',
    source_mission_create_at: '2023-04-27T01:05:48.463881Z',
    source_mission_modified_at: '2023-05-18T18:26:36.135867Z',
    alias: null,
    create_at: '2023-04-27T10:05:48.463881+09:00',
    modified_at: '2023-05-19T03:26:36.135867+09:00',
    file: null,
    route_description: null,
    factory: null,
    detail_route: null,
  },
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    mission_name: '230601_USF_POC_CB3_4MIN',
    source_mission_create_at: '2023-06-15T08:04:19.884225Z',
    source_mission_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: '빡세',
    create_at: '2023-06-15T17:04:19.884225+09:00',
    modified_at: '2023-07-10T14:09:18.247678+09:00',
    file: 'http://localhost:9000/route-map-bucket/2023-7-10/%E1%84%82%E1%85%B3%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC_%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9_DC2oWiK.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20230710%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230710T073845Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=df4a45860460c2c35b3a8c720eac8d8e229ec659e5e666640498b0618ab8b042',
    route_description: '몰123',
    factory: '123',
    detail_route: '1 - 2 - 3 - 4',
  },
];
