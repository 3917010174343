import { useLangContext } from '@/hooks/constate/LangContext';
import { Languages, languages } from '@/i18n/i18n';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const LanguageToggle = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language as Languages;
  const { setLocale } = useLangContext();

  const handleChangeLanguage = (
    event: React.MouseEvent<HTMLElement>,
    lang: Languages
  ) => {
    if (lang === 'ko') setLocale('koKR');
    else setLocale('enUS');
    i18n.changeLanguage(lang);
  };

  return (
    <ToggleButtonGroup
      value={currentLanguage}
      exclusive
      onChange={handleChangeLanguage}
      aria-label="language-toggle"
    >
      {languages.map((lang) => (
        <ToggleButton key={lang} value={lang} aria-label={`language-${lang}`}>
          {t(`language_${lang}`)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};
