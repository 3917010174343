import { mockAddressFactoriesData } from './data/mockAddressFactoriesData';
import { rest } from 'msw';
import { mockAddressData } from './data/mockAddressData';

const baseUrl = process.env.REACT_APP_BACKEND_ENDPOINT_URL;

export const addressHandlers = [
  rest.get(`${baseUrl}/address/`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockAddressData));
  }),
  rest.post(`${baseUrl}/address/`, (req, res, ctx) => {
    // You can process or store the data as needed
    return res(
      ctx.status(200),
      ctx.json({ message: 'Address added successfully' })
    );
  }),
  rest.put(`${baseUrl}/address/detail`, (req, res, ctx) => {
    // You can process or update the data as needed
    return res(
      ctx.status(200),
      ctx.json({ message: 'Address updated successfully' })
    );
  }),
  rest.delete(`${baseUrl}/address/detail`, (req, res, ctx) => {
    // You can process or delete the data as needed
    return res(
      ctx.status(200),
      ctx.json({ message: 'Address deleted successfully' })
    );
  }),
  // rest.get(`${baseUrl}/address/factories`, (req, res, ctx) => {
  //   return res(ctx.status(200), ctx.json(mockAddressFactoriesData));
  // }),
];
