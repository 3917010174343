import { cleanAndNormalizeString } from './cleanAndNormalizeString';
import { displayThresholdRange } from './displayThresholdRange';

interface KeyResult {
  name: string;
  value: string;
}

export const getAlarmResultText = (
  alarmName: string,
  keyResults?: KeyResult[]
) => {
  if (!keyResults) return '-';

  const normalizedAlarmName = cleanAndNormalizeString(alarmName);

  if (normalizedAlarmName.includes('chemical')) {
    const tvoc = keyResults.find((el) => el.name.includes('tv'));
    return `(tvoc) ${tvoc?.value}`;
  }

  if (normalizedAlarmName.includes('inspection')) {
    const minTempValue =
      keyResults.find(
        (result) =>
          cleanAndNormalizeString(result.name) ===
          cleanAndNormalizeString('Min Temperature')
      )?.value ?? '-';
    const maxTempValue =
      keyResults.find(
        (result) =>
          cleanAndNormalizeString(result.name) ===
          cleanAndNormalizeString('Max Temperature')
      )?.value ?? '-';
    return displayThresholdRange(minTempValue, maxTempValue);
  }

  if (normalizedAlarmName.includes('pincam')) {
    const gaugeValue = keyResults.find(
      (result) => result.name === 'guage_value'
    );
    const detectionRate = parseFloat(gaugeValue?.value ?? '0');
    return `${detectionRate.toFixed(2)}`;
  }

  if (normalizedAlarmName.includes('thresholder')) {
    const detectedValue = keyResults.find((result) =>
      result.name.toLowerCase().includes('detected')
    );
    return `${detectedValue?.value ?? '0'}`;
  }

  if (normalizedAlarmName.includes('door')) {
    const detectedValue = keyResults.find((result) =>
      result.name.toLowerCase().includes('cause')
    );
    return `${detectedValue?.value ?? '-'}`;
  }

  return '-';
};
