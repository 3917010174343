import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { deleteFactory } from '@/api/coreConfig';

interface DeleteFactoryParams {
  name: string;
  id: number;
  manager: string;
}

const useDeleteFactory = (
  options?: UseMutationOptions<
    DeleteFactoryParams,
    AxiosError,
    DeleteFactoryParams
  >
) => {
  const fetcher = async (variable: DeleteFactoryParams) => {
    const result = await deleteFactory(variable.id);
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useDeleteFactory;
