import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { getCookie } from '@utils/cookie';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';
axios.defaults.headers.common['X-CSRFToken'] = getCookie('csrftoken');

const baseURL =
  process.env.REACT_APP_ENV !== 'dev'
    ? `${window.location.origin}/back`
    : process.env.REACT_APP_BACKEND_ENDPOINT_URL;

const clientConfig: AxiosRequestConfig = {
  baseURL,
  withCredentials: true,
};

export const client: AxiosInstance = axios.create(clientConfig);
