import { Box, Button } from '@mui/material';
import { FormFileInput } from '../Common/FormFileInput';
import { Control } from 'react-hook-form';

interface SMTPList {
  address: string;
  name: string;
  id: string;
  password: string;
  port: number;
}

interface ScoutInfo {
  token: string;
}

interface SMSList {
  apiKey: string;
  senderPhoneNumber: number;
  agency: string;
  default: boolean;
  time: string;
}

interface OnboardingFormValue {
  scoutInfo: ScoutInfo;
  logoImage: File;
  smtpList: SMTPList;
  smsList: SMSList;
}

export interface InfoFormProps {
  control: Control<OnboardingFormValue>;
}

export const LogoImageUploadForm = ({ control }: InfoFormProps) => {
  return (
    <Box>
      <Button sx={{ m: 1 }} variant="contained" component="label">
        <FormFileInput
          name="logoImage"
          control={control}
          InputProps={{
            inputProps: { accept: 'image/*' },
            type: 'file',
            fullWidth: true,
          }}
        />
      </Button>
    </Box>
  );
};
