import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useScheduleContext } from '@/hooks/constate/ScheduleContext';

export const getReportListColumns = (): GridColDef[] => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { schedules } = useScheduleContext();

  const FAILURE_SUCCEED = ['success', 'failure'];

  const ROBOT_NAMES = useMemo(
    () =>
      schedules?.robots
        .filter((robot) => robot.nickname)
        .map((robot) => robot.nickname) || [],
    [schedules?.robots]
  );

  const MISSION_NAMES = useMemo(
    () => schedules?.mission.map((toGetNames) => toGetNames.name) || [],
    [schedules?.mission]
  );

  return [
    {
      field: 'missionName',
      type: 'singleSelect',
      valueOptions: MISSION_NAMES,
      headerName: t('missionName') || 'missionName',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      editable: false,
    },
    {
      field: 'startTime',
      type: 'string',
      headerName: t('startTime') || 'startTime',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      editable: false,
    },
    {
      field: 'executingRobot',
      type: 'singleSelect',
      valueOptions: ROBOT_NAMES,
      headerName: t('executingRobot') || 'executingRobot',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      editable: false,
    },
    {
      field: 'status',
      type: 'singleSelect',
      valueOptions: FAILURE_SUCCEED,
      headerName: `${t('success')}/${t('failure')}` || 'Success/Failure',
      headerAlign: 'center',
      align: 'center',
      width: 200,
      editable: false,
    },
    {
      field: 'openReport',
      headerName: t('openReport') || 'openReport',
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      width: 100,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate(`/report/${params.value}`)}
        >
          {t('open')}
        </Button>
      ),
      editable: false,
    },
    {
      field: 'openInNewWindow',
      headerName: t('openInNewWindow') || 'openInNewWindow',
      headerAlign: 'center',
      align: 'center',
      filterable: false,
      width: 100,
      renderCell: (params) => (
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            window.open(
              `/report/${params.value}`,
              '_blank',
              'width=1400, height=900'
            )
          }
        >
          {t('open')}
        </Button>
      ),
      editable: false,
    },
  ];
};
