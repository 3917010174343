import { CircularProgress, Paper, Typography } from '@mui/material';
import { DateRangePicker } from './DateRangePicker';
import useScheduleLogs from '@/hooks/remote/useSchedulesLogs';
import { Suspense, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const ScheduleLog = () => {
  const today = useMemo(() => {
    return new Date(
      Date.UTC(
        new Date().getFullYear(),
        new Date().getMonth(),
        new Date().getDate()
      )
    );
  }, []);
  const { t } = useTranslation();
  const [from, setFrom] = useState(today);
  const [to, setTo] = useState(today);

  const { data: scheduleLogs } = useScheduleLogs({
    from: from.toISOString(),
    end: to.toISOString(),
  });

  // Todo : 로그 부분만 스크롤 하고 싶은데, 전역 css에 먹혀서 안됌

  return (
    <Paper
      sx={{
        height: '100%',
        padding: 2,
      }}
    >
      <Typography variant="h4">
        {t('scheduleRegistration')}/{t('deletion')} {t('log')}
      </Typography>
      <DateRangePicker onStartDateChange={setFrom} onEndDateChange={setTo} />
      <Suspense fallback={<CircularProgress />}>
        {scheduleLogs?.data[0].map((log, index) => (
          <Typography
            key={index}
            sx={{
              marginTop: 1,
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
            }}
          >
            {log}
          </Typography>
        ))}
      </Suspense>
    </Paper>
  );
};
