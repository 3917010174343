import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { queryKey } from './querykey';
import { fetchLogo } from '@/api/coreConfig';

interface fetchLogoResponse {
  url: string;
}

const useConfigLogo = (
  variable = undefined,
  options?: UseQueryOptions<fetchLogoResponse, AxiosError>
) => {
  const fetcher = async () => {
    const result = await fetchLogo();
    return result.data;
  };

  return useQuery<fetchLogoResponse, AxiosError>(
    [queryKey.fetchLogo, variable],
    fetcher,
    options
  );
};

export default useConfigLogo;
