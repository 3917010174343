import useSchedules from '@/hooks/remote/useSchedules';
import FilterListIcon from '@mui/icons-material/FilterList';
import {
  Box,
  Button,
  Collapse,
  Drawer,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MultipleSelectCheckbox } from '../PatrolReport/MultipleSelectCheckbox';
import { useReportContext } from '@/hooks/constate/ReportContext';

interface filter {
  listPage: number;
  limit: number;
  MissionName: never[];
  endDate: string;
  startDate: string;
  Robot: never[];
  SuccessFailure: never[];
}

export const ReportListFilterDrawer = () => {
  const offset = 1000 * 60 * 60 * 9;
  const koreaNow = new Date(new Date().getTime() + offset);
  const { t } = useTranslation();
  const { filters, setFilters } = useReportContext();

  const [open, setOpen] = useState(false);
  const [settingStartDateTime, setSettingStartDateTime] = useState(
    koreaNow.toISOString().substring(0, 16)
  );

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const { data: scheduleResponse } = useSchedules(undefined, {
    retry: true,
  });

  const FAILURE_SUCCEED = [{ name: 'success' }, { name: 'failure' }];

  const robotName = scheduleResponse?.robots
    .filter((value) => value.nickname)
    .map((robot) => {
      return { ...robot, name: robot.nickname };
    });

  const updateFiltersWithDate = (
    date: string,
    filters: filter,
    field: 'startDate' | 'endDate'
  ) => {
    if (date !== '') {
      const apiDate = new Date(new Date(date).getTime() + offset).toISOString();
      setFilters({ ...filters, [field]: apiDate });
    }
  };

  const onStartDateChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {
      target: { value },
    } = e;
    setSettingStartDateTime(value);
    updateFiltersWithDate(value, filters, 'startDate');
  };

  return (
    <>
      <Button onClick={toggleDrawer}>
        <FilterListIcon />
        <Typography> {t('filter')}</Typography>
      </Button>
      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            position: 'relative',
            margin: 0,
            padding: 0,
          },
        }}
        variant="permanent"
        open={open}
      >
        <Stack direction="row">
          <Collapse in={open} timeout={400}>
            {scheduleResponse && (
              <Stack direction="row" sx={{ minWidth: 1042 }}>
                <MultipleSelectCheckbox
                  checkboxName="MissionName"
                  names={scheduleResponse.mission}
                  filters={filters}
                  setFilters={setFilters}
                />
                <Box
                  mt={1}
                  sx={{
                    justifyContent: 'space-between',
                    display: 'flex',
                  }}
                >
                  <TextField
                    id="datetime-start"
                    label={t('StartDate')}
                    type="datetime-local"
                    value={settingStartDateTime}
                    onChange={onStartDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Box>

                {robotName && (
                  <MultipleSelectCheckbox
                    checkboxName="Robot"
                    names={robotName}
                    filters={filters}
                    setFilters={setFilters}
                  />
                )}
                <MultipleSelectCheckbox
                  checkboxName="SuccessFailure"
                  names={FAILURE_SUCCEED}
                  filters={filters}
                  setFilters={setFilters}
                />
              </Stack>
            )}
          </Collapse>
        </Stack>
      </Drawer>
    </>
  );
};
