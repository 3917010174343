interface SMSInfoResponse {
  id: number;
  agency: string;
  api_key: string;
  sender_phone_number: string;
  default: boolean;
  is_need_config: boolean;
  setting_time: string;
}

export const mockSMSData: SMSInfoResponse = {
  id: 5,
  agency: 'SEJONG',
  api_key: '*',
  sender_phone_number: '028014545',
  default: true,
  is_need_config: false,
  setting_time: '12:21',
};
