import { fetchMissionList } from '@/api/missionSetting';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { queryKey } from './querykey';

interface MissionListResponse {
  mission_uuid: string;
  mission_name: string;
  source_mission_create_at: string;
  source_mission_modified_at: string;
  alias: string | null;
  create_at: string;
  modified_at: string;
  file: string | null;
  route_description: string | null;
  factory: string | null;
  detail_route: string | null;
}

const useMissionList = (
  variable: undefined,
  options?: UseQueryOptions<MissionListResponse[], AxiosError>
) => {
  const fetcher = async () => {
    const result = await fetchMissionList();
    return result.data;
  };

  return useQuery<MissionListResponse[], AxiosError>(
    [queryKey.fetchMissionList, variable],
    fetcher,
    options
  );
};

export default useMissionList;
