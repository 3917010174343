import { queryKey } from '@/hooks/remote/querykey';
import useDeleteAddress from '@/hooks/remote/useDeleteAddress';
import { Button } from '@mui/material';
import {
  GridFooterContainer,
  GridPagination,
  useGridApiContext,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { showToast } from '@/hooks/toast/toastUtils';

interface DeleteAddressParmas {
  email: string[];
}

export const CustomFooter = () => {
  const { mutateAsync: deleteAddress } = useDeleteAddress();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const handleDeleteAddress = async (params: DeleteAddressParmas) => {
    try {
      await deleteAddress(params, {
        onSuccess: () => {
          queryClient.invalidateQueries([queryKey.fetchAddress]);
          showToast(t, 'success', '삭제에 성공했습니다.');
        },
      });
    } catch (e) {
      showToast(t, 'error', `삭제 실패`);
    }
  };

  const apiRef = useGridApiContext(); // Access the grid API context
  const selectedRows = Array.from(apiRef.current.getSelectedRows().values());

  const handleDeleteClick = () => {
    const selectedEmails: string[] = selectedRows.map((row) => row.email); // Extract the email values
    if (selectedRows.length > 0) {
      handleDeleteAddress({ email: selectedEmails });
    }
  };

  return (
    <GridFooterContainer sx={{ justifyContent: 'space-between' }}>
      <Button onClick={handleDeleteClick}>
        <DeleteIcon />
        {t('delete')}
      </Button>
      <GridPagination />
    </GridFooterContainer>
  );
};
