import { Paper, Stack } from '@mui/material';
import { AddressFormDialogs } from '@/components/Address/AddressFormDialogs';
// import { AlarmLevel } from '@/components/Address/AlarmLevel';
import { useTheme } from '@mui/material/styles';
import { MainContainer } from '@/components/myStyle/MainContainer';
import { AddressTable } from '@/components/Address/AddressTable';
import { Suspense } from 'react';
import { LoadingPage } from './LoadingPage';
import { AddressProvider } from '@/hooks/constate/AddressContext';

export const AddressPage = () => {
  return (
    <Suspense
      fallback={<LoadingPage loadingText={'연락처 페이지 로딩 중..'} />}
    >
      <AddressProvider>
        <Address />
      </AddressProvider>
    </Suspense>
  );
};

const Address = () => {
  const theme = useTheme();

  return (
    <MainContainer theme={theme}>
      <Paper>
        <Stack
          m={2}
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
          }}
        >
          <AddressFormDialogs />
        </Stack>
        <Suspense
          fallback={<LoadingPage loadingText={'연락처 가져오는 중 ..'} />}
        >
          <AddressTable />
        </Suspense>
      </Paper>
    </MainContainer>
  );
};
