import { OnboardingStep } from '@/components/Onboarding/OnboardingStep';
import { Container } from '@mui/material';
import { Suspense } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import { LoadingPage } from './LoadingPage';

const CustomContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
}));

export const OnboardingPage = () => {
  const methods = useForm();

  return (
    <Suspense fallback={<LoadingPage loadingText={'initPage 로딩 중..'} />}>
      <FormProvider {...methods}>
        <Onboarding />
      </FormProvider>
    </Suspense>
  );
};

const Onboarding = () => {
  return (
    <CustomContainer>
      <OnboardingStep />
    </CustomContainer>
  );
};
