import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import mc from '@utils/mc';
import notFoundimage from '@assets/images/404image.png';
import { getAlarmResultText } from '@/utils/getAlarmResultText';
import { getAlarmInspectionStandards } from '@/utils/getAlarmInspectionStandards';
import { useReportContext } from '@/hooks/constate/ReportContext';
import { useTranslation } from 'react-i18next';
import { showToast } from '@/hooks/toast/toastUtils';
import { cleanIncludes } from '@/utils/cleanIncludes';
interface AlarmReportProps {
  alarmListElement: Action;
  index: number;
}
interface Action {
  action_name: string;
  data_captures: DataCapture[];
}
interface DataCapture {
  time: string;
  dataUrl: string;
  key_result: KeyResult[];
  channelName: string;
  full_dataUrl: string;
}
interface KeyResult {
  name: string;
  value: string;
}

export const PRE_SIGNED_URL_EXPIRATION_TIME = 24 * 60 * 60;

export const AlarmReport = ({ alarmListElement }: AlarmReportProps) => {
  const [images, setImages] = useState<string[]>([]);
  const { setIsLoaded } = useReportContext();
  const { t } = useTranslation();

  const elActionName = useMemo(() => {
    return alarmListElement?.action_name;
  }, [alarmListElement]);

  const actionName = useMemo(() => {
    return cleanIncludes(elActionName, 'human')
      ? `${elActionName.split('_')[0]}
                ${elActionName.split('_')[1]}`
      : elActionName.split('-')[0];
  }, [alarmListElement]);

  const actionLine = useMemo(() => {
    if (cleanIncludes(elActionName, 'thresholder')) return '-';

    if (cleanIncludes(elActionName, 'door'))
      return (
        alarmListElement?.data_captures[0].key_result.find((result) =>
          result.name.toLocaleLowerCase().includes('ssid')
        )?.value ?? '-'
      );

    return elActionName.split('-')[2];
  }, [alarmListElement]);

  const actionFactory = useMemo(() => {
    if (cleanIncludes(elActionName, 'thresholder')) return '-';

    if (cleanIncludes(elActionName, 'door')) return '-';

    return cleanIncludes(elActionName, 'human')
      ? '-'
      : elActionName.split('-')[1];
  }, [alarmListElement]);

  const actionTime = useMemo(() => {
    return alarmListElement?.data_captures[0]?.time.slice(5);
  }, [alarmListElement]);

  const sortedAlarmListElement = alarmListElement?.data_captures?.sort(
    (a, b) => b.key_result.length - a.key_result.length
  );

  const actionResult = useMemo(() => {
    return getAlarmResultText(
      elActionName?.split('-')[0].trimEnd() || '',
      sortedAlarmListElement[0]?.key_result
    );
  }, [alarmListElement]);

  const { maxThreshold, minThreshold } = useMemo(() => {
    return getAlarmInspectionStandards(
      actionName,
      sortedAlarmListElement[0]?.key_result
    );
  }, [alarmListElement]);

  const getPreSignedUrl = async (img: string) => {
    try {
      const presignedUrl = await mc.presignedUrl(
        'GET',
        'spot-bucket',
        img,
        PRE_SIGNED_URL_EXPIRATION_TIME
      );
      setImages((prev) => [...prev, presignedUrl]);
    } catch (err) {
      showToast(t, 'error', `presigned err ${err}`);
    }
  };

  const handleErrorImg = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    e.currentTarget.src = notFoundimage;
    e.currentTarget.onerror = null;
  };

  const filterRawImageUrl = async (
    arg: Action,
    actionName: string,
    imageArray: string[]
  ) => {
    const isMatchingAction = cleanIncludes(arg.action_name, actionName);

    if (isMatchingAction) {
      const camData = arg.data_captures || [];

      for (const image of imageArray) {
        const matchedData = camData.find(
          (data) =>
            cleanIncludes(data.channelName, image) &&
            !cleanIncludes(data.channelName, 'raw')
        );

        if (matchedData) {
          await getPreSignedUrl(matchedData.dataUrl.slice(1));
        }
      }
    }
  };

  useEffect(() => {
    if (!alarmListElement) {
      return;
    }

    alarmListElement.data_captures?.sort((a, b) => {
      if (a.channelName < b.channelName) return 1;
      if (a.channelName > b.channelName) return -1;
      return 0;
    });

    filterRawImageUrl(alarmListElement, 'Spot CAM', [
      'spot-cam-ptz',
      'spot-cam-ir',
    ]);
    filterRawImageUrl(alarmListElement, 'Human', [
      'location',
      'detected-spot-cam-ir',
    ]);
    filterRawImageUrl(alarmListElement, 'Inspection', [
      'spot-cam-ptz',
      'spot-cam-ir',
      'isotherm_image',
    ]);
    filterRawImageUrl(alarmListElement, 'thresholder', [
      'location',
      'spot-cam-ir',
    ]);
    filterRawImageUrl(alarmListElement, 'door', ['pano']);

    filterRawImageUrl(alarmListElement, 'visualization', ['predict', 'sensor']);

    if (cleanIncludes(elActionName, 'pincam')) {
      const camData = sortedAlarmListElement?.filter(
        (data) => data.channelName === 'pincam'
      );
      camData?.forEach((data) => getPreSignedUrl(data.dataUrl.slice(1)));
    }

    return () => {
      setImages([]);
    };
  }, [alarmListElement]);

  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: 4,
        padding: 2,
        border: 1,
        borderColor: 'grey.400',
      }}
    >
      <TableContainer>
        <Table
          sx={{
            border: 1,
            borderColor: 'grey.400',
            width: '100%',
          }}
        >
          <TableHead
            sx={{
              width: '100%',
            }}
          >
            <TableRow>
              <TableCell colSpan={2} sx={{ fontWeight: 'bold', flexGrow: 0 }}>
                {t('line')}
              </TableCell>
              <TableCell sx={{ flexGrow: 0 }}>{actionLine}</TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  width: 300,
                  textAlign: 'center',
                  flexGrow: 1,
                }}
              >
                {t('picture')}1
              </TableCell>
              <TableCell
                sx={{
                  fontWeight: 'bold',
                  width: 300,
                  textAlign: 'center',
                  flexGrow: 1,
                }}
              >
                {t('picture')}2
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                {t('category')}
              </TableCell>
              <TableCell>
                {cleanIncludes(actionName, 'thresholder')
                  ? '고온감지'
                  : actionName}
              </TableCell>
              {[...Array(2)].map((_, index) => {
                const url = images[index] || '';
                const hasImage = Boolean(url);
                return (
                  <TableCell
                    sx={{
                      border: '1',
                      borderColor: 'grey.400',
                      width: 300,
                      height: 300,
                    }}
                    key={index}
                    rowSpan={7}
                  >
                    <Box sx={{ width: 300, height: 300 }}>
                      {hasImage && (
                        <img
                          src={url}
                          referrerPolicy="no-referrer"
                          onError={handleErrorImg}
                          alt="이미지가 없습니다."
                          style={{ width: 300, height: 300 }}
                          onLoad={() => setIsLoaded(true)}
                        />
                      )}{' '}
                    </Box>
                  </TableCell>
                );
              })}
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                {t('factory')}
              </TableCell>
              <TableCell>{actionFactory}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                {t('insepctionValue')}
              </TableCell>
              <TableCell>{actionResult}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell rowSpan={2} sx={{ fontWeight: 'bold' }}>
                {t('specification')}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('maximum')}</TableCell>
              <TableCell>{maxThreshold}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('minimum')}</TableCell>
              <TableCell>{minThreshold}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                {t('date')}
              </TableCell>
              <TableCell>{actionTime}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                {t('decision')}
              </TableCell>
              <TableCell>check</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
