export const mockReportData = [
  {
    uuid: 'fa12440f-4aa9-471a-b312-4076d0e03ba7',
    mission: [
      {
        name: '230917_AG1_A_ROUTE',
        status: 'SUCCESS',
        start_time: '2024년 02월 13일 19시 53분 47초',
        end_time: '2024년 02월 13일 20시 28분 31초',
        map_url: null,
        factory_alias: null,
        mission_alias: null,
        detail_route: null,
        route_description: null,
        time_spent: {
          hours: 0,
          minutes: 34,
          seconds: 44,
        },
      },
    ],
    robot: [
      {
        info: {
          name: 'SPOT-AG1',
          ip: '223.171.142.145',
        },
        subscribers: [],
      },
    ],
    action: [
      {
        action_name: 'developer log',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 28분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/developer log/developer log developer log developer log.csv',
            key_result: [
              {
                name: 'travel  distance',
                value: '1233.947624360182',
              },
              {
                name: 'battery consumption',
                value: '50',
              },
              {
                name: 'latency count',
                value: '696',
              },
              {
                name: 'average latency',
                value: '77.5799683908046',
              },
              {
                name: 'maximum latency',
                value: '999',
              },
            ],
            channelName: 'developer log',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/developer log/developer log developer log developer log.csv',
          },
        ],
      },
      {
        action_name: 'developer log',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 28분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/developer log/developer log communication_stat comm.jpg',
            key_result: [
              {
                name: 'latency count',
                value: '696',
              },
              {
                name: 'average latency',
                value: '77.5799683908046',
              },
              {
                name: 'maximum latency',
                value: '999',
              },
            ],
            channelName: 'communication_stat',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/developer log/developer log communication_stat comm.jpg',
          },
        ],
      },
      {
        action_name: 'developer log',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 28분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/developer log/developer log battery level.jpg',
            key_result: [
              {
                name: 'battery consumption',
                value: '50',
              },
            ],
            channelName: 'battery',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/developer log/developer log battery level.jpg',
          },
        ],
      },
      {
        action_name: 'SpotAI_detector_antic-skunk_0',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 18분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/SpotAI_detector_antic-skunk_0/SpotAI_detector_antic-skunk_0 spot-cam-ir antic-skunk_0.jpg',
            key_result: [],
            channelName: 'spot-cam-ir',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/SpotAI_detector_antic-skunk_0/SpotAI_detector_antic-skunk_0 spot-cam-ir antic-skunk_0.jpg',
          },
          {
            time: '2024년 02월 13일 20시 18분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/SpotAI_detector_antic-skunk_0/SpotAI_detector_antic-skunk_0 detected-spot-cam-ir antic-skunk_0.jpg',
            key_result: [
              {
                name: 'waypoint_id',
                value: 'antic-skunk_0',
              },
              {
                name: 'object name_0',
                value: 'panel',
              },
              {
                name: 'confidence_0',
                value: '0.5806476473808289',
              },
            ],
            channelName: 'detected-spot-cam-ir',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/SpotAI_detector_antic-skunk_0/SpotAI_detector_antic-skunk_0 detected-spot-cam-ir antic-skunk_0.jpg',
          },
          {
            time: '2024년 02월 13일 20시 18분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/SpotAI_detector_antic-skunk_0/SpotAI_detector_antic-skunk_0 location antic-skunk_0.jpg',
            key_result: [],
            channelName: 'location',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/SpotAI_detector_antic-skunk_0/SpotAI_detector_antic-skunk_0 location antic-skunk_0.jpg',
          },
        ],
      },
      {
        action_name: 'Spot Cam - Painting2 - 7',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 18분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Spot Cam - Painting2 - 7/Spot Cam - Painting2 - 7 spot-cam-ptz.jpg',
            key_result: [],
            channelName: 'spot-cam-ptz',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Spot Cam - Painting2 - 7/Spot Cam - Painting2 - 7 spot-cam-ptz.jpg',
          },
        ],
      },
      {
        action_name: 'Spot Cam - Painting2 - 6',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 17분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Spot Cam - Painting2 - 6/Spot Cam - Painting2 - 6 spot-cam-ptz.jpg',
            key_result: [],
            channelName: 'spot-cam-ptz',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Spot Cam - Painting2 - 6/Spot Cam - Painting2 - 6 spot-cam-ptz.jpg',
          },
        ],
      },
      {
        action_name: 'Spot Cam - Painting2 - 5',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 17분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Spot Cam - Painting2 - 5/Spot Cam - Painting2 - 5 spot-cam-ptz.jpg',
            key_result: [],
            channelName: 'spot-cam-ptz',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Spot Cam - Painting2 - 5/Spot Cam - Painting2 - 5 spot-cam-ptz.jpg',
          },
        ],
      },
      {
        action_name: 'Spot Cam - Painting2 - 4',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 16분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Spot Cam - Painting2 - 4/Spot Cam - Painting2 - 4 spot-cam-ptz.jpg',
            key_result: [],
            channelName: 'spot-cam-ptz',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Spot Cam - Painting2 - 4/Spot Cam - Painting2 - 4 spot-cam-ptz.jpg',
          },
        ],
      },
      {
        action_name: 'Spot Cam - Painting2 - 3',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 15분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Spot Cam - Painting2 - 3/Spot Cam - Painting2 - 3 spot-cam-ptz.jpg',
            key_result: [],
            channelName: 'spot-cam-ptz',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Spot Cam - Painting2 - 3/Spot Cam - Painting2 - 3 spot-cam-ptz.jpg',
          },
        ],
      },
      {
        action_name: 'Spot Cam - Painting2 - 2',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 14분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Spot Cam - Painting2 - 2/Spot Cam - Painting2 - 2 spot-cam-ptz.jpg',
            key_result: [],
            channelName: 'spot-cam-ptz',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Spot Cam - Painting2 - 2/Spot Cam - Painting2 - 2 spot-cam-ptz.jpg',
          },
        ],
      },
      {
        action_name: 'Spot Cam - Painting2 - 1',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 14분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Spot Cam - Painting2 - 1/Spot Cam - Painting2 - 1 spot-cam-ptz.jpg',
            key_result: [],
            channelName: 'spot-cam-ptz',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Spot Cam - Painting2 - 1/Spot Cam - Painting2 - 1 spot-cam-ptz.jpg',
          },
        ],
      },
      {
        action_name: 'PINCAM - Paint - Panel - 1',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 08분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/PINCAM - Paint - Panel - 1/PINCAM - Paint - Panel - 1 pincam daq_helper_channel.jpg',
            key_result: [
              {
                name: 'min_threshold',
                value: '0',
              },
              {
                name: 'max_threshold',
                value: '100',
              },
              {
                name: 'error_code',
                value: 'E000',
              },
              {
                name: 'time',
                value: '2024Y 02M 13D 20h 14m 15s',
              },
              {
                name: 'guage_value',
                value: '29.3',
              },
              {
                name: 'alert_level',
                value: '1',
              },
            ],
            channelName: 'pincam',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/PINCAM - Paint - Panel - 1/PINCAM - Paint - Panel - 1 pincam daq_helper_channel.jpg',
          },
        ],
      },
      {
        action_name: 'Door-AreaCallback-[T20:03:11]',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 03분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Door-AreaCallback-[T20-03-11]/Door-AreaCallback-[T20-03-11] spot-cam-pano antsy-moray-ewH40veTQkvi54cetlRvBw==.jpg',
            key_result: [
              {
                name: 'door_state_before_area_callback',
                value: 'Status.CLOSED',
              },
              {
                name: 'cause',
                value: 'Failed to close door, door status is Status.OPENED',
              },
              {
                name: 'ssid',
                value: 'DOOR-S1-4',
              },
              {
                name: 'door_state_after_area_callback',
                value: 'Status.OPENED',
              },
              {
                name: 'severity',
                value: 'warning',
              },
            ],
            channelName: 'spot-cam-pano',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Door-AreaCallback-[T20-03-11]/Door-AreaCallback-[T20-03-11] spot-cam-pano antsy-moray-ewH40veTQkvi54cetlRvBw==.jpg',
          },
        ],
      },
      {
        action_name: 'PINCAM - Assy - HB - 3',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 00분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/PINCAM - Assy - HB - 3/PINCAM - Assy - HB - 3 pincam daq_helper_channel.jpg',
            key_result: [
              {
                name: 'min_threshold',
                value: '0',
              },
              {
                name: 'max_threshold',
                value: '100',
              },
              {
                name: 'error_code',
                value: 'E000',
              },
              {
                name: 'time',
                value: '2024Y 02M 13D 20h 07m 42s',
              },
              {
                name: 'guage_value',
                value: '20',
              },
              {
                name: 'alert_level',
                value: '1',
              },
            ],
            channelName: 'pincam',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/PINCAM - Assy - HB - 3/PINCAM - Assy - HB - 3 pincam daq_helper_channel.jpg',
          },
        ],
      },
      {
        action_name: 'PINCAM - Assy - HB - 2',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 00분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/PINCAM - Assy - HB - 2/PINCAM - Assy - HB - 2 pincam daq_helper_channel.jpg',
            key_result: [
              {
                name: 'min_threshold',
                value: '0',
              },
              {
                name: 'max_threshold',
                value: '100',
              },
              {
                name: 'error_code',
                value: 'E000',
              },
              {
                name: 'time',
                value: '2024Y 02M 13D 19h 32m 59s',
              },
              {
                name: 'guage_value',
                value: '19',
              },
              {
                name: 'alert_level',
                value: '1',
              },
            ],
            channelName: 'pincam',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/PINCAM - Assy - HB - 2/PINCAM - Assy - HB - 2 pincam daq_helper_channel.jpg',
          },
        ],
      },
      {
        action_name: 'PINCAM - Assy - HB - 1',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 20시 00분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/PINCAM - Assy - HB - 1/PINCAM - Assy - HB - 1 pincam daq_helper_channel.jpg',
            key_result: [
              {
                name: 'min_threshold',
                value: '0',
              },
              {
                name: 'max_threshold',
                value: '100',
              },
              {
                name: 'error_code',
                value: 'E000',
              },
              {
                name: 'time',
                value: '2024Y 02M 13D 20h 07m 44s',
              },
              {
                name: 'guage_value',
                value: 'OFF',
              },
              {
                name: 'alert_level',
                value: '0',
              },
            ],
            channelName: 'pincam',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/PINCAM - Assy - HB - 1/PINCAM - Assy - HB - 1 pincam daq_helper_channel.jpg',
          },
        ],
      },
      {
        action_name: 'Thermal Inspection - Assy - HB - 1',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 19시 59분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Thermal Inspection - Assy - HB - 1/Thermal Inspection - Assy - HB - 1 spot-cam-ptz.jpg',
            key_result: [],
            channelName: 'spot-cam-ptz',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Thermal Inspection - Assy - HB - 1/Thermal Inspection - Assy - HB - 1 spot-cam-ptz.jpg',
          },
          {
            time: '2024년 02월 13일 19시 59분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Thermal Inspection - Assy - HB - 1/Thermal Inspection - Assy - HB - 1 thermal-inspection_advanced-anomaly_isotherm_image.jpg',
            key_result: [
              {
                name: 'Max Temperature',
                value: '14.45 °C',
              },
              {
                name: 'Min Temperature',
                value: '-6.75 °C',
              },
              {
                name: 'Max Alarm Threshold',
                value: '100.05 °C',
              },
            ],
            channelName: 'thermal-inspection_advanced-anomaly_isotherm_image',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Thermal Inspection - Assy - HB - 1/Thermal Inspection - Assy - HB - 1 thermal-inspection_advanced-anomaly_isotherm_image.jpg',
          },
          {
            time: '2024년 02월 13일 19시 59분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Thermal Inspection - Assy - HB - 1/Thermal Inspection - Assy - HB - 1 spot-cam-ir-raw.raw',
            key_result: [],
            channelName: 'spot-cam-ir-raw',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Thermal Inspection - Assy - HB - 1/Thermal Inspection - Assy - HB - 1 spot-cam-ir-raw.raw',
          },
        ],
      },
      {
        action_name: 'Human_detector_orange-hyla_0',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 19시 58분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Human_detector_orange-hyla_0/Human_detector_orange-hyla_0 spot-cam-ir orange-hyla_0.jpg',
            key_result: [],
            channelName: 'spot-cam-ir',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Human_detector_orange-hyla_0/Human_detector_orange-hyla_0 spot-cam-ir orange-hyla_0.jpg',
          },
          {
            time: '2024년 02월 13일 19시 58분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Human_detector_orange-hyla_0/Human_detector_orange-hyla_0 location orange-hyla_0.jpg',
            key_result: [],
            channelName: 'location',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Human_detector_orange-hyla_0/Human_detector_orange-hyla_0 location orange-hyla_0.jpg',
          },
          {
            time: '2024년 02월 13일 19시 58분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Human_detector_orange-hyla_0/Human_detector_orange-hyla_0 detected-spot-cam-ir orange-hyla_0.jpg',
            key_result: [
              {
                name: 'waypoint_id',
                value: 'orange-hyla_0',
              },
              {
                name: 'object name_0',
                value: 'standing',
              },
              {
                name: 'confidence_0',
                value: '0.6237437725067139',
              },
            ],
            channelName: 'detected-spot-cam-ir',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Human_detector_orange-hyla_0/Human_detector_orange-hyla_0 detected-spot-cam-ir orange-hyla_0.jpg',
          },
        ],
      },
      {
        action_name: 'Human_detector_unawed-beef_0',
        alert_data: null,
        inspection_standard: null,
        data_captures: [
          {
            time: '2024년 02월 13일 19시 58분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Human_detector_unawed-beef_0/Human_detector_unawed-beef_0 detected-spot-cam-ir unawed-beef_0.jpg',
            key_result: [
              {
                name: 'waypoint_id',
                value: 'unawed-beef_0',
              },
              {
                name: 'object name_0',
                value: 'standing',
              },
              {
                name: 'confidence_0',
                value: '0.5190441608428955',
              },
            ],
            channelName: 'detected-spot-cam-ir',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Human_detector_unawed-beef_0/Human_detector_unawed-beef_0 detected-spot-cam-ir unawed-beef_0.jpg',
          },
          {
            time: '2024년 02월 13일 19시 58분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Human_detector_unawed-beef_0/Human_detector_unawed-beef_0 spot-cam-ir unawed-beef_0.jpg',
            key_result: [],
            channelName: 'spot-cam-ir',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Human_detector_unawed-beef_0/Human_detector_unawed-beef_0 spot-cam-ir unawed-beef_0.jpg',
          },
          {
            time: '2024년 02월 13일 19시 58분',
            dataUrl:
              '/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Human_detector_unawed-beef_0/Human_detector_unawed-beef_0 location unawed-beef_0.jpg',
            key_result: [],
            channelName: 'location',
            full_dataUrl:
              'https://127.0.0.1/daq/download/spot-BD-23350003/2024-02-13T105347Z_230917_AG1_A_ROUTE/Human_detector_unawed-beef_0/Human_detector_unawed-beef_0 location unawed-beef_0.jpg',
          },
        ],
      },
      {
        action_name: 'Groupname - 1',
        alert_data: null,
        inspection_standard: null,
        data_captures: [],
      },
    ],
  },
];
