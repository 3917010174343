import { useState } from 'react';
import constate from 'constate';

const useNavbarData = () => {
  const [selectedKey, setSelectedKey] = useState<string>('');
  const [open, setOpen] = useState(true);

  return {
    selectedKey,
    setSelectedKey,
    open,
    setOpen,
  };
};

export const [NavbarProvider, useNavbarContext] = constate(useNavbarData);
