import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { ListItemButton, ListItemText, ListItemIcon } from '@mui/material';
import { useHeaderContext } from '@constate/HeaderContext';
import { styled } from '@mui/material/styles';
import { useReportContext } from '@constate/ReportContext';
import { useTranslation } from 'react-i18next';
import { useNavbarContext } from '@/hooks/constate/NavbarContext';

export const StyledNavItem = styled(ListItemButton)<{
  component?: React.ElementType;
  onClick: () => void;
  to: string;
  disabled: boolean;
  activeClassName?: string;
  open: boolean;
}>(({ theme, open }) => ({
  height: 48,
  position: 'relative',
  marginLeft: open ? '0.25rem' : '0px',
  padding: open ? 0 : 2,
  color: theme.palette.text.secondary,
  borderRadius: '12px',
  '&.Mui-selected': {
    color: 'text.primary',
    bgcolor: 'action.selected',
    fontWeight: 'fontWeightBold',
  },
}));

export const StyledNavItemIcon = styled(ListItemIcon)({
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface ListItem {
  href: string;
  icon: React.ReactElement;
  title: string;
  disabled?: boolean;
}

interface ListItemProps {
  item: ListItem;
  itemKey: string;
}

const ListItemLink = ({ item, itemKey }: ListItemProps) => {
  const { selectedKey, setSelectedKey } = useNavbarContext();
  const { icon, title, disabled } = item;
  const { uuid } = useReportContext();
  const { t } = useTranslation();
  const location = useLocation();

  const finalHref = item.href.includes(':uuid')
    ? item.href.replace(':uuid', uuid || '')
    : item.href;

  const { setpageName, drawOpen } = useHeaderContext();

  const handleClick = () => {
    setpageName(title);
  };

  const pathToKeyMap: Record<string, string> = {
    '': 'main-0',
    report: 'main-1',
    list: 'main-2',
    msetting: 'custom-0',
    address: 'custom-1',
    systemset: 'custom-2',
  };

  useEffect(() => {
    const pathChecker = location.pathname.split('/')[1];
    const selectedKey = pathToKeyMap[pathChecker];
    setSelectedKey(selectedKey);
  }, [location.pathname]);

  return (
    <StyledNavItem
      disabled={disabled || false}
      onClick={handleClick}
      component={RouterLink}
      to={finalHref}
      selected={selectedKey === itemKey}
      open={drawOpen}
    >
      {<StyledNavItemIcon>{icon && icon}</StyledNavItemIcon>}
      {drawOpen && <ListItemText primary={t(title)} />}
    </StyledNavItem>
  );
};

ListItemLink.defaultProps = {
  disabled: false,
};

export default ListItemLink;
