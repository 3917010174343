import { useReportContext } from '@/hooks/constate/ReportContext';
import useCreatePdf from '@/hooks/remote/useCreatePdf';
import { generateReportPageToPdf } from '@/utils/generateReportPageToPdf';
import { getToday } from '@/utils/getToday';
import { LoadingButton } from '@mui/lab';
import jsPDF from 'jspdf';
import { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { useParams } from 'react-router';
import { showToast } from '@/hooks/toast/toastUtils';
import { useTranslation } from 'react-i18next';

interface PdfButtonProps {
  version: string;
}

const CustomLoadingButton = styled(LoadingButton)`
  background-color: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.primary.contrastText};
  padding: 10px;
  text-transform: none;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.dark};
  }
`;

export const PdfButton = ({ version }: PdfButtonProps) => {
  const [isLoadingGeneratePdf, setIsLoadingGeneratePdf] = useState(false);
  const { mutateAsync: createPdf } = useCreatePdf();
  const { report, patrolRef, alarmRefs, isLoaded } = useReportContext();
  const { type } = useParams();
  const { t } = useTranslation();
  const startDate = report?.mission[0]?.start_time.split('.')[0] ?? '';

  useEffect(() => {
    if (type === 'selenium' && isLoaded) {
      exportPdf('server');
    }
  }, [type, isLoaded]);

  const savePdfLocal = (pdf: jsPDF) => {
    pdf.save('Patrolreport' + getToday() + '.pdf');
  };

  const savePdfRemote = async (pdf: jsPDF, uuid: string) => {
    try {
      await createPdf({
        // @ts-ignore
        file: pdf.output('blob', { filename: 'Patrolreport.pdf' }),
        uuid,
      });
    } catch (e) {
      showToast(t, 'error', `저장실패 ${e}`);
    }
  };

  const exportPdf = async (saveTo: string) => {
    setIsLoadingGeneratePdf(true);
    try {
      const pdf = await generateReportPageToPdf({
        patrolElements: patrolRef.current,
        alarmElements: alarmRefs,
        startDate: startDate || '',
      });
      if (!pdf) return;
      if (saveTo === 'server' && report?.uuid) {
        await savePdfRemote(pdf, report?.uuid);
      } else {
        savePdfLocal(pdf);
        showToast(t, 'success', 'Successfully saved the PDF file');
      }
    } catch (e) {
      showToast(t, 'error');
    }

    setIsLoadingGeneratePdf(false);
  };

  return (
    <CustomLoadingButton
      variant="outlined"
      size="small"
      loading={isLoadingGeneratePdf}
      sx={{ width: 130, height: 40, margin: '0.625rem' }}
      onClick={() => {
        exportPdf(version);
      }}
      data-html2canvas-ignore="true"
      color="inherit"
    >
      {t('pdfDownload')}
    </CustomLoadingButton>
  );
};
