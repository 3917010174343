import { Box, Paper } from '@mui/material';
import ReportListTable from '@/components/ReportList/ReportListTable';
import { LoadingPage } from './LoadingPage';
import { ReportProvider } from '@/hooks/constate/ReportContext';
import { ScheduleProvider } from '@/hooks/constate/ScheduleContext';
import { MainContainer } from '@/components/myStyle/MainContainer';
import { useTheme } from '@mui/material/styles';
import { Suspense } from 'react';

export const ReportListPage = () => {
  return (
    <Suspense
      fallback={<LoadingPage loadingText={'레포트 리스트 로딩 중..'} />}
    >
      <ScheduleProvider>
        <ReportProvider>
          <ReportList />
        </ReportProvider>
      </ScheduleProvider>
    </Suspense>
  );
};

const ReportList = () => {
  const theme = useTheme();

  return (
    <ReportProvider>
      <MainContainer theme={theme}>
        <Box>
          <Paper sx={{ display: 'flex', flexDirection: 'column', mb: '2' }}>
            <ReportListTable />
          </Paper>
        </Box>
      </MainContainer>
    </ReportProvider>
  );
};
