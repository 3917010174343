import { client } from '@api/client';

interface Report {
  uuid: string;
  url: string;
  mission: Mission[];
  robot: Robot[];
  created_at: string;
}

interface Mission {
  name: string;
  status: string;
  start_time: string;
  end_time: string;
  time_spent: TimeSpent;
  mission_route: string;
  map_url: string;
}
interface Robot {
  info: RobotInfo;
  name: string;
  subscribers: unknown[];
}

interface RobotInfo {
  name: string;
  ip: string;
}
interface TimeSpent {
  hours: number;
  minutes: number;
  seconds: number;
}

export const createPdf = (createPdfParams: CreatePdfParams) => {
  const formData = new FormData();
  formData.append('file', createPdfParams.file);
  formData.append('uuid', createPdfParams.uuid);

  return client.post(`/report/post_pdf`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const fetchReports = ({
  listPage = 1,
  limit = 10,
  MissionName = [],
  endDate = '',
  startDate = '',
  Robot = [],
  SuccessFailure = [],
}: ReportsParams) => {
  const params = new URLSearchParams({
    page: listPage.toString(),
    limit: limit.toString(),
    'mission-name': MissionName.join(','),
    'end-date': endDate,
    'start-date': startDate,
    robot: Robot.join(','),
    'mission-status': SuccessFailure.join(','),
  });

  return client.get<ReportsResponse>(`/report/list?${params.toString()}`);
};

export const fetchReportDetail = (params: ReportDetailsParams) => {
  return client.get(`/report/detail/${params.uuid}`);
};

export const deleteReport = (uuid: string[]) => {
  return client.delete(`/report/`, { data: { uuid } });
};

interface CreatePdfParams {
  file: Blob;
  uuid: string;
}
interface ReportsParams {
  listPage: number;
  limit: number;
  MissionName?: string[];
  endDate?: string;
  startDate?: string;
  Robot?: string[];
  SuccessFailure?: string[];
}
interface ReportsResponse {
  reports: Report[];
  pageCnt: number;
  curPage: number;
}
interface ReportDetailsParams {
  uuid: string;
}
