import { Box, Theme } from '@mui/material';
import { ReactNode } from 'react';

interface MainContainerProps {
  theme: Theme;
  children: ReactNode;
}

export const MainContainer = ({ theme, children }: MainContainerProps) => (
  <Box
    sx={{
      width: '100%', // for xs, sm screens
      [theme.breakpoints.up('lg')]: {
        width: '1080px', // for md and lg screens
      },
      margin: 'auto',
      borderRadius: '12px',
    }}
  >
    {children}
  </Box>
);
