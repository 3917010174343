import { Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReportContext } from '@/hooks/constate/ReportContext';
import notFoundimage from '@assets/images/404image.png';

export const MissionMap = () => {
  const { t } = useTranslation();
  const { report } = useReportContext();

  return (
    <Grid item xs={12} md={6}>
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 4,
          padding: 2,
          border: 1,
          borderColor: 'grey.400', // 이 부분에 회색 테두리 색상을 설정하세요.
        }}
      >
        <Typography variant="h5" sx={{ textAlign: 'center' }} gutterBottom>
          {t('missionMap')}
        </Typography>
        <img
          src={report?.mission[0]?.map_url || notFoundimage}
          alt="Mission Map"
          style={{ width: '100%', maxHeight: '400px' }}
        />
      </Box>
    </Grid>
  );
};
