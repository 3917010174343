import { rest } from 'msw';

const baseUrl = process.env.REACT_APP_BACKEND_ENDPOINT_URL;

export const scheduleLogHandlers = [
  rest.get(`${baseUrl}/schedule/log`, (req, res, ctx) => {
    // const from = req.url.searchParams.get('from');
    // const end = req.url.searchParams.get('end');
    // You can customize this fake data as needed
    const fakeData: [string, string][] = [
      ['2023-04-11T10:00:00', 'Mission 1 created'],
      ['2023-04-11T12:00:00', 'Mission 2 created'],
      ['2023-04-11T14:00:00', 'Mission 1 updated'],
      ['2023-04-11T16:00:00', 'Mission 2 deleted'],
    ];

    // Filter the logs based on the provided 'from' and 'end' dates
    // const filteredData = fakeData.filter(
    //   (log) =>
    //     new Date(log[0]) >= new Date(from as string) &&
    //     new Date(log[0]) <= new Date(end as string)
    // );

    return res(ctx.json([fakeData]));
  }),
];
