// src/mocks/handlers.js

import { addressHandlers } from './api/address';
import { coreConfigHandlers } from './api/coreConfig';
import { missionsHandlers } from './api/missions';
import { reportDetailHandlers } from './api/reportDetail';
import { reportListHandlers } from './api/reportList';
import { scheduleHandlers } from './api/schedule';
import { scheduleLogHandlers } from './api/scheduleLog';

export const handlers = [
  ...scheduleLogHandlers,
  ...scheduleHandlers,
  ...reportListHandlers,
  ...reportDetailHandlers,
  ...addressHandlers,
  ...coreConfigHandlers,
  ...missionsHandlers,
];
