import { Box, Typography } from '@mui/material';

import { Scheduler } from '@components/Schedule/Scheduler';
import { TodayList } from '@components/Schedule/TodayList';
import { ScheduleLog } from '@/components/Schedule/ScheduleLog';
import { Suspense } from 'react';
import { LoadingPage } from './LoadingPage';
import {
  ScheduleProvider,
  useScheduleContext,
} from '@/hooks/constate/ScheduleContext';
import { styled, useTheme } from '@mui/material/styles';
import { MainContainer } from '@/components/myStyle/MainContainer';
import { useTranslation } from 'react-i18next';

const NoScheduleMessage = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: 150,
});

export const SchedulePage = () => {
  return (
    <Suspense fallback={<LoadingPage loadingText={'스케쥴 가져오는 중 ..'} />}>
      <ScheduleProvider>
        <Schedule />
      </ScheduleProvider>
    </Suspense>
  );
};

const Schedule = () => {
  const { daySortedSchedules } = useScheduleContext();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <MainContainer theme={theme}>
      <Scheduler />
      {daySortedSchedules ? (
        <TodayList schedules={daySortedSchedules} />
      ) : (
        <NoScheduleMessage>
          <Typography variant="h5" color="text.secondary">
            {t('There are no registered schedules available')}
          </Typography>
        </NoScheduleMessage>
      )}
      <ScheduleLog />
    </MainContainer>
  );
};
