import { queryKey } from '@/hooks/remote/querykey';
import useMissionSync from '@/hooks/remote/useMissionSync';
import { showToast } from '@/hooks/toast/toastUtils';
import { IconButton, Typography, Box, LinearProgress } from '@mui/material';
import { green } from '@mui/material/colors';
import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SyncTwoToneIcon from '@mui/icons-material/SyncTwoTone';
import CheckIcon from '@mui/icons-material/Check';

export const MissionSyncButton = () => {
  const queryClient = useQueryClient();
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const { mutateAsync: missionSync } = useMissionSync({
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.fetchMissionList]);
      setLoading(false);
      setSuccess(true);
    },
  });

  const handleSync = async () => {
    try {
      setLoading(true);
      await missionSync();
      showToast(t, 'success', '동기화 성공');
    } catch (e) {
      showToast(t, 'error', `동기화 에러 ${e}`);
    }
  };

  useEffect(() => {
    handleSync();
  }, []);

  return (
    <>
      <IconButton
        sx={{ display: 'flex', justifyContent: 'flex-end', marginLeft: 'auto' }}
        onClick={handleSync}
      >
        {success ? (
          <CheckIcon
            sx={{
              ...(success && {
                color: green[500],
                '&:hover': {
                  color: green[700],
                },
              }),
            }}
          />
        ) : (
          <SyncTwoToneIcon />
        )}
        <Typography
          variant="subtitle2"
          sx={{
            ...(success && {
              color: green[500],
              '&:hover': {
                color: green[700],
              },
            }),
          }}
        >
          {t('sync')}
        </Typography>
      </IconButton>
      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
    </>
  );
};
