import { List } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';
import { MainListItems } from './MainListItems';
import { ReportProvider } from '@/hooks/constate/ReportContext';
import { NavbarProvider } from '@constate/NavbarContext';
import { SettingListItems } from './SettingListItems';
import { useHeaderContext } from '@/hooks/constate/HeaderContext';

const drawerWidth = 210;

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'sticky',
    borderRight: 'none',
    color: theme.palette.text.primary,
    background: theme.palette.background.default,
    width: drawerWidth,
    whiteSpace: 'nowrap',
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export const SidebarPage = () => {
  return (
    <NavbarProvider>
      <Sidebar />
    </NavbarProvider>
  );
};

const Sidebar = () => {
  const { drawOpen } = useHeaderContext();

  return (
    <Drawer variant="permanent" open={drawOpen}>
      <List>
        <ReportProvider>
          <MainListItems />
          <SettingListItems />
        </ReportProvider>
      </List>
    </Drawer>
  );
};
