import { rest } from 'msw';
import { mockActionListResponse } from './data/missions/mockActionListResponse';
import { mockActionResponse } from './data/missions/mockActionResponse';
import { mockMissionListResponse } from './data/missions/mockMissionListResponse';
import { mockMissionResponse } from './data/missions/mockMissionResponse';
import { mockRouteMapImageResponse } from './data/missions/mockRouteMapImageResponse';

const baseUrl = process.env.REACT_APP_BACKEND_ENDPOINT_URL;

export const missionsHandlers = [
  // missionList
  rest.get(`${baseUrl}/missions`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockMissionListResponse));
  }),
  // actionList
  rest.get(`${baseUrl}/missions/:missionUuid/actions`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockActionListResponse));
  }),

  // action
  rest.get(
    `${baseUrl}/missions/:missionUuid/actions/:actionUuid`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockActionResponse));
    }
  ),
  // mission
  rest.get(`${baseUrl}/missions/:missionUuid`, (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockMissionResponse));
  }),
  // routeImage
  rest.get(
    `${baseUrl}/missions/route-map-image/:missionUuid`,
    (req, res, ctx) => {
      return res(ctx.status(200), ctx.json(mockRouteMapImageResponse));
    }
  ),
  // action edit
  rest.put(`${baseUrl}/missions/:missionUuid/actions`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  // sync
  rest.post(`${baseUrl}/missions/sync`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),
  // mission edit
  rest.put(`${baseUrl}/missions/:missionUuid`, (req, res, ctx) => {
    return res(ctx.status(200));
  }),

  // routeImage dit
  rest.put(
    `${baseUrl}/missions/route-map-image/:missionUuid`,
    (req, res, ctx) => {
      return res(ctx.status(200));
    }
  ),
  // routeImage delete
  rest.delete(
    `${baseUrl}/missions/route-map-image/:missionUuid`,
    (req, res, ctx) => {
      return res(ctx.status(200));
    }
  ),
];
