import { cleanIncludes } from '@/utils/cleanIncludes';
import { getAlarmInspectionStandards } from '@/utils/getAlarmInspectionStandards';
// import { getAlarmResultText } from '@/utils/getAlarmResultText';
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface AlarmReportProps {
  alarmListElement: Action;
  index: number;
}
interface Action {
  action_name: string;
  data_captures: DataCapture[];
}

interface DataCapture {
  time: string;
  dataUrl: string;
  key_result: KeyResult[];
  channelName: string;
  full_dataUrl: string;
}

interface KeyResult {
  name: string;
  value: string;
  level?: string;
}

export const NonImageAlarm = ({ alarmListElement }: AlarmReportProps) => {
  const { t } = useTranslation();

  const actionName = useMemo(() => {
    return alarmListElement?.action_name.split('-')[0];
  }, [alarmListElement]);

  const actionLine = useMemo(() => {
    return alarmListElement?.action_name.split('-')[2];
  }, [alarmListElement]);

  const actionFactory = useMemo(() => {
    return cleanIncludes(alarmListElement?.action_name, 'Human')
      ? '-'
      : alarmListElement?.action_name.split('-')[1];
  }, [alarmListElement]);

  const actionTime = useMemo(() => {
    return alarmListElement?.data_captures[0]?.time.slice(5);
  }, [alarmListElement]);

  const sortedAlarmListElement = alarmListElement?.data_captures?.sort(
    (a, b) => b?.key_result?.length - a?.key_result?.length
  );

  // const actionResult = useMemo(() => {
  //   return getAlarmResultText(
  //     alarmListElement?.action_name?.split('-')[0].trimEnd() || '',
  //     sortedAlarmListElement[0]?.key_result
  //   );
  // }, [alarmListElement]);

  const { maxThreshold, minThreshold } = useMemo(() => {
    return getAlarmInspectionStandards(
      actionName,
      sortedAlarmListElement[0]?.key_result
    );
  }, [alarmListElement]);
  return (
    <Box
      sx={{
        backgroundColor: 'white',
        borderRadius: 4,
        padding: 2,
        border: 1,
        borderColor: 'grey.400',
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TableContainer>
            <Table
              sx={{
                border: 1,
                borderColor: 'grey.400',
                width: '100%',
              }}
            >
              <TableHead
                sx={{
                  width: '100%',
                }}
              >
                <TableRow>
                  <TableCell
                    colSpan={2}
                    sx={{ fontWeight: 'bold', flexGrow: 0 }}
                  >
                    {t('line')}
                  </TableCell>
                  <TableCell sx={{ flexGrow: 0 }}>{actionLine}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                    {t('category')}
                  </TableCell>
                  <TableCell>{actionName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                    {t('factory')}
                  </TableCell>
                  <TableCell>{actionFactory}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell rowSpan={2} sx={{ fontWeight: 'bold' }}>
                    {t('specification')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {t('maximum')}
                  </TableCell>
                  <TableCell>{maxThreshold}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {t('minimum')}
                  </TableCell>
                  <TableCell>{minThreshold}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                    {t('date')}
                  </TableCell>
                  <TableCell>{actionTime}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2} sx={{ fontWeight: 'bold' }}>
                    {t('decision')}
                  </TableCell>
                  <TableCell>{'check'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item xs={6}>
          <TableContainer>
            <Table
              sx={{
                border: 1,
                borderColor: 'grey.400',
                width: '100%',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>{t('name')}</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>
                    {t('result')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {alarmListElement?.data_captures[0]?.key_result.map(
                  (key, index) => (
                    <TableRow key={index}>
                      <TableCell>{key.name}</TableCell>
                      <TableCell
                        sx={{
                          color: key.value.includes('좋음')
                            ? 'green'
                            : key.value.includes('나쁨')
                            ? 'red'
                            : 'black',
                        }}
                      >
                        {key.value}
                      </TableCell>{' '}
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Box>
  );
};
