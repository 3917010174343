import React, { Dispatch, SetStateAction } from 'react';
import { SubmitHandler, Control, UseFormHandleSubmit } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { FormSelect } from '../Common/FormSelect';
import { FormInputText } from '../Common/FormInputText';

interface FactoryFormValue {
  addFactoryName: string;
  newFactoryName: string;
  selectedFactory: {
    name: string;
    id: number;
    newName: string;
  };
}

interface EditFormProps {
  onSubmit: SubmitHandler<FactoryFormValue>;
  handleSubmit: UseFormHandleSubmit<FactoryFormValue, undefined>;
  control: Control<FactoryFormValue>;
  FACTORY: { value: string; label: string }[];
  setAction: Dispatch<SetStateAction<string>>;
}

const EditForm: React.FC<EditFormProps> = ({
  handleSubmit,
  onSubmit,
  control,
  FACTORY,
  setAction,
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={2} alignItems="center" mt={3}>
        <Grid item xs={12} mb={1}>
          <FormSelect
            name="selectedFactory.name"
            control={control}
            defaultValue=""
            rules={{
              required: {
                value: true,
                message: '공장을 선택해주세요',
              },
            }}
            selectGroup={FACTORY}
            SelectFieldProps={{ label: '공장' }}
            FormControlProps={{ size: 'small', fullWidth: true }}
          />
        </Grid>
        <Grid item xs={12}>
          <FormInputText
            name="selectedFactory.newName"
            control={control}
            defaultValue=""
            textFieldProps={{
              label: '선택된 공장',
              fullWidth: true,
              type: 'text',
            }}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          {/* <Button
            variant="contained"
            type="submit"
            onClick={() => setAction('delete')}
            sx={{ mr: 1 }}
          >
            삭제
          </Button> */}
          <Button
            variant="contained"
            type="submit"
            onClick={() => setAction('edit')}
          >
            편집
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default EditForm;
