import { useState } from 'react';
import constate from 'constate';
import * as locales from '@mui/material/locale';

type SupportedLocales = keyof typeof locales;

const useLangData = () => {
  const [locale, setLocale] = useState<SupportedLocales>('koKR');

  return {
    locale,
    setLocale,
  };
};

export const [LangProvider, useLangContext] = constate(useLangData);
