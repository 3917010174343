import { Box } from '@mui/material';
import { FormInputText } from '../Common/FormInputText';
import { Control } from 'react-hook-form';
interface SMTPList {
  address: string;
  name: string;
  id: string;
  password: string;
  port: number;
}

interface ScoutInfo {
  token: string;
}

interface SMSList {
  apiKey: string;
  senderPhoneNumber: number;
  agency: string;
  default: boolean;
  time: string;
}

interface OnboardingFormValue {
  scoutInfo: ScoutInfo;
  logoImage: File;
  smtpList: SMTPList;
  smsList: SMSList;
}

interface InfoFormProps {
  control: Control<OnboardingFormValue>;
}
export const SMTPInfoForm = ({ control }: InfoFormProps) => {
  return (
    <Box>
      <FormInputText
        name="smtpList.name"
        control={control}
        rules={{ required: 'SMTP 서비스 별칭을 정해주세요' }}
        defaultValue={''}
        textFieldProps={{
          label: 'SMTP 별칭',
          fullWidth: true,
          type: 'text',
        }}
      />
      <FormInputText
        name="smtpList.address"
        control={control}
        rules={{ required: 'SMTP 주소입력을 해주세요' }}
        defaultValue={''}
        textFieldProps={{
          label: 'SMTP 주소',
          fullWidth: true,
          type: 'text',
        }}
      />
      <FormInputText
        name="smtpList.id"
        control={control}
        rules={{ required: 'SMTP 아이디를 입력해주세요' }}
        defaultValue={''}
        textFieldProps={{
          label: 'SMTP 아이디',
          fullWidth: true,
          type: 'email',
        }}
      />
      <FormInputText
        name="smtpList.password"
        control={control}
        rules={{ required: 'SMTP 비밀번호를 입력해주세요' }}
        defaultValue={''}
        textFieldProps={{
          label: 'SMTP 비밀번호',
          fullWidth: true,
          type: 'password',
        }}
      />
      <FormInputText
        name="smtpList.port"
        control={control}
        rules={{ required: 'SMTP 포트번호를 입력해주세요' }}
        defaultValue={0}
        textFieldProps={{
          label: 'SMTP 포트번호',
          fullWidth: true,
          type: 'number',
        }}
      />
    </Box>
  );
};
