import {
  QueryClientProvider,
  QueryClient,
  QueryCache,
} from '@tanstack/react-query';
import { StyledToastContainer } from '@hooks/toast/toastStyle';
import { ThemeProvider } from './theme';
import { Route, Routes } from 'react-router';
import { MainLayout } from './pages/MainLayout';
import {
  SchedulePage,
  PatrolReportPage,
  MissionSettingPage,
  Dashboard,
  AddressPage,
  OnboardingPage,
  ReportListPage,
  SystemSettingPage,
} from './pages';
import { NotfoundPage } from './pages/NotfoundPage';
import { LangProvider } from './hooks/constate/LangContext';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
    },
  },
  queryCache: new QueryCache(),
});

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <LangProvider>
        <ThemeProvider>
          <StyledToastContainer newestOnTop />
          <Routes>
            <Route element={<MainLayout />}>
              <Route path="/" element={<SchedulePage />} />
              <Route path="/report/*" element={<PatrolReportPage />} />
              <Route path="/address" element={<AddressPage />} />
              <Route path="/msetting/*" element={<MissionSettingPage />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/list" element={<ReportListPage />} />
              <Route path="/systemset" element={<SystemSettingPage />} />
              <Route path="*" element={<NotfoundPage />} />
            </Route>
            <Route>
              <Route path="/onboarding" element={<OnboardingPage />} />
            </Route>
          </Routes>
        </ThemeProvider>
      </LangProvider>
    </QueryClientProvider>
  );
};

export default App;
