/* eslint-disable camelcase */
import { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useReportContext } from '@/hooks/constate/ReportContext';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/system';
import { cleanAndNormalizeString } from '@/utils/cleanAndNormalizeString';

const BorderBox = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  p: 1,
  border: '1px solid',
  borderColor: theme.palette.grey[400],
  borderRadius: 8,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const MissionSummary = () => {
  const { report } = useReportContext();
  const { t } = useTranslation();

  const travelDistanceData = report?.action
    .find((data) => data.action_name.startsWith('developer'))
    ?.data_captures?.find((item) =>
      item.key_result.some((key) => key.name.includes('travel'))
    );
  const travelDistance = travelDistanceData?.key_result.find((key) =>
    key.name.includes('travel')
  )?.value;

  const robotDistanceInMeters = travelDistance
    ? String(Math.trunc(Number(travelDistance)))
    : '';

  const batteryConsumption = useMemo(() => {
    const developerLogActions = report?.action.filter((data) =>
      cleanAndNormalizeString(data.action_name)
        .split('_')[0]
        .includes('developerlog')
    );

    const battery =
      developerLogActions
        ?.map((action) => {
          const batteryDataCapture = action.data_captures?.find((data) =>
            data.channelName.includes('battery')
          );

          const batteryKeyResult = batteryDataCapture?.key_result.find((data) =>
            data.name.includes('battery')
          );

          return batteryKeyResult?.value;
        })
        .find((value) => value !== undefined) ?? '';

    return battery;
  }, [report]);

  const spentTime = useMemo(() => {
    const { time_spent } = report?.mission[0] ?? {};
    if (!time_spent) return '';
    const { hours, minutes, seconds } = time_spent;
    return `${hours ? `${hours}${t('hours')} ` : ''}${minutes}${t(
      'minutes'
    )} ${seconds}${t('seconds')}`;
  }, [report?.mission]);

  const startTime = useMemo(() => {
    if (!report) return '';

    const [, , , hour, minute] = report?.mission[0]?.start_time.split(' ');

    return `${hour} ${minute}`;
  }, [report?.mission]);

  const startYear = useMemo(() => {
    if (!report) return '';

    const [year, month, day, ,] = report?.mission[0]?.start_time.split(' ');

    return `${year} ${month} ${day}`;
  }, [report?.mission]);

  const convertToKm = (meters: number): string => {
    if (meters / 1000 <= 0) return meters + 'm';
    const km = meters / 1000;
    return km.toFixed(2) + 'km';
  };
  const robotDistance = robotDistanceInMeters
    ? convertToKm(Number(robotDistanceInMeters))
    : '미취합/미수집';

  const batteryLevel = batteryConsumption
    ? `${Math.round(parseInt(batteryConsumption))}%`
    : '미취합/미수집';

  return (
    <Grid container spacing={4} sx={{ mb: 3 }}>
      <Grid item xs={12} md={6} lg={3}>
        <BorderBox>
          <Typography variant="h6" gutterBottom>
            {startYear}
          </Typography>
        </BorderBox>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <BorderBox>
          <Typography variant="h6" gutterBottom>
            {/* {startTime} {t('start')} {t('mission')} */}
            {t('missionStartTime', { startTime })}
          </Typography>
        </BorderBox>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <BorderBox>
          {report?.mission[0]?.status === 'SUCCESS' ? (
            <Typography variant="h5" sx={{ color: 'green' }}>
              {report?.mission[0]?.status}
            </Typography>
          ) : (
            <Typography variant="h5" sx={{ color: 'red' }}>
              {report?.mission[0]?.status}
            </Typography>
          )}
        </BorderBox>
      </Grid>
      <Grid item xs={12} md={6} lg={3}>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            border: 1,
            borderColor: 'grey.400',
            borderRadius: 2,
            p: 1,
            paddingLeft: 7,
          }}
        >
          <Typography variant="h6" gutterBottom>
            {t('travelDistance')} : {robotDistance}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {t('missionDuration')} : {spentTime}
          </Typography>
          <Typography variant="h6" gutterBottom>
            {t('batteryConsumption')} : {batteryLevel}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};
