import { Button } from '@mui/material';
import { GridCellParams, GridColDef } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const getMissionColumns = (): GridColDef[] => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // const MoveActionEdit = (params: GridCellParams) => {
  //   navigate(`/msetting/action/${params.row.mission_uuid}`);
  // };

  const MoveMissionEdit = (params: GridCellParams) => {
    navigate(`/msetting/${params.row.mission_uuid}`);
  };

  return [
    {
      field: 'mission_name',
      headerName: t('scout Mission') || 'scout Mission',
      width: 300,
    },
    {
      field: 'alias',
      headerName: t('alias') || 'alias',
      width: 300,
    },
    {
      field: 'modified_at',
      headerName: t('modified_at') || 'modified_at',
      width: 300,
    },
    // {
    //   field: 'actionEdit',
    //   headerName: '액션 편집',
    //   width: 100,
    //   renderCell: (params: GridCellParams) => (
    //     <Button variant="outlined" onClick={() => MoveActionEdit(params)}>
    //       편집
    //     </Button>
    //   ),
    // },
    {
      field: 'missionEdit',
      headerName: `${t('MissionEdit')}`,
      width: 100,
      renderCell: (params: GridCellParams) => (
        <Button variant="contained" onClick={() => MoveMissionEdit(params)}>
          {t('edit')}
        </Button>
      ),
    },
  ];
};
