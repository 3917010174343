interface ActionResponse {
  mission_uuid: string;
  action_uuid: string;
  action_name: string;
  source_action_create_at: string;
  source_action_modified_at: string;
  alias: string;
  create_at: string;
  modified_at: string;
  factory: string;
  line: string;
  description: string;
  unit: string;
  action_enable: boolean;
  inspection_type: string;
  inspection_criteria: string;
}

export const mockActionResponse: ActionResponse[] = [
  {
    mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
    action_uuid: '0e94b206-a8a2-45db-a252-236925e0c232',
    action_name: 'Extension Finished: 1',
    source_action_create_at: '2023-06-15T08:04:19.884225Z',
    source_action_modified_at: '2023-06-15T08:04:19.884225Z',
    alias: 'test1234',
    create_at: '2023-07-10T14:08:21.553359+09:00',
    modified_at: '2023-07-10T14:09:31.728690+09:00',
    factory: 'clobot-factory',
    line: 'spot-line',
    description: 'clobot spot test',
    unit: '섭씨',
    action_enable: false,
    inspection_type: 'min-max',
    inspection_criteria: '10-50',
  },
];
