import { fetchSMS } from '@api/coreConfig/index';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { queryKey } from './querykey';

interface SMSInfoResponse {
  id: number;
  agency: string;
  api_key: string;
  sender_phone_number: string;
  default: boolean;
  is_need_config: boolean;
  setting_time: string;
}
const useSMSinfo = (
  variable = undefined,
  options?: UseQueryOptions<SMSInfoResponse, AxiosError>
) => {
  const fetcher = async () => {
    const result = await fetchSMS();
    return result.data;
  };

  return useQuery<SMSInfoResponse, AxiosError>(
    [queryKey.fetchSMS, variable],
    fetcher,
    options
  );
};

export default useSMSinfo;
