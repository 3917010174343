import { cleanIncludes } from './cleanIncludes';
interface KeyResult {
  name: string;
  value: string;
}
type AlarmInspectionStandard = {
  minThreshold: string;
  maxThreshold: string;
};

export const getAlarmInspectionStandards = (
  alarmName: string,
  keyResults?: KeyResult[]
): AlarmInspectionStandard => {
  if (!keyResults) return { minThreshold: '-', maxThreshold: '-' };

  const findValue = (name: string): number | null => {
    const item = keyResults.find((item) => item.name.includes(name));
    if (!item) return null;
    return parseInt(item.value);
  };

  if (cleanIncludes(alarmName, 'pincam')) {
    return {
      minThreshold: String(findValue('min')),
      maxThreshold: String(findValue('max')),
    };
  }

  if (cleanIncludes(alarmName, 'thermal')) {
    const getThresholdValue = (suffix: string) =>
      keyResults.find(
        (result) =>
          cleanIncludes(result.name, 'threshold') &&
          cleanIncludes(result.name, suffix)
      )?.value ?? '-';

    const minThreshold = getThresholdValue('min');
    const maxThreshold = getThresholdValue('max');

    return { minThreshold, maxThreshold };
  }
  return { minThreshold: '-', maxThreshold: '-' };
};
