import { client } from '@api/client';
import Schedule from '@mui/icons-material/Schedule';

export const fetchSchedule = () => {
  return client.get<ScheduleResponse>(`schedule/`);
};

export const registerSchedule = (params: RegisterScheduleParams) => {
  return client.post(`schedule/work`, {
    data: {
      robot: params.robotNickname,
      timeMs: params.date,
      repeatMs: params.repeatMs,
      missionId: params.missionId,
      eventName: params.eventName,
      isSMSChecked: params.isSMSChecked,
      isEmailChecked: params.isEmailChecked,
    },
  });
};

export const deleteSchedule = (params: DeleteScheduleParams) => {
  return client.delete(`schedule/work`, {
    data: params,
  });
};

export const fetchScheduleLogs = (params?: FetchScheduleLogsParams) => {
  const searchParams = new URLSearchParams();

  // Add the query parameters if the params object is provided
  if (params) {
    Object.entries(params).forEach(([key, value]) => {
      searchParams.append(key, value);
    });
  }

  // Make the request with the query parameters
  return client.get('schedule/log', {
    params: searchParams,
  });
};

interface ScheduleResponse {
  mission: ScheduledMission[];
  robots: ScheduledRobot[];
  calendar?: Calendar;
}

interface ScheduledMission {
  uuid: string;
  mission_id: number;
  name: string;
  saved_at: string;
}

interface ScheduledRobot {
  robotIndex: number;
  hostname: string;
  username: string;
  nickname: string;
}

interface Calendar {
  activeEvents: ActiveEvent[];
}
interface Agent {
  nickname: string;
}

interface ActiveEvent {
  eventMetadata: EventMetadata;
  agent: Agent;
  task: Task;
  schedule: Schedule;
  booking?: BookingData;
}
export interface Task {
  missionId: string;
}
export interface EventMetadata {
  eventId: string;
  name: string;
  modificationTimeMs: string;
  modificationUser: string;
}
export interface BookingData {
  is_email_received: boolean;
  is_sms_received: boolean;
}

interface RegisterScheduleParams {
  robotNickname: string;
  date: number;
  repeatMs: number;
  missionId: string;
  eventName: string;
  isSMSChecked: boolean;
  isEmailChecked: boolean;
}

interface Schedule {
  timeMs: string;
  repeatMs: string;
}

interface DeleteScheduleParams {
  eventMetadata: EventMetadata;
  agent: Agent;
  task: Task;
  schedule: Schedule;
}

interface FetchScheduleLogsParams {
  from: string;
  end: string;
}
