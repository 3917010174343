import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import styled from 'styled-components';
import { showToast } from '@/hooks/toast/toastUtils';
import { useTranslation } from 'react-i18next';

interface DateRangePickerProps {
  onStartDateChange: (date: Date) => void;
  onEndDateChange: (date: Date) => void;
}

const DateWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 1rem;
`;

export const DateRangePicker = ({
  onStartDateChange,
  onEndDateChange,
}: DateRangePickerProps) => {
  const [searchType, setSearchType] = useState('range');
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { t } = useTranslation();

  const getCurrentMonthStartDate = () => {
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    startOfMonth.setHours(startOfMonth.getHours() + 9);
    return startOfMonth;
  };

  const getCurrentMonthEndDate = () => {
    const now = new Date();
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
    endOfMonth.setDate(endOfMonth.getDate());
    endOfMonth.setHours(endOfMonth.getHours() + 9);

    return endOfMonth;
  };

  const handleSearch = () => {
    const start = new Date(startDate);
    start.setHours(0, 0, 0, 0);
    start.setHours(start.getHours() + 9);

    const end = new Date(endDate);
    end.setHours(0, 0, 0, 0);
    end.setHours(end.getHours() + 9);

    onStartDateChange(start);
    onEndDateChange(end);
    showToast(t, 'success');
  };

  return (
    <DateWrapper>
      <FormControl
        component="fieldset"
        sx={{ display: 'flex', justifyContent: 'center' }}
      >
        <FormLabel component="legend">{t('searchType')}</FormLabel>
        <RadioGroup
          aria-label="search-type"
          name="search-type"
          value={searchType}
          onChange={(event) => setSearchType(event.target.value)}
        >
          <Box display="flex" alignItems="center">
            <FormControlLabel
              value="range"
              control={<Radio />}
              label={t('duration')}
              sx={{ minWidth: '7rem', margin: '0.1rem' }}
            />
            {searchType === 'range' && (
              <Grid container spacing={2} alignItems="center" direction="row">
                <Grid item>
                  <TextField
                    label={t('startDate')}
                    type="date"
                    defaultValue={startDate.toISOString().slice(0, 10)}
                    onChange={(event) =>
                      setStartDate(new Date(event.target.value))
                    }
                    inputProps={{
                      min: getCurrentMonthStartDate()
                        .toISOString()
                        .slice(0, 10),
                      max: getCurrentMonthEndDate().toISOString().slice(0, 10),
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    label={t('endDate')}
                    type="date"
                    defaultValue={endDate.toISOString().slice(0, 10)}
                    onChange={(event) =>
                      setEndDate(new Date(event.target.value))
                    }
                    inputProps={{
                      min: getCurrentMonthStartDate()
                        .toISOString()
                        .slice(0, 10),
                      max: getCurrentMonthEndDate().toISOString().slice(0, 10),
                    }}
                  />
                </Grid>
              </Grid>
            )}
            <FormControlLabel
              value="today"
              control={<Radio />}
              label={t('today')}
              sx={{ minWidth: '7rem', margin: '0.1rem' }}
            />
          </Box>
        </RadioGroup>
      </FormControl>
      <Button
        variant="contained"
        onClick={handleSearch}
        sx={{ marginTop: '1rem' }}
      >
        {t('search')}
      </Button>
    </DateWrapper>
  );
};
