import { useParams } from 'react-router';
import { useState } from 'react';
import constate from 'constate';
import { useMediaQuery } from '@mui/material';

const useHeaderData = () => {
  const [pageName, setpageName] = useState('schedule');
  const [drawOpen, setDrawOpen] = useState(
    !useMediaQuery('(max-width: 1024px)')
  );

  const params = useParams();

  if (!params) setpageName('schedule');

  return {
    pageName,
    setpageName,
    drawOpen,
    setDrawOpen,
  };
};

export const [HeaderProvider, useHeaderContext] = constate(useHeaderData);
