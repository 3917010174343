import { StyledTableCell } from './DataTable';
import { TableRow } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReportContext } from '@/hooks/constate/ReportContext';

export const InspectionPointRow = () => {
  const { t } = useTranslation();
  const { report } = useReportContext();

  return (
    <>
      <TableRow>
        {report && (
          <StyledTableCell rowSpan={report.action.length + 2}>
            {t('inspectionPoint')}
          </StyledTableCell>
        )}
      </TableRow>
      <TableRow
        sx={{
          '& .MuiStyledTableCell-root': {
            fontWeight: 'bold',
          },
        }}
      >
        <StyledTableCell>{t('factory')}</StyledTableCell>
        <StyledTableCell>{t('line')}</StyledTableCell>
        <StyledTableCell colSpan={4}>{t('inspectionlist')}</StyledTableCell>
        <StyledTableCell align="center">{t('executionStatus')}</StyledTableCell>
      </TableRow>
    </>
  );
};
