import { rest } from 'msw';

const baseUrl = process.env.REACT_APP_BACKEND_ENDPOINT_URL;

export const reportListHandlers = [
  rest.get(`${baseUrl}/report/list`, (req, res, ctx) => {
    const listPage = req.url.searchParams.get('page') || '1';

    const generateReport = (id: number) => ({
      uuid: `afc0a7ea-349e-47bc-a217-88e0030ff58${id}`,
      url: `${baseUrl}/report/detail/afc0a7ea-349e-47bc-a217-88e0030ff58${id}`,
      mission: [
        {
          name: `(221119) AG2_FULL_${id}`,
          status: 'SUCCESS',
          start_time: `03/31/2023 03:58:4${id}`,
          end_time: `03/31/2023 04:37:3${id}`,
          time_spent: {
            hours: 0,
            minutes: 38,
            seconds: 58,
          },
          mission_route: 'Press - Body - Design - Paint - Body - Press',
        },
      ],
      robot: [
        {
          info: {
            name: `beta4${id}`,
            ip: `223.171.142.14${id}`,
          },
          subscribers: [],
        },
      ],
      created_at: `2023-04-05T13:42:22.88242${id}`,
    });

    // Create an array with 5 sets of example data
    const exampleReports = Array.from({ length: 5 }, (_, i) =>
      generateReport(i + 1)
    );

    // In this case, there is only one report, so no need to filter and paginate
    const response = {
      reports: exampleReports,
      pageCnt: 1,
      curPage: parseInt(listPage),
    };

    return res(ctx.json(response));
  }),
];
