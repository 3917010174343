import { StyledTableCell } from './DataTable';
import { TableRow, Typography, Stack, Box } from '@mui/material';
import { useReportContext } from '@/hooks/constate/ReportContext';
import { useTranslation } from 'react-i18next';
import React, { useMemo } from 'react';
import { getDetectedNumber } from '@/utils/getDetectedNumber';

interface ConstantSurveillanceList {
  name: string;
  inspection: string;
  content: string;
  unit: string;
}

interface ConstantSurveillanceRowProps {
  constantSurveillanceList: ConstantSurveillanceList[];
}
export const ConstantSurveillanceRow = ({
  constantSurveillanceList,
}: ConstantSurveillanceRowProps) => {
  const { t } = useTranslation();
  const { report } = useReportContext();

  return (
    <>
      {constantSurveillanceList.map(
        ({ name, inspection, content, unit }, idx) => {
          const detections = useMemo(() => {
            if (!report) return;
            return getDetectedNumber(report.action, name);
          }, [report]);
          return (
            <React.Fragment key={idx}>
              <TableRow>
                <StyledTableCell rowSpan={2}>{t(inspection)}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell colSpan={5}>
                  <Stack direction="row" spacing={1}>
                    <Typography>{t(content)}</Typography>
                  </Stack>
                </StyledTableCell>
                <StyledTableCell align="center">
                  {detections} {t(unit)}
                </StyledTableCell>
                <StyledTableCell align="center">-</StyledTableCell>
              </TableRow>
            </React.Fragment>
          );
        }
      )}
    </>
  );
};
