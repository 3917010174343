import { Suspense } from 'react';
import { Stack } from '@mui/material';
import { LoadingPage } from './LoadingPage';
import { ReportProvider } from '@/hooks/constate/ReportContext';
import { ReportDetail } from '@/components/PatrolReport/ReportDetail';
import { PdfButton } from '@/components/PatrolReport/PdfButton';
import { Route, Routes, useParams } from 'react-router';
import { MainContainer } from '@/components/myStyle/MainContainer';
import { useTheme } from '@mui/material/styles';

export const PatrolReportPage = () => {
  return (
    <Suspense
      fallback={<LoadingPage loadingText={'레포트 페이지 로딩 중..'} />}
    >
      <ReportProvider>
        <Routes>
          <Route path="/" element={<PatrolReport />} />
          <Route path=":uuid" element={<PatrolReport />} />
          <Route path="/:type/:uuid" element={<PatrolReport />} />
        </Routes>
      </ReportProvider>
    </Suspense>
  );
};

const PatrolReport = () => {
  const { type } = useParams();
  const theme = useTheme();

  return (
    <ReportProvider>
      <MainContainer theme={theme}>
        <Stack direction="row" spacing={1}>
          {type === 'selenium' ? (
            <PdfButton version="server" />
          ) : (
            <PdfButton version="local" />
          )}
        </Stack>
        <Suspense
          fallback={<LoadingPage loadingText={'레포트 가져오는 중 ..'} />}
        >
          <ReportDetail />
        </Suspense>
      </MainContainer>
    </ReportProvider>
  );
};
