import { client } from '@api/client';

interface FetchActionListProps {
  uuid: string;
}
interface FetchActionsProps {
  missionUuid: string;
  actionUuid: string;
}

interface EditActionsProps {
  mission_uuid: string;
  action_uuid: string;
  action_name: string;
  source_action_create_at: string;
  source_action_modified_at: string;
  alias: string | null;
  create_at: string;
  modified_at: string;
  factory: string | null;
  line: string | null;
  description: string | null;
  unit: string | null;
  action_enable: boolean;
  inspection_type: string | null;
  inspection_criteria: string | null;
}

export const fetchActionList = (params: FetchActionListProps) => {
  return client.get(`/missions/${params.uuid}/actions`);
};

export const fetchActions = (params: FetchActionsProps) => {
  return client.get(
    `/missions/${params.missionUuid}/actions/${params.actionUuid}`
  );
};

export const editActions = (params: EditActionsProps[]) => {
  return client.put(`/missions/${params[0].mission_uuid}/actions`, {
    data: params,
  });
};

// export const editActions = (params: EditActionsProps[]) => {
//   return client.put(`/missions/${params[0].mission_uuid}/actions`, {
//     data: params,
//   });
// };
