import { useState } from 'react';
import { Box, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import Dns from '@mui/icons-material/Dns';
import SettingsIcon from '@mui/icons-material/Settings';
import ListItemLink from './ListItemLink';
import { useHeaderContext } from '@/hooks/constate/HeaderContext';
import { useTranslation } from 'react-i18next';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
const data = [
  {
    icon: <Dns />,
    title: 'missionSetting',
    href: '/msetting',
    disabled: false,
  },
  {
    icon: <PermContactCalendarIcon />,
    title: 'contactSetting',
    href: '/address',
  },
  {
    icon: <SettingsOutlinedIcon />,
    title: 'SystemSetting',
    href: '/systemset',
  },
];

export const SettingListItems = () => {
  const [open, setOpen] = useState(true);
  const { drawOpen } = useHeaderContext();
  const { t } = useTranslation();

  return (
    <Box>
      <ListItemButton
        onClick={() => setOpen(!open)}
        sx={{
          pb: 1,
          borderRadius: '12px',
          '&:hover, &:focus': { '& svg': { opacity: 1 } },
        }}
      >
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        {drawOpen && (
          <>
            <ListItemText primary={t('setting')} />
            <KeyboardArrowDown
              sx={{
                mr: -1,
                opacity: 0,
                transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                transition: '0.2s',
              }}
            />
          </>
        )}
      </ListItemButton>
      {open &&
        data.map((item, index) => {
          const itemKey = `custom-${index}`;
          return (
            <ListItemLink key={item.title} item={item} itemKey={itemKey} />
          );
        })}
    </Box>
  );
};
