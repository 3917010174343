// AddForm.tsx
import React from 'react';
import { SubmitHandler, Control, UseFormHandleSubmit } from 'react-hook-form';
import { Button, Grid } from '@mui/material';
import { FormInputText } from '../Common/FormInputText';
import { queryKey } from '@/hooks/remote/querykey';
import useRegisterFactory from '@/hooks/remote/useRegisterFactory';
import { showToast } from '@/hooks/toast/toastUtils';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';

interface FactoryFormValue {
  addFactoryName: string;
  newFactoryName: string;
  selectedFactory: {
    name: string;
    id: number;
    newName: string;
  };
}

interface AddFormProps {
  handleSubmit: UseFormHandleSubmit<FactoryFormValue, undefined>;
  control: Control<FactoryFormValue>;
}

const AddForm: React.FC<AddFormProps> = ({ control, handleSubmit }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { mutateAsync: createFactory } = useRegisterFactory({
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.fetchFactory]);
    },
  });
  const factoryCreate: SubmitHandler<FactoryFormValue> = async (data) => {
    try {
      await createFactory(data);
      showToast(t, 'success', '추가완료');
    } catch (e) {
      showToast(t, 'error', '전송오류');
    }
  };

  return (
    <form onSubmit={handleSubmit(factoryCreate)}>
      <Grid container alignItems="center">
        <Grid item xs={12}>
          <FormInputText
            name="addFactoryName"
            control={control}
            defaultValue={''}
            rules={{ required: '발신자 번호를 입력해주세요' }}
            textFieldProps={{
              fullWidth: true,
              type: 'tel',
              label: '추가할 공장 이름',
            }}
          />
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Button variant="contained" type="submit">
            추가
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default AddForm;
