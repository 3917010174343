import React, { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Button,
  FormLabel,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { SubmitHandler, useForm } from 'react-hook-form';

import { FormFileInput } from '../Common/FormFileInput';
import { FormInputText } from '../Common/FormInputText';
import { MainContainer } from '../myStyle/MainContainer';
import { FormRadio } from '../Common/FormRadio';

import { showToast } from '@/hooks/toast/toastUtils';
import useMissions from '@/hooks/remote/useMissions';
import useEditMissions from '@/hooks/remote/useEditMissions';
import useFactories from '@/hooks/remote/useFactory';
import { queryKey } from '@/hooks/remote/querykey';
import { useQueryClient } from '@tanstack/react-query';

import notFoundimage from '@assets/images/404image.png';

interface MissionEditFormValue {
  routeMapImage: File;
  routeDescription: string;
  missionAlias: string;
  factory: string;
  detailRoute: string;
}

export const MissionEdit = () => {
  const theme = useTheme();
  const { handleSubmit, control } = useForm<MissionEditFormValue>({});
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { uuid: missionUuid } = useParams();

  const { data: mission } = useMissions({ uuid: missionUuid || '' });
  const { data: factoriesList } = useFactories(undefined);

  const { mutateAsync: editMission } = useEditMissions({
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.fetchMissions]);
    },
  });

  const FACTORIES = useMemo(() => {
    return factoriesList?.map((factory) => ({
      value: factory.name,
      label: factory.name,
    }));
  }, [factoriesList]);

  const onSubmit: SubmitHandler<MissionEditFormValue> = async (data) => {
    try {
      const EditData = { ...data, uuid: missionUuid || '' };
      await editMission(EditData);
      showToast(t, 'success', '미션 수정 성공');
    } catch (e) {
      showToast(t, 'error', '미션 수정 실패');
    }
  };

  const handleErrorImg = useCallback(
    (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
      e.currentTarget.src = notFoundimage;
      e.currentTarget.onerror = null;
    },
    []
  );

  const RenderMissionInfo = () => (
    <Stack direction="row" columnGap={5} m={2}>
      <Typography variant="h4">
        {t('scout Mission')} : {mission?.mission_name}
      </Typography>
      <Typography variant="h4">
        {t('alias')} : {mission?.alias}
      </Typography>
      <Typography variant="h4">
        {t('modified_at')} :{mission?.modified_at}
      </Typography>
    </Stack>
  );

  const RenderForm = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack direction="row" columnGap={2}>
        <Stack
          rowGap={2}
          sx={{ border: 1, borderRadius: 1, borderColor: '#CDD5DF' }}
          p={1}
        >
          <FormLabel>{t('Select Route Image')}</FormLabel>
          <Button variant="contained" component="label">
            <FormFileInput
              name="routeMapImage"
              control={control}
              InputProps={{
                inputProps: { accept: 'image/*' },
                type: 'file',
                fullWidth: true,
              }}
            />
          </Button>
          <Box>
            <img
              src={mission?.file}
              referrerPolicy="no-referrer"
              alt="이미지가 없습니다."
              onError={handleErrorImg}
              height="300"
              width="300"
            />
          </Box>
        </Stack>
        <Box p={1} sx={{ border: 1, borderRadius: 1, borderColor: '#CDD5DF' }}>
          <FormLabel>{t('MissionInfo Edit')}</FormLabel>
          <FormInputText
            name="missionAlias"
            control={control}
            rules={{}}
            defaultValue={mission?.alias}
            textFieldProps={{
              label: `${t('missionAlias')}`,
              fullWidth: true,
            }}
          />
          <FormInputText
            name="routeDescription"
            control={control}
            rules={{}}
            defaultValue={mission?.route_description}
            textFieldProps={{
              label: `${t('routeDescription')}`,
              fullWidth: true,
            }}
          />
          <FormRadio
            name="factory"
            control={control}
            rules={{ required: '공장을 선택해주세요' }}
            defaultValue={mission?.factory}
            group={FACTORIES ?? []}
            label="공장 선택"
          />
          <FormInputText
            name="detailRoute"
            control={control}
            rules={{ required: '경로 상세를 입력해주세요' }}
            defaultValue={mission?.detail_route}
            textFieldProps={{
              label: `${t('routeDetail')}`,
              fullWidth: true,
            }}
          />
          <Box mt={5}>
            <Button variant="contained" fullWidth type="submit">
              {t('submit')}
            </Button>
          </Box>
        </Box>
      </Stack>
    </form>
  );

  return (
    <MainContainer theme={theme}>
      <Paper>
        <RenderMissionInfo />
        <RenderForm />
      </Paper>
    </MainContainer>
  );
};
