import { Box } from '@mui/material';
import { FormInputText } from '../Common/FormInputText';
import { Control } from 'react-hook-form';

interface SMTPList {
  address: string;
  name: string;
  id: string;
  password: string;
  port: number;
}

interface ScoutInfo {
  token: string;
}

interface SMSList {
  apiKey: string;
  senderPhoneNumber: number;
  agency: string;
  default: boolean;
  time: string;
}

interface OnboardingFormValue {
  scoutInfo: ScoutInfo;
  logoImage: File;
  smtpList: SMTPList;
  smsList: SMSList;
}

interface InfoFormProps {
  control: Control<OnboardingFormValue>;
}
export const ScoutAdminIdPassForm = ({ control }: InfoFormProps) => {
  return (
    <Box>
      <FormInputText
        name="scoutInfo.token"
        control={control}
        rules={{ required: 'scout token을 입력해주세요' }}
        defaultValue=""
        textFieldProps={{
          label: 'scout token',
          fullWidth: true,
          type: 'text',
        }}
      />
    </Box>
  );
};
