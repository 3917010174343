import { client } from '@api/client';

interface FetchMissionsProps {
  uuid: string;
}

interface FetchRouteImageProps {
  uuid: string;
}
interface EditRouteImageProps {
  uuid: string;
  image: File;
}

interface DeleteRouteImageProps {
  uuid: string;
  image: File;
}

interface EditMissionsProps {
  uuid: string;
  routeMapImage: File;
  routeDescription: string;
  missionAlias: string;
  factory: string;
  detailRoute: string;
}

export const missionSync = () => {
  return client.post(`/missions/sync`);
};

export const fetchMissionList = () => {
  return client.get(`/missions/`);
};

export const fetchMissions = (params: FetchMissionsProps) => {
  return client.get(`/missions/${params.uuid}`);
};

export const editMissions = (params: EditMissionsProps) => {
  return client.put(
    `/missions/${params.uuid}`,
    {
      file: params.routeMapImage,
      alias: params.missionAlias,
      route_description: params.routeDescription,
      factory: params.factory,
      detail_route: params.detailRoute,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export const fetchRouteImage = (params: FetchRouteImageProps) => {
  return client.get(`/missions/route-map-image/${params.uuid}`);
};
export const editRouteImage = (params: EditRouteImageProps) => {
  return client.put(
    `/missions/route-map-image/${params.uuid}`,
    {
      file: params.image,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

export const deleteRouteImage = (params: DeleteRouteImageProps) => {
  return client.delete(`/missions/route-map-image/${params.uuid}`, {
    data: {
      file: params.image,
    },
  });
};
