import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { deleteAddress } from '@/api/address';
import { AxiosError } from 'axios';

interface DeleteAddressParmas {
  email: string[];
}
interface DeleteAddressResponse {
  rank: string;
  name: string;
  phone: string;
  email: string;
  in_charge: string;
  alarm_level: AlarmLevel[];
  factories: Factories[];
}
interface AlarmLevel {
  name: string;
  is_checked: boolean;
}
interface Factories {
  name: string;
  is_checked: boolean;
}

const useDeleteAddress = (
  options?: UseMutationOptions<
    DeleteAddressResponse,
    AxiosError,
    DeleteAddressParmas
  >
) => {
  const fetcher = async (variable: DeleteAddressParmas) => {
    const result = await deleteAddress(variable.email);
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useDeleteAddress;
