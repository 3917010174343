export const mockScheduleData = {
  mission: [
    {
      uuid: 'a1b2c3d4',
      mission_id: 1,
      name: 'mission1',
      saved_at: '2023-04-10T08:30:00.000Z',
    },
    {
      uuid: 'e5f6g7h8',
      mission_id: 2,
      name: 'mission2',
      saved_at: '2023-04-11T10:15:00.000Z',
    },
  ],
  robots: [
    {
      robotIndex: 0,
      hostname: 'host1',
      username: 'user1',
      nickname: 'robot1',
    },
    {
      robotIndex: 1,
      hostname: 'host2',
      username: 'user2',
      nickname: 'robot2',
    },
  ],
  calendar: {
    activeEvents: [
      {
        eventMetadata: {
          eventId: 'event1',
          name: 'eventName1',
          modificationTimeMs: '1689770880000',
          modificationUser: 'modUser1',
        },
        agent: {
          nickname: 'robot1',
        },
        task: {
          missionId: 'a1b2c3d4',
        },
        schedule: {
          timeMs: '1689770880000',
          repeatMs: '0',
        },
      },
      {
        eventMetadata: {
          eventId: 'event2',
          name: 'eventName2',
          modificationTimeMs: '1689503020',
          modificationUser: 'modUser2',
        },
        agent: {
          nickname: 'robot2',
        },
        task: {
          missionId: 'e5f6g7h8',
        },
        schedule: {
          timeMs: '1689503020',
          repeatMs: '86400000',
        },
        booking: {
          is_email_received: true,
          is_sms_received: true,
        },
      },
    ],
  },
};
