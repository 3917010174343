import { Grid } from '@mui/material';
import { SmsForm } from './SmsForm';
import { FactoryForm } from './FactoryForm';

export const SystemForm = () => {
  return (
    <Grid container p={2}>
      {/* <Grid
        item
        xs={12}
        md={6}
        p={2}
        sx={{ border: 1, borderRadius: 1, borderColor: '#CDD5DF' }}
      >
        <Stack direction="column">
          <Typography variant="h4" mb={2}>
            SMTP 설정 (email)
          </Typography>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <TextField label="Mission Type" variant="outlined" />
            </Grid>
            <Grid item xs={12}>
              <TextField label="Mission Setting Time" variant="outlined" />
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              <Button variant="contained">등록</Button>
            </Grid>
          </Grid>
        </Stack>
      </Grid> */}
      <SmsForm />
      <FactoryForm />
    </Grid>
  );
};
