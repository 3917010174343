import { showToast } from '@/hooks/toast/toastUtils';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from '@mui/material';
import { DataGrid, GridValidRowModel, koKR, enUS } from '@mui/x-data-grid';
import { useRef, useState } from 'react';
import CustomNoRowsOverlay from '../Common/CustomNoRowsOverlay';
import useAddress from '@/hooks/remote/useAddress';
import { CustomFooter } from './DataGridCustomFooter';
import { CustomToolbar } from './DataGridCustomToolbar';
import { setToastMessage } from './AddressSetToastMessage';
import { queryKey } from '@/hooks/remote/querykey';
import useUpdateAddress from '@/hooks/remote/useUpdateAddress';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getColumns } from './DataGridColumns';
import { useLangContext } from '@/hooks/constate/LangContext';

interface UpdateAddressParams {
  rank: string;
  name: string;
  phone: string;
  email: string;
  to_email: string;
  in_charge: string;
  alarm_level: AlarmLevel[];
}
interface AlarmLevel {
  name: string;
  is_checked: boolean;
}

export const AddressTable = () => {
  const { data: address } = useAddress(undefined);
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const noButtonRef = useRef<HTMLButtonElement>(null);
  const [promiseArguments, setPromiseArguments] = useState<any>(null);
  const { locale } = useLangContext();

  const { mutateAsync: updateAddress } = useUpdateAddress({
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.fetchAddress]);
    },
  });

  const addToEmailValue = (
    newRow: GridValidRowModel,
    oldRow: GridValidRowModel
  ) => {
    const updatedRow = {
      ...(newRow.email !== oldRow.email
        ? { to_email: newRow.email }
        : { to_email: oldRow.email }),
      ...newRow,
    };
    return updatedRow as UpdateAddressParams;
  };

  const handleYes = async () => {
    const { newRow, oldRow } = promiseArguments;
    const updateRow: UpdateAddressParams = addToEmailValue(newRow, oldRow);
    updateRow.email = oldRow.email;
    updateRow.phone.replaceAll('-', '');
    try {
      // Make the HTTP request to save in the backend
      await updateAddress(updateRow);
      showToast(t, 'success', '변경에 성공했습니다.');
      setPromiseArguments(null);
    } catch (error) {
      showToast(t, 'error', '변경에 실패했습니다.');
      setPromiseArguments(null);
    }
  };

  const handleEntered = () => {
    noButtonRef.current?.focus();
  };

  const handleNo = () => {
    setPromiseArguments(null);
  };
  // Cell 편집 후 setPromiseArguments를 통해 Dialog를 띄워준다.
  const handleCellEditSubmmit = async (
    newRow: GridValidRowModel,
    oldRow: GridValidRowModel
  ) => {
    const toastMessage = setToastMessage(newRow, oldRow, t);
    if (toastMessage) {
      setPromiseArguments({ newRow, oldRow });
      return newRow;
    }
    return oldRow;
  };
  const ConfirmDialog = () => {
    if (!promiseArguments) {
      return null;
    }
    const { newRow, oldRow } = promiseArguments;
    const editMessage = setToastMessage(newRow, oldRow, t);

    return (
      <Dialog
        maxWidth="xl"
        open={!!promiseArguments}
        TransitionProps={{ onEntered: handleEntered }}
      >
        <DialogTitle>{t('modify')}?</DialogTitle>
        <DialogContent dividers>
          {`수정을 누르시면 ${editMessage} 변경됩니다.`}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleNo} ref={noButtonRef}>
            {t('cancel')}
          </Button>
          <Button onClick={handleYes}>{t('confirm')}</Button>
        </DialogActions>
      </Dialog>
    );
  };

  const columns = getColumns();
  return (
    <Box sx={{ minHeight: 400, width: '100%' }}>
      <ConfirmDialog />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DataGrid
            sx={{ minHeight: 400 }}
            rows={address || []}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 20,
                },
              },
            }}
            pageSizeOptions={[20]}
            checkboxSelection
            slots={{
              toolbar: CustomToolbar,
              noRowsOverlay: CustomNoRowsOverlay,
              footer: CustomFooter,
            }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { debounceMs: 300 },
              },
            }}
            processRowUpdate={handleCellEditSubmmit}
            onProcessRowUpdateError={() =>
              showToast(t, 'error', 'change failed')
            }
            localeText={
              locale === 'koKR'
                ? koKR.components.MuiDataGrid.defaultProps.localeText
                : enUS.components.MuiDataGrid.defaultProps.localeText
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};
