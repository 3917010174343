import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
} from '@mui/material';
import { ActionDataRow } from './ActionDataRow';
import { ConstantSurveillanceRow } from './ConstantSurveillanceRow';
import { InspectionPointRow } from './InspectionPointRow';
import { HeaderRow } from './HeaderRow';
import { styled } from '@mui/material/styles';
interface FilteredActionsProps {
  filteredActions: FilteredActions[];
}

interface FilteredActions {
  action_name: string;
  data_captures: DataCapture[];
}
interface DataCapture {
  time: string;
  dataUrl: string;
  key_result: KeyResult[];
  channelName: string;
  full_dataUrl: string;
}

interface KeyResult {
  name: string;
  value: string;
}

export const StyledTableRow = styled(TableRow)`
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const StyledTableCell = styled(TableCell)`
  font-size: 0.875rem;
  padding: 12px 16px;
  font-weight: 500;
`;

const constantSurveillanceList = [
  {
    name: 'Fallen',
    inspection: 'unconsciousPatient',
    content: 'Estimated number of fainted patients',
    unit: 'times',
  },
  {
    name: 'Thermal',
    inspection: 'abnormalTemperature',
    content: 'Number of abnormal temperature detection',
    unit: 'times',
  },
];

const DataTable = ({ filteredActions }: FilteredActionsProps) => {
  const regex = /(Human|Door|Thermal_thresholder|Fallen)/;
  const actionData = filteredActions.filter(
    (action) => !regex.test(action.action_name)
  );
  actionData.forEach((action) =>
    action.data_captures.sort(
      (a, b) => b.key_result?.length - a.key_result?.length
    )
  );

  return (
    <Box
      sx={{
        borderRadius: 4,
        border: 1,
        borderColor: 'grey.400',
      }}
    >
      <TableContainer
        sx={{
          height: 'auto',
          borderRadius: 4,
        }}
      >
        <Table
          stickyHeader
          sx={{
            midWidth: 800,
          }}
        >
          <HeaderRow />
          <TableBody>
            <ConstantSurveillanceRow
              constantSurveillanceList={constantSurveillanceList}
            />
            <InspectionPointRow />
            <ActionDataRow filteredActions={actionData} />
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DataTable;
