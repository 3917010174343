import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { fetchReportDetail } from '@api/report';
import { queryKey } from './querykey';
interface ReportDetailsParams {
  uuid: string;
}

interface ReportDetailsResponse {
  uuid: string;
  mission: Mission[];
  robot: Robot[];
  action: Action[];
}
interface Mission {
  name: string;
  status: string;
  start_time: string;
  end_time: string;
  time_spent: TimeSpent;
  detail_route: string;
  factory_alias: string;
  mission_alias: string;
  route_description: string;
  map_url: string;
}

interface Robot {
  info: RobotInfo;
  name: string;
  subscribers: unknown[];
}

interface Action {
  action_name: string;
  data_captures: DataCapture[];
}

interface DataCapture {
  time: string;
  dataUrl: string;
  key_result: KeyResult[];
  channelName: string;
  full_dataUrl: string;
}

interface KeyResult {
  name: string;
  value: string;
}
interface TimeSpent {
  hours: number;
  minutes: number;
  seconds: number;
}

interface RobotInfo {
  name: string;
  ip: string;
}

const useReportDetails = (
  variable: ReportDetailsParams,
  options: UseQueryOptions<ReportDetailsResponse, AxiosError>
) => {
  const fetcher = async () => {
    const result = await fetchReportDetail(variable);
    return result.data;
  };

  return useQuery<ReportDetailsResponse, AxiosError>(
    [queryKey.fetchReportDetail, variable],
    fetcher,
    options
  );
};

export default useReportDetails;
