import useRegisterLogo from '@/hooks/remote/useRegisterLogo';
import useRegisterScoutInfo from '@/hooks/remote/useRegisterScoutInfo';
import useRegisterSMS from '@/hooks/remote/useRegisterSMS';
import useRegisterSMTP from '@/hooks/remote/useRegisterSMTP';
import { showToast } from '@/hooks/toast/toastUtils';
import {
  StepIcon,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Box,
} from '@mui/material';
import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { LogoImageUploadForm } from './LogoImageUploadForm';
import { ScoutAdminIdPassForm } from './ScoutAdminIdPassForm';
import { SMSInfoForm } from './SMSInfoForm';
import { SMTPInfoForm } from './SMTPInfoForm';
import { StepButtons } from './StepButtons';

interface SMTPList {
  address: string;
  name: string;
  id: string;
  password: string;
  port: number;
}

interface ScoutInfo {
  token: string;
}

interface SMSList {
  apiKey: string;
  senderPhoneNumber: number;
  agency: string;
  default: boolean;
  time: string;
}

interface OnboardingFormValue {
  scoutInfo: ScoutInfo;
  logoImage: File;
  smtpList: SMTPList;
  smsList: SMSList;
}
const CustomBox = styled(Box)({
  width: '880px',
  display: 'flex',
  alignItems: 'center',
  marginBottom: '24px',
  flexDirection: 'column',
});

const steps = [
  {
    label: 'Orbit 토큰 입력',
    component: ScoutAdminIdPassForm,
  },
  { label: '로고 업로드', component: LogoImageUploadForm },
  { label: 'SMTP 관련 정보 입력', component: SMTPInfoForm },
  { label: 'SMS 관련 정보 입력', component: SMSInfoForm },
];

const CustomStepIcon = styled(StepIcon)(() => ({
  fontSize: '35px',
}));

export const OnboardingStep = () => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const { handleSubmit, control } = useForm<OnboardingFormValue>({
    defaultValues: {},
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const { mutateAsync: registerScoutinfo } = useRegisterScoutInfo();
  const { mutateAsync: registerSMTP } = useRegisterSMTP();
  const { mutateAsync: registerSMS } = useRegisterSMS();
  const { mutateAsync: registerLogo } = useRegisterLogo();

  const handleSubmitStep: SubmitHandler<OnboardingFormValue> = async (data) => {
    try {
      setIsLoading(true);
      if (activeStep === 0) await scoutAdminSubmit(data);
      if (activeStep === 1) await logoSubmit(data);
      if (activeStep === 2) await smtpInfoSubmit(data);
      if (activeStep === 3) await smsInfoSubmit(data);
    } catch (e) {
      showToast(t, 'error', '전송오류');
      setIsLoading(false);
    }
  };

  const handlePrevious = () => {
    setActiveStep((prevStep) => prevStep - 1);
    setIsLoading(false);
  };

  const handleSkip = () => {
    setActiveStep((prevStep) => prevStep + 1);
    setIsLoading(false);
  };

  const scoutAdminSubmit: SubmitHandler<OnboardingFormValue> = async (data) => {
    try {
      setIsLoading(true);
      await registerScoutinfo(data.scoutInfo);
      setActiveStep((prevStep) => prevStep + 1);
      showToast(t, 'success', '전송을 성공했습니다.');
      setIsLoading(false);
    } catch (e) {
      showToast(t, 'error', '전송오류');
      setIsLoading(false);
    }
  };

  const smtpInfoSubmit: SubmitHandler<OnboardingFormValue> = async (data) => {
    try {
      setIsLoading(true);
      await registerSMTP(data.smtpList);
      setActiveStep((prevStep) => prevStep + 1);
      showToast(t, 'success', '전송을 성공했습니다.');
      setIsLoading(false);
    } catch (e) {
      showToast(t, 'error', '전송오류');
      setIsLoading(false);
    }
  };

  const smsInfoSubmit: SubmitHandler<OnboardingFormValue> = async (data) => {
    try {
      setIsLoading(true);
      await registerSMS(data.smsList);
      showToast(t, 'success', '전송을 성공했습니다.');
      setIsLoading(false);
      navigate('/');
    } catch (e) {
      showToast(t, 'error', '전송오류');
      setIsLoading(false);
    }
  };

  const logoSubmit: SubmitHandler<OnboardingFormValue> = async (data) => {
    try {
      setIsLoading(true);
      await registerLogo(data);
      setActiveStep((prevStep) => prevStep + 1);
      showToast(t, 'success', '전송을 성공했습니다.');
      setIsLoading(false);
    } catch (e) {
      showToast(t, 'error', '전송오류');
      setIsLoading(false);
    }
  };

  const renderStepForm = () => {
    const FormComponent = steps[activeStep].component;
    return (
      <form onSubmit={handleSubmit(handleSubmitStep)}>
        <Box>
          <FormComponent control={control} />
          <StepButtons
            isLoading={isLoading}
            activeStep={activeStep}
            handlePrevious={handlePrevious}
            stepLength={steps.length}
            handleSkip={handleSkip}
          />
        </Box>
      </form>
    );
  };

  return (
    <CustomBox>
      <Typography variant="h2" mb={2}>
        Patrol Service 시작 설정
      </Typography>
      <Stepper
        sx={{ width: '50%' }}
        activeStep={activeStep}
        orientation="vertical"
      >
        {steps.map(({ label }, index) => (
          <Step key={label}>
            <StepLabel StepIconComponent={CustomStepIcon}>
              <Typography variant="h3">{label}</Typography>
            </StepLabel>
            {activeStep === index && renderStepForm()}
          </Step>
        ))}
      </Stepper>
    </CustomBox>
  );
};
