interface MissionResponse {
  mission_uuid: string;
  mission_name: string;
  source_mission_create_at: string;
  source_mission_modified_at: string;
  alias: string;
  create_at: string;
  modified_at: string;
  file: string;
  route_description: string;
  factory: string;
  detail_route: string;
}

export const mockMissionResponse: MissionResponse = {
  mission_uuid: '03f288eb-ba35-412a-97a0-51d10a8d4d42',
  mission_name: '230601_USF_POC_CB3_4MIN',
  source_mission_create_at: '2023-06-15T08:04:19.884225Z',
  source_mission_modified_at: '2023-06-15T08:04:19.884225Z',
  alias: '별칭입니다',
  create_at: '2023-06-15T17:04:19.884225+09:00',
  modified_at: '2023-07-10T14:09:18.247678+09:00',
  file: 'http://localhost:9000/route-map-bucket/2023-7-10/%E1%84%82%E1%85%B3%E1%86%AF%E1%84%89%E1%85%A1%E1%86%BC_%E1%84%85%E1%85%A9%E1%84%80%E1%85%A9_DC2oWiK.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minio%2F20230710%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20230710T073936Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=44c7607ddcbe6b8b170aaaebc264980bda0e9aeda60e8a4966ea958480c44ea4',
  route_description: '테스트 미션입니다',
  factory: 'AG_RED',
  detail_route: '1 - 2 - 3 - 4',
};
