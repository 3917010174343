import { StyledTableCell } from './DataTable';
import { TableRow, TableHead } from '@mui/material';
import { useTranslation } from 'react-i18next';

const styleForStyledCell = { bgcolor: '#314155', color: 'white' };

export const HeaderRow = () => {
  const { t } = useTranslation();
  return (
    <TableHead
      sx={{
        '& .MuiStyledTableCell-root': {
          fontWeight: 'bold',
          fontSize: '1rem',
        },
      }}
    >
      <TableRow>
        <StyledTableCell sx={styleForStyledCell}>
          {t('inspectionlist')}
        </StyledTableCell>
        <StyledTableCell colSpan={4} align="center" sx={styleForStyledCell}>
          {t('content')}
        </StyledTableCell>
        <StyledTableCell align="center" sx={styleForStyledCell}>
          {t('inspectionStandard')}
        </StyledTableCell>
        <StyledTableCell align="center" sx={styleForStyledCell}>
          {t('result')}
        </StyledTableCell>
        <StyledTableCell align="center" sx={styleForStyledCell}>
          {t('notificationStatus')}
        </StyledTableCell>
      </TableRow>
    </TableHead>
  );
};
