import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastOptions } from 'react-toastify';
type ToastType = 'success' | 'error' | 'info' | 'warning' | 'default';

export const showToast = (
  t: (key: string) => string,
  type: ToastType,
  message?: string,
  options: ToastOptions = {}
): void => {
  const toastOptions: ToastOptions = {
    position: toast.POSITION.TOP_CENTER,
    pauseOnFocusLoss: false,
    autoClose: 500,
    ...options,
  };

  switch (type) {
    case 'success':
      toast.success(
        message ? t(message) : t('successfully completed'),
        toastOptions
      );
      break;
    case 'error':
      toast.error(message ? t(message) : t('please try again'), toastOptions);
      break;
    case 'info':
      toast.info(message, toastOptions);
      break;
    case 'warning':
      toast.warn(message, toastOptions);
      break;
    default:
      toast(message, toastOptions);
  }
};
