import { Paper, Typography, Stack, Grid, Checkbox } from '@mui/material';
import { convertDate } from '@/utils/converDate';
import { convertMillisecondsToDHM } from '@/utils/convertMillisecondsToDHM';
import AlertDialog from '@/hooks/common/AlertDialog';
import { queryKey } from '@/hooks/remote/querykey';
import useDeleteSchedule from '@/hooks/remote/useDeleteSchedule';
import { useQueryClient } from '@tanstack/react-query';
import { showToast } from '@/hooks/toast/toastUtils';
import { useTranslation } from 'react-i18next';
interface BookingData {
  is_email_received: boolean;
  is_sms_received: boolean;
}
interface EventMetadata {
  eventId: string;
  name: string;
  modificationTimeMs: string;
  modificationUser: string;
}
interface Agent {
  nickname: string;
}
interface Task {
  missionId: string;
}
interface Schedule {
  timeMs: string;
  repeatMs: string;
}
interface ActiveEvent {
  eventMetadata: EventMetadata;
  agent: Agent;
  task: Task;
  schedule: Schedule;
  booking?: BookingData;
}
interface ScheduleCardProps {
  index: number;
  schedule: ActiveEvent;
}

const ScheduleCard = ({ index, schedule }: ScheduleCardProps) => {
  const { agent, eventMetadata, booking, schedule: scheduleData } = schedule;
  const { t } = useTranslation();

  const queryClient = useQueryClient();
  const timeMs = parseFloat(scheduleData.timeMs);
  const remainingTime = convertMillisecondsToDHM(timeMs);
  const isNotStarted = remainingTime !== '';
  const formattedDate = convertDate(timeMs);
  const { mutate: deleteSchedule } = useDeleteSchedule({
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.fetchSchedule]);
    },
  });

  const handleDelete = async () => {
    try {
      deleteSchedule(schedule);
      showToast(t, 'success', 'successfully deleted the schedule');
    } catch (error) {
      showToast(t, 'error');
    }
  };
  return (
    <Paper>
      <Grid container spacing={2}>
        <Grid item xs={10}>
          <Typography variant="h4">
            {t('bookedSchedule')} #{index + 1}
          </Typography>
          <Typography variant="h5" m={2}>
            {`${
              isNotStarted
                ? t('Robot awaiting', {
                    remainingTime,
                    robotName: agent.nickname,
                    mission: eventMetadata.name,
                    formattedDate,
                  })
                : t('Robot executing', {
                    robotName: agent.nickname,
                    mission: eventMetadata.name,
                  })
            }`}
          </Typography>
          <Stack
            pl={2}
            direction="row"
            spacing={2}
            pt={0.5}
            alignItems="center"
          >
            <Typography variant="h5" pt={0.5}>
              {t('notificationSetting')}:
            </Typography>

            <Stack direction="row" alignItems="center">
              <Typography pt={0.5}>이메일</Typography>
              <Checkbox checked={booking?.is_email_received} disabled />
            </Stack>
            <Stack direction="row" alignItems="center">
              <Typography pt={0.5}>문자메세지</Typography>
              <Checkbox checked={booking?.is_sms_received} disabled />
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Grid
            container
            direction="column"
            justifyContent="space-between"
            alignItems="flex-end"
            height="100%"
          >
            <Grid item>
              <AlertDialog
                schedule={schedule}
                title={eventMetadata.name}
                message={`${t('Schedule to be deleted')} : ${
                  eventMetadata.name
                }`}
                robot={agent.nickname}
                time={formattedDate}
                onConfirm={handleDelete}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ScheduleCard;
