import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Box,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Checkbox,
} from '@mui/material';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { queryKey } from '@/hooks/remote/querykey';
import useAddAddress from '@/hooks/remote/useAddAddress';
import { useTranslation } from 'react-i18next';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import useFactories from '@/hooks/remote/useFactory';
import { FormInputText } from '../Common/FormInputText';
import { showToast } from '@/hooks/toast/toastUtils';

interface AddressFormValue {
  rank: string;
  name: string;
  phone: string;
  email: string;
  alarmTypes: CheckList[];
  factories: CheckList[];
}
interface CheckList {
  name: string;
  is_checked: boolean;
}

const ALARMLEVEL = ['INFO', 'ERROR', 'WARNING', 'CRITICAL'];

const ALARMTYPES = [
  { name: 'EMAIL', is_checked: false },
  { name: 'SMS_LIVE', is_checked: false },
  { name: 'SMS_BOOKING', is_checked: false },
];

export const AddressFormDialogs = () => {
  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors },
    setValue,
    reset,
  } = useForm<AddressFormValue>();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const queryClient = useQueryClient();

  const { mutateAsync: addAddress } = useAddAddress({
    onSuccess: () => {
      queryClient.invalidateQueries([queryKey.fetchAddress]);
      setOpen(false);
    },
  });

  const { data: factoriesList } = useFactories(undefined);

  const FACTORIES = useMemo(() => {
    if (!factoriesList) return [];
    return factoriesList.map((factory) => {
      return { name: factory.name, is_checked: false };
    });
  }, [factoriesList]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit: SubmitHandler<AddressFormValue> = useCallback(
    async (data) => {
      const { rank, name, phone, email, alarmTypes, factories } = data;

      const alarmLevel = ALARMLEVEL.map((alarmName) => ({
        name: alarmName,
        is_checked: true,
      }));

      const addUserData = {
        rank,
        name,
        phone,
        email,
        in_charge: 'test',
        alarm_level: alarmLevel,
        alarm_types: alarmTypes,
        factories,
      };

      try {
        await addAddress(addUserData);
        showToast(t, 'success', '등록에 성공했습니다.');
        reset();
      } catch (e) {
        showToast(t, 'error');
        reset();
        // checkBox 부분을 해결하지 못해 일단 다 리셋
      }
    },
    [reset]
  );

  const handleCheckboxChange = (
    changeDataName: keyof AddressFormValue,
    listName: string,
    isChecked: boolean
  ) => {
    const values = getValues()[changeDataName] as CheckList[];
    setValue(
      changeDataName,
      values.map((item) => {
        if (item.name === listName) {
          return { ...item, is_checked: isChecked };
        }
        return item;
      })
    );
  };

  const validateFactories = (factories: CheckList[]): boolean => {
    return factories.some((factory) => factory.is_checked);
  };
  const validateAlarmTypes = (alarmTypes: CheckList[]): boolean => {
    return alarmTypes.some((alarmType) => alarmType.is_checked);
  };

  return (
    <Box>
      <Button onClick={handleClickOpen}>
        <PersonAddAlt1Icon />
        {t('contactRegistration')}
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormControl component="fieldset" variant="standard">
            <DialogTitle>{t('contactRegistration')}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {t('Please enter your information.')}
              </DialogContentText>
              <FormInputText
                name="rank"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter a rank',
                  },
                }}
                textFieldProps={{
                  label: `${t('rank')}`,
                  fullWidth: true,
                  autoFocus: true,
                }}
              />
              <FormInputText
                name="name"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter a name',
                  },
                }}
                textFieldProps={{
                  label: `${t('name')}`,
                  fullWidth: true,
                }}
              />
              <FormInputText
                name="phone"
                control={control}
                defaultValue=""
                textFieldProps={{
                  label: `${t('phoneNumber')}`,
                  fullWidth: true,
                  type: 'tel',
                }}
              />
              <FormInputText
                name="email"
                control={control}
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: 'Please enter an email',
                  },
                }}
                textFieldProps={{
                  label: `${t('email')}`,
                  fullWidth: true,
                  type: 'email',
                }}
              />
              <Box
                sx={{
                  border: 1,
                  borderColor: '#CDD5DF',
                  width: '100%',
                  padding: 1,
                  marginBottom: 1,
                }}
              >
                <FormLabel component="legend">{t('factory')}</FormLabel>
                {FACTORIES?.map((factory, index) => (
                  <Controller
                    name="factories"
                    control={control}
                    defaultValue={FACTORIES}
                    key={index}
                    rules={{
                      validate: {
                        atLeastOneSelected: (value) =>
                          validateFactories(value) ||
                          '최소 하나의 공장을 선택해주세요',
                      },
                    }}
                    render={({ field }) => (
                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              {...field}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  field.name,
                                  factory.name,
                                  e.target.checked
                                )
                              }
                            />
                          }
                          label={t(factory.name)}
                        />
                      </>
                    )}
                  />
                ))}
                {
                  <FormHelperText sx={{ color: 'red' }}>
                    {errors.factories?.message}
                  </FormHelperText>
                }
              </Box>
              <Box
                sx={{
                  border: 1,
                  borderColor: '#CDD5DF',
                  width: '100%',
                  padding: 1,
                  marginBottom: 1,
                }}
              >
                <FormLabel component="legend">
                  {t('featureSelection')}
                </FormLabel>
                {ALARMTYPES.map((alarmType, index) => (
                  <Controller
                    name={'alarmTypes'}
                    control={control}
                    key={index}
                    defaultValue={ALARMTYPES}
                    rules={{
                      validate: {
                        atLeastOneSelected: (value) =>
                          validateAlarmTypes(value) ||
                          '최소 하나의 기능을 지정해주세요',
                      },
                    }}
                    render={({ field }) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...field}
                            onChange={(e) =>
                              handleCheckboxChange(
                                field.name,
                                alarmType.name,
                                e.target.checked
                              )
                            }
                          />
                        }
                        label={t(alarmType.name)}
                      />
                    )}
                  />
                ))}
                {
                  <FormHelperText sx={{ color: 'red' }}>
                    {errors.alarmTypes?.message}
                  </FormHelperText>
                }
              </Box>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleClose}>
                {t('cancel')}
              </Button>
              <Button variant="outlined" type="submit">
                {t('registration')}
              </Button>
            </DialogActions>
          </FormControl>
        </form>
      </Dialog>
    </Box>
  );
};
