import { ActionEdit } from '@/components/MissionSetting/ActionEdit';
import { MissionEdit } from '@/components/MissionSetting/MissionEdit';
import { MissionTable } from '@/components/MissionSetting/MissionTable';
import { MainContainer } from '@/components/myStyle/MainContainer';
import { Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router';
import { LoadingPage } from './LoadingPage';

export const MissionSettingPage = () => {
  return (
    <Suspense
      fallback={<LoadingPage loadingText={'미션 세팅 페이지 로딩 중..'} />}
    >
      <Routes>
        <Route path="/" element={<MissionSetting />} />
        <Route path=":uuid" element={<MissionEdit />} />
        <Route path="/action/:uuid" element={<ActionEdit />} />
      </Routes>
    </Suspense>
  );
};

const MissionSetting = () => {
  const theme = useTheme();

  return (
    <MainContainer theme={theme}>
      <Paper>
        <MissionTable />
      </Paper>
    </MainContainer>
  );
};
