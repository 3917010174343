import { Box } from '@mui/material';
import { Control } from 'react-hook-form';
import { FormInputText } from '../Common/FormInputText';
import { FormRadio } from '../Common/FormRadio';
interface SMTPList {
  address: string;
  name: string;
  id: string;
  password: string;
  port: number;
}

interface ScoutInfo {
  token: string;
}

interface SMSList {
  apiKey: string;
  senderPhoneNumber: number;
  agency: string;
  default: boolean;
  time: string;
}

interface OnboardingFormValue {
  scoutInfo: ScoutInfo;
  logoImage: File;
  smtpList: SMTPList;
  smsList: SMSList;
}

interface InfoFormProps {
  control: Control<OnboardingFormValue>;
}
export const SMSInfoForm = ({ control }: InfoFormProps) => {
  return (
    <Box>
      <FormInputText
        name="smsList.apiKey"
        control={control}
        rules={{ required: 'API Key를 입력해주세요' }}
        textFieldProps={{
          label: 'API Key',
          fullWidth: true,
          type: 'text',
        }}
      />
      <FormInputText
        name="smsList.senderPhoneNumber"
        control={control}
        rules={{ required: '발신자 번호를 입력해주세요' }}
        textFieldProps={{
          label: '발신자 번호',
          fullWidth: true,
          type: 'tel',
        }}
      />
      <FormInputText
        name="smsList.time"
        control={control}
        rules={{
          required: '요약문자를 받을 시간을 입력해주세요',
          pattern: {
            value: /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/,
            message: '시간은 00:00 형식으로 입력해주셔야합니다.',
          },
        }}
        textFieldProps={{
          label: '미션 요약 SMS 시간 (24시분단위 ex 0830)',
          fullWidth: true,
          type: 'time',
        }}
      />
      <FormRadio
        name="smsList.agency"
        control={control}
        rules={{ required: '대행사를 선택해주세요' }}
        group={[
          { value: 'SEJONG', label: '세종' },
          { value: 'GABIA', label: '가비아' },
        ]}
        label="대행사를 선택해주세요"
      />
    </Box>
  );
};
