import { queryKey } from '@/hooks/remote/querykey';
import { Button } from '@mui/material';
import {
  GridFooterContainer,
  GridPagination,
  useGridApiContext,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { showToast } from '@/hooks/toast/toastUtils';
import useDeleteReport from '@/hooks/remote/useDeleteReport';

interface DeleteReportParmas {
  uuid: string[];
}

export const ReportDataGridFooter = () => {
  const { mutateAsync: deleteReport } = useDeleteReport();
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const handleDeleteReport = async (params: DeleteReportParmas) => {
    try {
      await deleteReport(params, {
        onSuccess: () => {
          queryClient.invalidateQueries([queryKey.fetchReports]);
          showToast(t, 'success', '삭제에 성공했습니다.');
        },
      });
    } catch (e) {
      showToast(t, 'error', `삭제 실패`);
    }
  };

  const apiRef = useGridApiContext(); // Access the grid API context
  const selectedRows = Array.from(apiRef.current.getSelectedRows().values());

  const handleDeleteClick = () => {
    const selectedReportUuid = {
      uuid: selectedRows.map((row) => row.id),
    };
    if (selectedRows.length > 0) {
      handleDeleteReport(selectedReportUuid);
    }
  };

  return (
    <GridFooterContainer sx={{ justifyContent: 'space-between' }}>
      {/* <Button onClick={handleDeleteClick}>
        <DeleteIcon />
        {t('delete')}
      </Button> */}
      <div />
      <GridPagination />
    </GridFooterContainer>
  );
};
