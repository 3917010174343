import { Button, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';

export interface StepButtonsProps {
  isLoading: boolean;
  activeStep: number;
  handlePrevious: () => void;
  handleSkip: () => void;
  stepLength: number;
}

export const StepButtons = ({
  isLoading,
  activeStep,
  handlePrevious,
  stepLength,
  handleSkip,
}: StepButtonsProps) => {
  return (
    <Grid container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <Button disabled={activeStep === 0} onClick={handlePrevious}>
        이전
      </Button>
      {activeStep === stepLength - 1 ? (
        <LoadingButton
          loading={isLoading}
          variant="contained"
          color="primary"
          type="submit"
        >
          설정완료
        </LoadingButton>
      ) : (
        <Grid item>
          <Grid container spacing={1}>
            <Grid item>
              <LoadingButton
                loading={isLoading}
                variant="contained"
                color="primary"
                type="submit"
              >
                다음
              </LoadingButton>
            </Grid>
            <Grid item>
              <Button variant="contained" color="primary" onClick={handleSkip}>
                건너뛰기
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
