import { editMissions } from '@/api/missionSetting';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';

interface EditMissionsParams {
  uuid: string;
  routeMapImage: File;
  routeDescription: string;
  missionAlias: string;
  factory: string;
  detailRoute: string;
}

interface EditMissionsResponse {
  test: string;
}

const useEditMissions = (
  options?: UseMutationOptions<
    EditMissionsResponse,
    AxiosError,
    EditMissionsParams
  >
) => {
  const fetcher = async (variable: EditMissionsParams) => {
    const result = await editMissions(variable);
    return result.data;
  };

  return useMutation(fetcher, options);
};

export default useEditMissions;
