import { Input, InputProps } from '@mui/material';
import { ChangeEvent, useState } from 'react';
import {
  useController,
  FieldValues,
  FieldPath,
  UseControllerProps,
} from 'react-hook-form';

interface MuiInputProps {
  InputProps?: InputProps;
}

interface FormFileInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> extends MuiInputProps,
    UseControllerProps<TFieldValues, TName> {}

export const FormFileInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  InputProps,
  ...props
}: FormFileInputProps<TFieldValues, TName>) => {
  const {
    field,
    fieldState: { error },
  } = useController(props);

  const [value, setValue] = useState('');

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    setValue(e.target.value);
    // Todo: 타입문제 해결이 필요함
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if (file) field.onChange(file as any);
  };
  return (
    <Input
      {...InputProps}
      {...field}
      type="file"
      value={value}
      onChange={handleInputChange}
      error={!!error}
      margin="dense"
    />
  );
};
