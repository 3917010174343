import { useMutation, UseMutationOptions } from '@tanstack/react-query';
import { createPdf } from '@api/report';
import { AxiosError } from 'axios';

interface CreatePdfResponse {
  status: number;
  statusText?: string;
}
interface CreatePdfParams {
  file: Blob;
  uuid: string;
}

// check: 제대로 할당이 된건지 모르겠음
const useCreatePdf = (
  options?: UseMutationOptions<CreatePdfResponse, AxiosError, CreatePdfParams>
) => {
  const fetcher = async (variable: CreatePdfParams) => {
    const result = await createPdf(variable);
    return result;
  };

  return useMutation(fetcher, options);
};

export default useCreatePdf;
