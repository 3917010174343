import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
  Typography,
  TableRow,
} from '@mui/material';

function createData(name: string, location: string) {
  return { name, location };
}

const rows = [createData('Spot1', 'painting')];

export default function AcccessibleTable() {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell>Robot Status</TableCell>
            <TableCell align="right">Spot location</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.location}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Table aria-label="caption table">
        <caption>Currently robot is patrolling</caption>
        <TableHead>
          <TableRow>
            <TableCell>Communication Status</TableCell>
            <TableCell align="right">Latency</TableCell>
            <TableCell align="right">Tx</TableCell>
            <TableCell align="right">Rx</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component="th" scope="row">
                <Typography color="primary">Good</Typography>
              </TableCell>
              <TableCell align="right">35</TableCell>
              <TableCell align="right">35</TableCell>
              <TableCell align="right">12.54</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
