import { useReportContext } from '@/hooks/constate/ReportContext';
import { Stack, Box } from '@mui/material';
import { useMemo } from 'react';
import { AlarmReport } from './AlarmReport';
import { NonImageAlarm } from './NonImageAlarm';
import { PatrolDetail } from './PatrolDetail';
import { cleanAndNormalizeString } from '@/utils/cleanAndNormalizeString';
import { cleanIncludes } from '@/utils/cleanIncludes';

export const ReportDetail = () => {
  const { report, patrolRef, alarmRefs, setAlarmRefs, setIsLoaded } =
    useReportContext();
  const filteredActions = useMemo(() => {
    if (!report) return [];

    const filteredActions = report.action
      .filter(
        (action) =>
          !(
            cleanAndNormalizeString(action.action_name).match(
              /developer|groupname|mission_fail_checker|navigation|spotai/
            ) ||
            action.action_name.startsWith('Human_detector') ||
            (cleanIncludes(action.action_name, 'chemical') &&
              action.data_captures.every(
                (capture) => capture.key_result.length === 0
              ))
          )
      )
      .reverse();

    filteredActions.length > 0 ? setIsLoaded(false) : setIsLoaded(true);
    return filteredActions;
  }, [report]);

  return (
    <>
      <Stack direction="column" spacing={2}>
        <Box ref={patrolRef}>
          <PatrolDetail filteredActions={filteredActions} />
        </Box>
        {filteredActions?.map((alarmListElement, index) => (
          <Box
            ref={(el: HTMLDivElement) => {
              if (el && index >= alarmRefs.length) {
                setAlarmRefs((prevRefs) => [...prevRefs, el]);
              }
            }}
            key={index}
          >
            {cleanIncludes(alarmListElement.action_name, 'chemical') ? (
              <NonImageAlarm
                alarmListElement={alarmListElement}
                index={index}
              />
            ) : (
              <AlarmReport alarmListElement={alarmListElement} index={index} />
            )}
          </Box>
        ))}
      </Stack>
    </>
  );
};
