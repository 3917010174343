import { Avatar, Box, Badge } from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import useSchedules from '@/hooks/remote/useSchedules';

export const CalendarSection = () => {
  const navigate = useNavigate();
  const { data: schedulesData } = useSchedules();
  const theme = useTheme();

  const activeEventsCount = schedulesData?.calendar?.activeEvents?.length || 0;

  const handleCalendarClick = () => {
    navigate('/');
  };
  return (
    <Box
      sx={{
        ml: 2,
        mr: 2,
        [theme.breakpoints.down('md')]: {
          mr: 2,
        },
      }}
    >
      <Avatar
        variant="rounded"
        aria-haspopup="true"
        onClick={handleCalendarClick}
        color="inherit"
        sx={{ width: '50px', height: '50px' }}
      >
        <Badge badgeContent={activeEventsCount} color="info">
          <ScheduleIcon />
        </Badge>
      </Avatar>
    </Box>
  );
};
