import { Box } from '@mui/material';
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import { ReactNode } from 'react';
import { ReportListFilterDrawer } from './ReportListFilterDrawer';

interface Props {
  children?: ReactNode;
}

export const ReportListCustomToolbar = ({ children }: Props) => {
  return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
      <Box>
        <GridToolbarExport />
      </Box>
      <GridToolbarQuickFilter />
      <ReportListFilterDrawer />
      {children}
    </GridToolbarContainer>
  );
};
