import { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { UseMutateFunction } from '@tanstack/react-query';

interface ActiveEvent {
  eventMetadata: EventMetadata;
  agent: Agent;
  task: Task;
  schedule: Schedule;
  booking?: BookingData;
}
interface BookingData {
  is_email_received: boolean;
  is_sms_received: boolean;
}

interface EventMetadata {
  eventId: string;
  name: string;
  modificationTimeMs: string;
  modificationUser: string;
}

interface Agent {
  nickname: string;
}

interface Task {
  missionId: string;
}

interface Schedule {
  timeMs: string;
  repeatMs: string;
}

interface ScheduleResponse {
  mission: ScheduledMission[];
  robots: ScheduledRobot[];
  calendar?: Calendar;
}

interface ScheduledMission {
  uuid: string;
  mission_id: number;
  name: string;
  saved_at: string;
}

interface ScheduledRobot {
  robotIndex: number;
  hostname: string;
  username: string;
  nickname: string;
}

interface Calendar {
  activeEvents: ActiveEvent[];
}

interface DeleteScheduleParams {
  eventMetadata: EventMetadata;
  agent: Agent;
  task: Task;
  schedule: Schedule;
}

interface ConfirmationDialogProps {
  schedule: ActiveEvent;
  title: string;
  message: string;
  robot: string;
  time: string;
  onConfirm: UseMutateFunction<
    ScheduleResponse,
    AxiosError<unknown, unknown>,
    DeleteScheduleParams,
    unknown
  >;
  buttonText?: string;
}

const AlertDialog = ({
  schedule,
  title,
  message,
  robot,
  time,
  onConfirm,
}: ConfirmationDialogProps) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    onConfirm(schedule);
    handleClose();
  };

  return (
    <Box>
      <IconButton aria-label="delete" onClick={handleClickOpen}>
        <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t(title)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
            <br />
            {`- ${t('robotName')}: ${robot}`}
            <br />
            {`- ${t('scheduledTime')}: ${time}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{t('cancel')}</Button>{' '}
          <Button onClick={handleConfirm} autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AlertDialog;
